<div class="land pt-2">
</div>

<div class="row mt-4">
  <div class="col-3">

  </div>
  <div class="col-8">
    <div class="clearfix">
      <button type="button" 
              class="btn btn-dark-moon float-right" 
              (click)="navigate('nursestation')">
            <i class="fas fa-angle-double-left handCursor"></i> Back to Nurse Station</button>
      <button type="button" 
              class="btn btn-dark-moon float-right"
              (click)="actionIcdPop(icdPopup, selectedBed)">ADD / Update ICD-10</button>
    </div>

    <form (ngSubmit)="submitDischargeSummaryDetails(dischargeSummaryForm)" 
          [formGroup]="dischargeSummaryForm"
          class="mb-5">

      <fieldset class="legend-block mb-3 py-2">
        <legend class="legend-title blue-title mb-0">Patient Information</legend>
                <!-- Patient Info -->
        <div class="row patient-info">
            <div class="col-6 mb-2">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        UMR No
                    </div>
                    <div class="col">
                        <span>: {{selectedBed?.UMRNo}}</span>
                    </div>
                </div>
            </div>

            <div class="col-6 mb-2">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        IP No
                    </div>
                    <div class="col">
                        <span>: {{selectedBed?.IpNr}}</span>
                    </div>
                </div>
            </div>

            <div class="col-6 mb-2">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        Patient Name
                    </div>
                    <div class="col">
                        <span>: {{selectedBed?.PatientName}}</span>
                    </div>
                </div>
            </div>

            <div class="col-6 mb-2">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        Gender/Age
                    </div>
                    <div class="col">
                        <span>: {{selectedBed?.GenderAge}}</span>
                    </div>
                </div>
            </div>

            <div class="col-6 mb-2">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        Consult Doctor
                    </div>
                    <div class="col">
                        <span>: {{selectedBed?.ConsultDoctor}}</span>
                    </div>
                </div>
            </div>

            <div class="col-6 mb-2" *ngIf="userInfo.hospitalShortName == 'KPMTA'">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        Mobile No
                    </div>
                    <div class="col">
                        <span>: {{selectedBed?.mobileNo}}</span>
                    </div>
                </div>
            </div>
        </div>
      </fieldset>
            
      <fieldset class="legend-block mb-3 py-2">
        <legend class="legend-title blue-title mb-0">Discharge Summary Form</legend>
        <div class="row mt-4">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 font-weight-bold">Department Type
                  <span class="color text-danger">*</span></div>
                <div class="col-md-7 p-0">
                  <select id="departmentType" 
                          class="form-control-sm form-control border border-primary"
                          formControlName="departmentType" 
                          placeholder="Select Department Type">
                    <option [ngValue]="''">-- Select Department Type --</option>
                    <option *ngFor="let department of departments" 
                            [ngValue]="department.DEPT_HEADING_TITLE">
                        {{ department.DEPT_HEADING_TITLE }}
                    </option>
                  </select>
                <span *ngIf="(dischargeSummaryForm.controls.departmentType.touched && dischargeSummaryForm.controls.departmentType.invalid) || (dischargeSummaryForm.controls.departmentType.invalid && showErrorMessage)"
                      class="bg-warningtxt">Department Type is required</span>
              </div>
            </div>
          </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-5 font-weight-bold">Discharge Type<span
                            class="color text-danger">*</span></div>
                    <div class="col-md-7 pl-0">
                        <select id="dischargetype" 
                                class="form-control-sm form-control border border-primary"
                                formControlName="dischargeType" 
                                placeholder="Select Discharge Type">
                            <option [ngValue]="''">-- Select Discharge Type --</option>
                            <option *ngFor="let type of dischargeTypesList" 
                                    [ngValue]="type.DISPLAY_DISCHARGE_SUMMARY">
                                {{ type.DISPLAY_DISCHARGE_SUMMARY }}
                            </option>
                        </select>
                        <span
                            *ngIf="(dischargeSummaryForm.controls.dischargeType.touched && dischargeSummaryForm.controls.dischargeType.invalid) || (dischargeSummaryForm.controls.dischargeType.invalid && showErrorMessage)"
                            class="bg-warningtxt">Discharge Type is required</span>
                    </div>
                </div>
            </div>
            
            <div class="col-6 mt-3">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        Discharge Date
                    </div>
                    <div class="col p-0">
                        <input type="date" 
                                class="form-control-sm form-control border border-primary px-2"
                                [min]="selectedBed.admissionDate"
                                [max]="maxDate"                                       
                                formControlName="dischargeDate" />
                    </div>
                </div>
            </div>

            <div class="col-6 mt-3">
                <div class="row">
                    <div class="col-5 font-weight-bold">
                        Select Template
                    </div>
                    <div class="col pl-0">
                        <select name="dischargeTemplate" 
                                id="dischargeTemplate"
                                class="form-control-sm form-control border border-primary"
                                formControlName="dischargeTemplate" 
                                placeholder="Select template"
                                (change)="updateTemplate($event)">
                            <option value="">-- Select Template --</option>
                            <option *ngFor="let template of templatesList">
                                {{template.TEMPLATE_NAME}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <form [formGroup]="diagnosisForm" (ngSubmit)="onSubmitDiagnosis()">
                    <div class="form-group row">
                        <div class="col-md-5">
                            <label class="font-weight-bold">Provision Diagnosis </label>
                        </div>
                        <div class="col-md-5">
                            <textarea type="text" 
                                    class="form-control-sm form-control border border-primary" 
                                    name="provisionDiagnosis" columns="5"
                                    formControlName="provisionalDiagnosis"                                          
                                    required
                                    ></textarea>
                                    <!-- [readOnly]="isFormReadonlyMode" -->
                            <!-- <span *ngIf="diagnosisForm.submitted && provisionDiagnosis.invalid" class="bg-warningtxt">
                            Provision Diagnosis is required
                            </span> -->
                        </div>
                        <div class="col-md-2 text-right">
                            <button type="submit" class="btn btn-primary px-2">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        
            <div class="col-md-6">
                <form [formGroup]="followupForm" (ngSubmit)="onSubmitFallowupDate()">
                        <div class="form-group row">
                            <label class="col-md-5 font-weight-bold">Follow-up Date</label>
                            <input class="col-md-4 text-center form-control border border-primary" type="date" 
                            formControlName="fdate"
                            >
                            <div class="col-md-3 text-right">
                                <button class="btn btn-primary px-2" type="submit">Save</button>
                            </div>
                            <!-- <span *ngIf="fallowupForm.submitted && fdate.invalid" class="bg-warningtxt">
                                Provision Diagnosis is required
                            </span> -->
                        </div>
                </form>
            </div>
        </div>
        <div class="row">
            <label class="col-md-2"><b>Diagnosis</b></label>
            <span class="col-md-10"><b>{{diagnosis}}</b></span>
        </div>
        <div class="mt-4 mb-1 font-weight-bold">Discharge Summary<span class="color pl-2 text-danger">*</span>
        </div>
        <div class="NgxEditor__Wrapper">
            <ckeditor [config]="{ extraPlugins: 'justify',
                                  toolbarGroups: [
                                    { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
                                    { name: 'insert' },
                                    { name: 'tools' },
                                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                                    { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
                                    { name: 'styles' },
                                    { name: 'colors' }
                                  ] 
                                }"
                      formControlName="editorContent"></ckeditor>
            <span
                *ngIf="(dischargeSummaryForm.controls.editorContent.touched && dischargeSummaryForm.controls.editorContent.invalid) || (showErrorMessage && dischargeSummaryForm.controls.editorContent.invalid)"
                class="bg-warningtxt">Discharge Summary is required</span>
        </div>
        <div class="clearfix">
            <p *ngIf="isDischargeSummaryExist && existingDischargeSummaryInfo?.DISCHARGE_SUMMARY_FINALIZED == 'N'"
                class="float-left mt-3">
                <a href="javascript:void(0)" (click)="finalizePopupOpen(finalizePopup)">Finalize the discharge
                    summary</a>
            </p>
            <div class="float-right">
                <button type="button" class="btn btn-primary mr-2 mt-2"
                    (click)="printDischargeSummary(dischargeSummaryPrintPopup)"
                    *ngIf="existingDischargeSummaryInfo.length !== 0">
                    View and Print Discharge Summary
                </button>
                <button class="btn btn-primary mt-2"
                    [disabled]="existingDischargeSummaryInfo?.DISCHARGE_SUMMARY_FINALIZED == 'Y'">{{
                    isDischargeSummaryExist? "Save": "Submit"}}</button>
            </div>
        </div>
      </fieldset>
    </form>

  </div>
</div>

<!-- discharge summary finalize popup -->
<ng-template #finalizePopup let-modal>
    <div class="modal-header">
        <h6 class="modal-title">You Selected the option to Finalize the Discharge Summary. On submitting you will not have option to edit. Please Click 'Yes' to Continue and 'No' to Go back</h6>
    </div>
    <div class="modal-body">
        <button class="btn btn-success mr-2" (click)="dischargeSummaryfinalizeConfirmation()">Yes</button>
        <button class="btn btn-danger" type="button" aria-label="Close"
            (click)="modal.dismiss('Cross click')">No</button>
    </div>
</ng-template>
<!-- ./END discharge summary finalize popup -->

<!-- print discharge summary popup -->
<ng-template #dischargeSummaryPrintPopup let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Discharge Summary</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
        </button>
    </div>
    <div class="modal-body text-break">
        <app-discharge-summary-popup [selectedBed]="selectedBed"
            [dischargeSummaryInfo]="existingDischargeSummaryInfo.DISCHARGE_SUMMARY_INFO"></app-discharge-summary-popup>
    </div>
</ng-template>
<!-- ./END print discharge summary popup -->

<!-- ICD Popup -->
<ng-template #icdPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">ICD-10 Information</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
      </button>
    </div>
    <div class="modal-body text-break">
      <app-icd [patientDetails]="icdPatientDetails" (ICDpopupClose)="popupClose()"></app-icd>
    </div>
</ng-template>
<!-- ./ END ICD Popup -->