<div>
    <div class="row mt-4 mx-4 font-16">
      <div class="col-2">
        <a href="javascript:void(0)" (click)="creditBillSubmission()">Credit Bill Submission</a>
      </div>
      <div class="col-2">
        <a href="javascript:void(0)" (click)="receivableEntryPopup(receivableEntryBlock)">Receivables Entry</a>
      </div>
      <div class="col-2">
        <a href="javascript:void(0)" (click)="allocationsPopup(allocationsPopupBlock)">Allocations</a>
      </div>
      <div class="col-2">
        <a href="javascript:void(0)" (click)="agingReportPopup(agingReportPopupBlock)">Aging Report</a>
      </div>
      <div class="col-4">
      </div>
      <!-- <div class="col-2 text-right">
        <button (click)="backTo()" class="btn btn-sm mx-1 text-white" style="background-color: #173267;">Back</button>
      </div> -->
    </div>
</div>

<!-- Credit Bill Submission link -->
<ng-template #creditBillSubmissionBlock let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Input Controls</h4>
      <button type="button" class="close" aria-label="Close" (click)="popUpClose();">
          <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">      
    </div>
</ng-template>

<!-- Receivables Entry link -->
<ng-template #receivableEntryBlock let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Input Controls</h4>
      <button type="button" class="close" aria-label="Close" (click)="popUpClose();">
          <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="receivablesForm" (ngSubmit)="submitReceivablesForm()">
        <div style="font-size: 16px;">
            <div class="row mt-3 mb-3">
                <label for="payeename" class="col-md-5 font-weight-bold font">Payee Name</label>
                <div class="col-md-7">
                  <select class="form-control input-textt2 w-100" name="payeename" formControlName="PAYEENAME"
                    id="payeename">
                    <option value="">Select Payee Name</option>
                    <option *ngFor="let payee of payeeNamesInfo" [ngValue]="payee?.PAYEE_ID">{{payee?.PIM_NAME}}</option>
                  </select>
                  <!-- <div *ngIf="submitted && f.paymentType.errors" class="invalid-feedback">
                    <div *ngIf="f.paymentType.errors.required">Payment Type is required</div>
                  </div> -->
                </div>                                
            </div>
            <div class="row mt-3 mb-3">
              <label for="amount" class="col-md-5 font-weight-bold font">Cheque/ECS/DD Amount</label>
              <div class="col-md-7">
                <input type="text" class="form-control-sm form-control border border-primary" 
                name="amount" 
                formControlName="CHEQUEAMOUNT"
                [ngClass]="{ 'is-invalid': submitted && f.CHEQUEAMOUNT.errors }"
                  id="amount" required>
                
                <!-- <div *ngIf="submitted && f.CHEQUEAMOUNT.errors" class="invalid-feedback">
                  <div *ngIf="f.CHEQUEAMOUNT.errors.required">From Date is required</div>
                </div> -->
              </div>
            </div>
            <div class="row mt-3 mb-3">
                <label for="cheqno" class="col-md-5 font-weight-bold font">Cheque/ECS/DD Number</label>
                <div class="col-md-7">
                  <input type="text" class="form-control-sm form-control border border-primary" 
                  name="cheqno" 
                  formControlName="CHEQUENUMBER"
                  [ngClass]="{ 'is-invalid': submitted && f.CHEQUENUMBER.errors }"
                    id="cheqno" required>
                  
                  <!-- <div *ngIf="submitted && f.CHEQUENUMBER.errors" class="invalid-feedback">
                    <div *ngIf="f.CHEQUENUMBER.errors.required">From Date is required</div>
                  </div> -->
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <label for="chDate" class="col-md-5 font-weight-bold font">Cheque/ECS/DD Date</label>
                <div class="col-md-7">
                  <input type="date" class="form-control-sm form-control border border-primary" 
                  name="chDate" 
                  formControlName="CHEQUEDATE"
                  [ngClass]="{ 'is-invalid': submitted && f.CHEQUEDATE.errors }"
                    id="chDate" required>
                  
                  <!-- <div *ngIf="submitted && f.CHEQUEDATE.errors" class="invalid-feedback">
                    <div *ngIf="f.CHEQUEDATE.errors.required">From Date is required</div>
                  </div> -->
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <label for="rmrks" class="col-md-5 font-weight-bold font">Remarks</label>
                <div class="col-md-7">
                  <input type="text" class="form-control-sm form-control border border-primary" 
                  name="rmrks" 
                  formControlName="REMARKS"
                  [ngClass]="{ 'is-invalid': submitted && f.REMARKS.errors }"
                    id="rmrks" required>
                  
                  <!-- <div *ngIf="submitted && f.REMARKS.errors" class="invalid-feedback">
                    <div *ngIf="f.REMARKS.errors.required">From Date is required</div>
                  </div> -->
                </div>
            </div>                    
        </div>
  
        <div class="text-right mt-5 mb-1 mr-5">
          <button type="submit" class="col-md-2 btn mx-2 p-2 text-white"
            style="background-color: #173267; width: 80px">Submit</button>
          <button (click)="setValuestoEmpty()" type="button"
            class="col-md-2 btn mx-2 resetSub p-2 text-white"
            style="background-color: #173267; width: 80px">Reset</button>        
        </div>      
      </form>
    </div>
</ng-template>

<!-- Allocations link -->
<ng-template #allocationsPopupBlock let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Input Controls</h4>
      <button type="button" class="close" aria-label="Close" (click)="popUpClose();">
          <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="allocationsForm" (ngSubmit)="submitAllocationsForm()">
        <div style="font-size: 16px;">
            <div class="row mt-3 mb-3">
                <label for="start" class="col-md-5 font-weight-bold font">From Date</label>
                <div class="col-md-7">
                  <input type="date" class="form-control-sm form-control border border-primary" 
                  name="start" placeholder="DD-MM-YYYY"
                  formControlName="STARTDATE"
                  [ngClass]="{ 'is-invalid': submitted && g.STARTDATE.errors }"
                    id="start" required>
                  
                  <!-- <div *ngIf="submitted && g.STARTDATE.errors" class="invalid-feedback">
                    <div *ngIf="g.STARTDATE.errors.required">From Date is required</div>
                  </div> -->
                </div>
            </div>
            <div class="row mt-3 mb-3">
              <label for="end" class="col-md-5 font-weight-bold font">To Date</label>
              <div class="col-md-7">
                <input type="date" class="form-control-sm form-control border border-primary" 
                name="end" placeholder="DD-MM-YYYY"
                formControlName="ENDDATE"
                [ngClass]="{ 'is-invalid': submitted && g.ENDDATE.errors }"
                  id="end" required>
                
                <!-- <div *ngIf="submitted && g.ENDDATE.errors" class="invalid-feedback">
                  <div *ngIf="g.ENDDATE.errors.required">To Date is required</div>
                </div> -->
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <label for="payeename" class="col-md-5 font-weight-bold font">Payee Name</label>
              <div class="col-md-7">
                <select class="form-control input-textt2 w-100" name="payeename" formControlName="PAYEENAME"
                  id="payeename">
                  <option value="">Select Payee Name</option>
                  <option *ngFor="let payee of payeeNamesInfo" [ngValue]="payee?.PAYEE_ID">{{payee?.PIM_NAME}}</option>
                </select>
                <!-- <div *ngIf="submitted && g.paymentType.errors" class="invalid-feedback">
                  <div *ngIf="g.paymentType.errors.required">Payment Type is required</div>
                </div> -->
              </div>                                
            </div>          
        </div>
  
        <div class="text-right mt-5 mb-1 mr-5">
          <button type="submit" class="col-md-2 btn mx-2 p-2 text-white"
            style="background-color: #173267; width: 80px">Submit</button>
          <button (click)="setValuestoEmpty()" type="button"
            class="col-md-2 btn mx-2 resetSub p-2 text-white"
            style="background-color: #173267; width: 80px">Reset</button>        
        </div>      
      </form>
    </div>
</ng-template>

<!-- Aging Reports link -->
<ng-template #agingReportPopupBlock let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Input Controls</h4>
      <button type="button" class="close" aria-label="Close" (click)="popUpClose();">
          <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="agingReportsForm" (ngSubmit)="submitAgingReportForm()">
        <div style="font-size: 16px;">
            <div class="row mt-3 mb-3">
                <label for="start" class="col-md-5 font-weight-bold font">From Date</label>
                <div class="col-md-7">
                  <input type="date" class="form-control-sm form-control border border-primary" 
                  name="start" placeholder="DD-MM-YYYY"
                  formControlName="STARTDATE"
                  [ngClass]="{ 'is-invalid': submitted && h.STARTDATE.errors }"
                    id="start" required>
                  
                  <!-- <div *ngIf="submitted && h.STARTDATE.errors" class="invalid-feedback">
                    <div *ngIf="h.STARTDATE.errors.required">From Date is required</div>
                  </div> -->
                </div>
            </div>
            <div class="row mt-3 mb-3">
              <label for="end" class="col-md-5 font-weight-bold font">To Date</label>
              <div class="col-md-7">
                <input type="date" class="form-control-sm form-control border border-primary" 
                name="end" placeholder="DD-MM-YYYY"
                formControlName="ENDDATE"
                [ngClass]="{ 'is-invalid': submitted && h.ENDDATE.errors }"
                  id="end" required>
                
                <!-- <div *ngIf="submitted && h.ENDDATE.errors" class="invalid-feedback">
                  <div *ngIf="h.ENDDATE.errors.required">To Date is required</div>
                </div> -->
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <label for="payeename" class="col-md-5 font-weight-bold font">Payee Name</label>
              <div class="col-md-7">
                <select class="form-control input-textt2 w-100" name="payeename" formControlName="PAYEENAME"
                  id="payeename">
                  <option value="">Select Payee Name</option>
                  <option *ngFor="let payee of payeeNamesInfo" [ngValue]="payee?.PAYEE_ID">{{payee?.PIM_NAME}}</option>
                </select>
                <!-- <div *ngIf="submitted && h.paymentType.errors" class="invalid-feedback">
                  <div *ngIf="h.paymentType.errors.required">Payment Type is required</div>
                </div> -->
              </div>                                
            </div>          
        </div>
  
        <div class="text-right mt-5 mb-1 mr-5">
          <button type="submit" class="col-md-2 btn mx-2 p-2 text-white"
            style="background-color: #173267; width: 80px">Submit</button>
          <button (click)="setValuestoEmpty()" type="button"
            class="col-md-2 btn mx-2 resetSub p-2 text-white"
            style="background-color: #173267; width: 80px">Reset</button>        
        </div>      
      </form>
    </div>
</ng-template>

