import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConstantsModule} from '../constants/constants.module';
import {HttpClientModule} from '@angular/common/http';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AgeCalculationPipe } from './pipes/age-calculation.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml';
import { OnlyNumberdecimalDirective } from './directives/only-numberdecimal.directive';
import { PatientRegistrationFormComponent } from './patient-registration-form/patient-registration-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { IpadmissionInfoComponent } from './ipadmission-info/ipadmission-info.component';
import { DischargeSummaryPopupComponent } from './discharge-summary-popup/discharge-summary-popup.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { UpdatePayeeComponent } from './update-payee/update-payee.component';
import { NgxEditorModule } from 'ngx-editor';
import {IcdComponent} from './icd-10/icd.component'
import { ViewLabReportsComponent } from './view-lab-reports/view-lab-reports.component';
import { UpdateExternalComponent } from './update-external/update-external.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { PatientRoomInfoComponent } from './patient-room-info/patient-room-info.component';
import { CreditBillSubmissionComponent } from './credit-bill-submission/credit-bill-submission.component';

@NgModule({
  exports: [
    SidenavComponent, 
    AgeCalculationPipe, 
    SafeHtmlPipe,
    OnlyNumberdecimalDirective,
    PatientRegistrationFormComponent, 
    IpadmissionInfoComponent, 
    DischargeSummaryPopupComponent,
    PatientInfoComponent,
    UpdatePayeeComponent,
    IcdComponent,
    ViewLabReportsComponent,
    UpdateExternalComponent,
    PatientRoomInfoComponent,
    CreditBillSubmissionComponent
  ],
  declarations: [
    SidenavComponent, 
    AgeCalculationPipe, 
    SafeHtmlPipe,
    OnlyNumberdecimalDirective, 
    PatientRegistrationFormComponent, 
    IpadmissionInfoComponent, 
    DischargeSummaryPopupComponent, 
    PatientInfoComponent, 
    UpdatePayeeComponent,
    IcdComponent,
    ViewLabReportsComponent,
    UpdateExternalComponent,
    PatientRoomInfoComponent,
    CreditBillSubmissionComponent
  ],
  imports: [
    CommonModule,
    ConstantsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    CKEditorModule,
    NgxEditorModule.forRoot({
      locals: {
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        url: 'false',
      }
    }),
  ]
})
export class SharedModule { }
