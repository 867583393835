<div id="print-prescription-section" class="print-form"
  *ngIf="admissionFormPrintData !== undefined">
  <div class="border border-dark">
    <div class="row no-gutters heading-block border-bottom border-dark">
      <div class="col-md-2 logo">
        <!-- <img src="./assets/hospital-logo.jpg" class="img-thumbnail" /> -->
        <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
      </div>
      <div class="col-md-9">
        <div class="text-center">
          <h4 class="main-title mb-0 pdf-hospital-heading font-weight-bold">{{ admissionFormPrintData?.HospitalName }}</h4>
          <span style="font-size: 12px; font-weight: 700; display: inline-block; ">{{ admissionFormPrintData?.HospitalTagLine }}</span>

          <p style="font-weight: 700;">{{admissionFormPrintData?.HospitalAddress}}</p>
          <h4 class="sub-title1 mb-1 font-weight-bold">
            {{admissionFormPrintData?.TitleLabel}}</h4>
        </div>
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-2">
      <div class="col-md-1 font-weight-bold">IP No. &nbsp;&nbsp;:</div>
      <div class="col-md-2 font-weight-bold border border-dark mr-3 p-1">
        {{admissionFormPrintData?.IpNo}}
      </div>
      <div class="col-md-1 font-weight-bold">
        U.M.R.No&nbsp;&nbsp;:
      </div>
      <div class="col-md-2 font-weight-bold border border-dark mr-3 p-1">
        {{admissionFormPrintData?.UMRNo}}
      </div>
      <div class="col-md-2 font-weight-bold">
        Admission Date&nbsp;&nbsp;:
      </div>
      <div class="col-md-2">
        {{admissionFormPrintData?.AdmissionDate}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-2">
      <div class="col-md-2 font-weight-bold">
        Name of the Patient :
      </div>
      <div>
        {{admissionFormPrintData?.PatientName}}
      </div>
    </div>
    <div style="font-weight: bolder;">
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Father/Husband Name :</div>
      <div class="col-md-4">{{admissionFormPrintData?.FatherHusband}}</div>
      <div class="col-md-1">Age :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Age}}</div>
      <div class="col-md-1">Gender :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Sex}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Mother's Name :</div>
      <div class="col-md-3">{{admissionFormPrintData?.MothersName}}</div>
      <div class="col-md-2">Marital Status :</div>
      <div class="col-md-2">{{admissionFormPrintData?.MaritalStatus}}</div>
      <div class="col-md-1">Occupation :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Occupation}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Address :
      </div>
      <div>
        {{admissionFormPrintData?.Address}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Phone Number :</div>
      <div class="col-md-2"><b>(R)</b></div>
      <div class="col-md-2"><b>(O)&nbsp;</b>{{admissionFormPrintData?.Phoneno}}</div>
      <div class="col-md-1">Nationality :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Nationality}}</div>
      <div class="col-md-1">Religion :</div>
      <div class="col-md-2">{{admissionFormPrintData?.Religion}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Consultant Doctor :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.ConsultantDoctor}}
      </div>
      <div class="col-md-2">
        Department :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.Department}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Ref. By Doctor :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.RefByDoctor}}
      </div>
      <div class="col-md-2">
        Ref. Source :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.RefSource}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">
        Credit Organisation :
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.CreditOrgnaization}}
      </div>
      <div class="col-md-2">
        <b>Ward / Bed No :</b>
      </div>
      <div class="col-md-4">
        {{admissionFormPrintData?.WardBedNo}}
      </div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Diagnosis on Admission :</div>
      <div>{{admissionFormPrintData?.DiagnosisOnAdmission}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Final Diagnosis :</div>
      <div class="col-md-6">{{admissionFormPrintData?.FinalDiagnosis}}</div>
      <div class="col-md-1 font-weight-bold">I.C.D Code :</div>
      <div class="col-md-3">{{admissionFormPrintData?.FinalDiagnosisIcdCode}}</div>
    </div>
    <div class="row  no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Secondary Diagnosis :</div>
      <div class="col-md-6">{{admissionFormPrintData?.SecondaryDiagnosis}}</div>
      <div class="col-md-1 font-weight-bold">I.C.D Code :</div>
      <div class="col-md-3">{{admissionFormPrintData?.SecondaryDiagnosisIcdCode}}</div>
    </div>
    <div class="row px-3 pt-3">
      <div class="col-md-2">Surgerical Operation:</div>
      <div class="col-md-4">{{admissionFormPrintData?.SurgicalOperation}}</div>
      <div class="col-md-2 font-weight-bold">I.C.D Code :</div>
      <div class="col-md-4">{{admissionFormPrintData?.SurgicalOperationIcdCode}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Date :</div>
      <div class="col-md-4">{{admissionFormPrintData?.SurgicalOperationDate}}</div>
      <div class="col-md-2 font-weight-bold">Type of Anaesthesia :</div>
      <div class="col-md-4">{{admissionFormPrintData?.SurgicalOperationTypeOfAnaesthesia}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Cause of Death :</div>
      <div class="col-md-2">{{admissionFormPrintData?.CauseOfDeath}}</div>
      <div class="col-md-2 font-weight-bold">Under 48hrs. :</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-2 font-weight-bold">Above 48hrs. :</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1">Date of Death: </div>
      <div class="col-md-1">{{admissionFormPrintData?.DateOfDeath}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-2">Date Of Discharge :</div>
      <div class="col-md-4">{{admissionFormPrintData?.DateofDischarge}}</div>
      <div class="col-md-2 font-weight-bold">Hospital Days :</div>
      <div class="col-md-4">{{admissionFormPrintData?.HospitalDays}}</div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3">
      <div class="col-md-1 font-weight-bold">Result :</div>
      <div class="col-md-1">Recovered</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1 font-weight-bold">Improved</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-2 font-weight-bold">Diagnosis Only</div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1 font-weight-bold">LAMA </div>
      <div class="col-md-1">
        <div class="checkBox"></div>
      </div>
      <div class="col-md-1 font-weight-bold">
        <div class="row">
          <div class="mr-2">MLC</div>
          <div class="checkBox"></div>
        </div>
      </div>
      <div class="col-md-1 font-weight-bold">
        <div class="row">
          <div class="mr-2">EXP</div>
          <div class="checkBox"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters  border-bottom border-dark p-3 pt-8">
      <div class="col-md-2">Signature of Resident</div>
      <div class="col-md-8"></div>
      <div class="col-md-2">Signature of Consultant</div>
    </div>
    <div class=" p-3 font-weight-bold">Obstetric Data :</div>
    <div class="row px-3 py-1">
      <div class="col-md-1">Normal</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Live Birth</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Date</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
    </div>
    <div class="row px-3 py-1">
      <div class="col-md-1">Abnormal</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Twin</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Male</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
    </div>
    <div class="row px-3 py-1 mb-2">
      <div class="col-md-1">Weight</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Still Birth</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
      <div class="col-md-1">Female</div>
      <div class="col-md-3">
        <div class="checkBox1"></div>
      </div>
    </div>
  </div>
  </div>
  <div style="height: 250px;"></div>
  <div class="border border-dark">
    <div class="font-weight-bold text-center p-3">
      AUTHORISATION FOR INVESTIGATIONS, PROCEDURES,
      SURGICAL OPERATIONS AND TREATMENT
    </div>
    <div class="p-3">
      I ______________________________________________ S/O D/O W/O F/O ____________________________
      resident of
      necessary for the diagnosis and treatment .
      any Procedures like Biopsy, Dialysis, Surgical Operations under any anaesthesia as and when required or deemed
      investigations including HIV Testing and administer necessary drugs, injections and blood transfusion and to
      undertake
      authorise all medical, nursing and paramedical staff of {{admissionFormPrintData?.HospitalName | titlecase}}, to conduct all
      necessary
    </div>
    <div class="row p-3">
      <div class="col-md-2">Witness Name :</div>
    </div>
    <div class="row px-3">
      <div class="col-md-2">Signature :</div>
    </div>
    <div class="row p-3">
      <div class="col-md-2">Date :</div>
      <div class="col-md-7"></div>
      <div class="col-md-3">Signature of Patient / Attendant</div>
    </div>
    <div class="font-weight-bold text-center p-3">
      AUTHORISATION FOR RELEASE OF INFORMATION
    </div>
    <div class="p-3">
      I hereby authorise the {{admissionFormPrintData?.HospitalName | titlecase}}, Hyderabad to release any document from any Medical
      record
      for the purpose of research, claim or settlements to any person or agency or settlements to any company
      permitted
      under law. I shall not hold the authorities liable for any legal consequences arising from the release of the
      said
      information.

    </div>
    <div class="row p-3">
      <div class="col-md-2">Witness Name :</div>
    </div>
    <div class="row px-3">
      <div class="col-md-2">Signature :</div>
    </div>
    <div class="row p-3">
      <div class="col-md-2">Date :</div>
      <div class="col-md-7">{{admissionFormPrintData?.Dischargformdate}}</div>
      <div class="col-md-3">Signature of Patient / Attendant</div>
    </div>
    <div class="font-weight-bold text-center p-3">
      DISCHARGE AGAINST MEDICAL ADVISE FORM
    </div>
    <div class="p-3">
      I am responsible for taking the patient
      _____{{admissionFormPrintData?.PatientName}}__________________________________ at my own
      risk.
      The patient condition and prognosis was explained to me by the consultant and Doctor on duty in the vernacular
      language understand by me. Yet, we have decided to take patient against medical advice. In case of any
      eventuality i will not hold the hospital authorities responsible.

    </div>
    <div class="row p-3">
      <div class="col-md-6 border-right border-dark">
        <div class="row p-3">
          SIGNATURE OF
          <br>
          CONSULTANT /
          <br>
          DOCTOR ON DUTY
          <h1 class="px-4">}</h1>
        </div>
        <div class="row px-3 py-2">
          <div>Name :</div>
        </div>
        <div class="row px-3 py-2">
          <div>Registration No :</div>
        </div>
        <div class="row py-2">
          <div class="col-md-2">Date :</div>
          <div class="col-md-10 font-weight-bold">{{admissionFormPrintData?.Dischargformdate}}</div>
        </div>
        <div class="row py-2">
          <div class="col-md-2">Time :</div>
          <div class="col-md-10 font-weight-bold">{{admissionFormPrintData?.Dischargformtimw}}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row px-3 py-2">Signature</div>
        <div class="row px-3 py-2">
          <div>Name :</div>
        </div>
        <div class="row px-3 py-2">
          <div>Relation to the Patient :</div>
        </div>
        <div class="row no-gutters border-bottom border-dark py-2">
          <div class="col-md-2">Date :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformdate}}</div>
          <div class="col-md-2">Time :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformtimw}}</div>
        </div>
        <div class="row px-3 py-2">
          <div>Wittness Name :</div>
        </div>
        <div class="row px-3 py-2">Signature</div>
        <div class="row py-2">
          <div class="col-md-2">Date :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformdate}}</div>
          <div class="col-md-2">Time :</div>
          <div class="col-md-4 font-weight-bold">{{admissionFormPrintData?.Dischargformtimw}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
