
<div>
        <div class="col-md-12 mb-5 font-16">
            <p style="text-align: right; padding-right: 25px;"><b>DATE: {{patientInfo.TodayDate}}</b></p>
        </div><br><br><br>
        <div class="text-center mb-2">
            <h2 style="font-weight: bold;"><u>GENUINENESS CERTIFICATE</u></h2>
        </div><br><br>
        <div class="col-md-12 font-16">
            <p><b>To,</b></p>
        </div>        
        <br><br><br><br>
        <div class="row px-4 font-16 mb-3">
            <div class="col-md-6">
                <div class="row font-weight-bold">
                    <p>Sub: Genuineness certificate – RegLr No: </p>
                </div>                
            </div>
            <div class="col-md-6 text-right">
                <div class="row">
                    <p class="col-md-12" style="text-align: right; padding-right: 130px;"><b>DATE: </b></p>
                </div> 
            </div>
        </div>
        <div class="text-justify col-md-12 font-16">
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to state that as per your request, we are certifying that the Medical Bill of <b>{{patientInfo.Pname}}</b>  
                aged in <b>{{patientInfo.Age}}</b>, S-W-D-B/O <b>{{patientInfo.SoDoRelation}}</b>. He/She was admitted and treated <b>{{patientInfo.Diagnosis}}</b> under 
                <b>{{patientInfo.TreatingDoctor}}</b> from <b>{{patientInfo.Doa | date: "dd-MMM-yyyy"}}</b> to <b>{{patientInfo.Dod | date: "dd-MMM-yyyy"}}</b>. He/She has paid an amount of 
                <b>Rs. {{patientInfo.GrandTotal}} /-</b> for his/her hospital bill amount. 
            </p><br><br>
            <p>
                This is a genuine case and for your information and records.
            </p>
            <br><br><br>
            <p>
                Thanking You,
            </p>
            <br><br><br>
            <p>
                For <b>{{patientInfo.HospitalName}}</b>
            </p>
            <br><br><br>
            <p>
                <b>AUTHORISED SIGNATORY</b>
            </p>
        </div>
        <div class="col-md-12 mt-3 text-center">
            <button class="col-sm-1 btn btn-primary btn-sm mx-3" (click)="getPrint()">Print</button>
            <button class="col-sm-1 btn btn-primary btn-sm mx-3" (click)="closePopup()">Close</button>
        </div>
</div>

<div id="genuineness-print" style="display: none; margin-top: 1.3in;" class="print-info">
        <!-- display: none; -->
        <div class="col-md-12">
            <div class="col-md-12 mb-5">
                <p style="font-size: 22px; text-align: right; padding-right: 25px;"><b>DATE: {{patientInfo.TodayDate}} </b></p>
            </div><br><br><br>
            <div class="text-center mb-2">
                <h2 style="font-weight: bold;"><u>GENUINENESS CERTIFICATE</u></h2>
            </div><br><br>
            <div class="col-md-12">
                <p style="font-size: 22px;"><b>To, </b></p>
            </div>        
            <br><br><br><br><br><br><br>
            <div class="row px-4 mb-4">
                <div class="col-md-6">
                    <div class="row font-weight-bold">
                        <p style="font-size: 22px;">Sub: Genuineness certificate – RegLr No: </p>
                    </div>                
                </div>
                <div class="col-md-6 text-right">
                    <div class="row">
                        <p class="col-md-12" style="font-size: 22px; padding-right: 150px;"><b>DATE: </b></p>
                    </div> 
                </div>
            </div>
            <div class="text-justify col-md-12">
                <p style="font-size: 22px;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to state that as per your request, we are certifying that the Medical Bill of <b>{{patientInfo.Pname}}</b>  
                    aged in <b>{{patientInfo.Age}}</b>, S-W-D-B/O <b>{{patientInfo.SoDoRelation}}</b>. He/She was admitted and treated <b>{{patientInfo.Diagnosis}}</b> under 
                    <b>{{patientInfo.TreatingDoctor}}</b> from <b>{{patientInfo.Doa | date: "dd-MMM-yyyy"}}</b> to <b>{{patientInfo.Dod | date: "dd-MMM-yyyy"}}</b>. He/She has paid an amount of 
                    <b>Rs. {{patientInfo.GrandTotal}} /-</b> for his/her hospital bill amount. 
                </p><br><br>
                <p style="font-size: 22px;">
                    This is a genuine case and for your information and records.
                </p>
                <br><br><br><br>
                <p style="font-size: 22px;">
                    Thanking You,
                </p>
                <br><br><br>
                <p style="font-size: 22px;">
                    For <b>{{patientInfo.HospitalName}}</b> 
                </p>
                <br><br><br><br><br>
                <p style="font-size: 22px;">
                    <b>AUTHORISED SIGNATORY</b>
                </p>
            </div>
        </div>        

</div>

