import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { NursestationsService } from 'src/app/ward-management/nursestations.service';
import { WardService } from 'src/app/ward-management/ward.service';

@Component({
  selector: 'app-discharge-list',
  templateUrl: './discharge-list.component.html',
  styleUrls: ['./discharge-list.component.css']
})
export class DischargeListComponent implements OnInit {
  userInfo: any;
  advSearchForm: any;
  patientsList: any = [];
  patientsData = new MatTableDataSource(this.patientsList);
  columnsToDisplay = [
    'IPNUM',
    'NAMEGENDERAGE',
    'REFERRALDOCTOR',
    'CONSULTDOCTORWARDBED',
    'ADMISSIONDATEANDTIME',
    'DISCHARGETYPE',
    'DISCHARGEDDATE',    
    'ACTION',
  ];
  selfPayList: any;
  insuranceList: any;
  patients: any = {};
  icdPatientDetails: any;
  patientIPNo: any;
  patientUMRNo: any;
  dischargeSummaryPatientInfo: any;  
  existingDischargeSummaryInfo: any = '';
  patientQuickInformation: any;
  existingRecord: boolean = false;
  patientName: any;

  constructor(private tokenService: TokenStorageService,
    private printerService: NgxPrinterService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private inpatientService: InpatientService,
    private nursestationsService: NursestationsService,
    private wardService: WardService,
    private toastr: ToastrService,
    private router: Router) {
      this.userInfo = this.tokenService.getUser();
     }

  ngOnInit(): void {
    this.advSearchForm = this.formBuilder.group({
      IPNO: [''],
      PATIENTNAME: [''],      
      UMRID: [''],
      FROMDATE: [''],
      TODATE: ['']
    });
    this.loadPatientsList();
  }

  backNav(): void {
    this.router.navigate(['/frontOffice']);
  }

  /* to load the patients list */
  loadPatientsList(){
    if(this.advSearchForm.controls.PATIENTNAME.value.length > 2 || this.advSearchForm.controls.PATIENTNAME.value.length == 0)
     {
       this.patientName = this.advSearchForm.controls.PATIENTNAME.value;
     }
    const input = {
      "start":0,
      "limit":10,
      "ser_hospitalid": this.userInfo.hospitalID,
      "sear_umr_no": this.advSearchForm.controls.UMRID.value,
      "sear_ip_no": this.advSearchForm.controls.IPNO.value,
      "sear_patient": this.patientName,
      "fromDate": this.advSearchForm.controls.FROMDATE.value,
      "toDate": this.advSearchForm.controls.TODATE.value,
      "sear_admission_date":"",
      "sear_consult_doctor":"",
      "sear_ptmid":"",
      "admission_date_order":"",
      "doctor_name_order":""
      }
      this.inpatientService.DischargepatientsList(input).subscribe((response: any)=>{
        this.patients = response;
        this.patientsList = this.patients;
        //console.log(this.patientsList);
        this.patientsData = new MatTableDataSource(this.patientsList);
      },(err) =>{
        this.patientsData = new MatTableDataSource([]);
      });
  }
  onSearch(): void {
    this.loadPatientsList();
  }

  patientQuickInfo(patientQuickInfoPopup, patient): void {
    this.patientQuickInformation = patient;
    this.modalService.open(patientQuickInfoPopup, { size: 'xl' });
  }

  viewLabReportsPopup(labReportsPopup, patientData)
  {
    console.log(patientData);
    this.patientIPNo = patientData.IP_NR;
    this.patientUMRNo = patientData.P_ID;
    this.modalService.open(labReportsPopup, { size: 'xl'});
  }

  /* printDischargeSummary(dischargeSummaryPrintPopup, patientData)
  {
    this.dischargeSummaryPatientInfo = patientData;
    this.modalService.open(dischargeSummaryPrintPopup, { size: 'xl'});
  } */

  actionIcdPop(icdPopup, patientDta){
    this.icdPatientDetails = patientDta;
    this.modalService.open(icdPopup, {size: 'xl'});
  }

  printDischargeSummary(dischargeSummaryPrintPopup, patientData): void {
    //console.log("patient data", patientData)
    const data = {
      UMRNo: patientData.P_ID,
      IpNr: patientData.IP_NR,
      PatientName: patientData.PATIENT_NAME,
      GenderAge: patientData.AGE,
      ConsultDoctor: patientData.CONSULT_DOCTOR,
      address: patientData.address,
      refDoctorName: patientData.externalRefDoctor,
      admissionDate: patientData.IP_ADMISSION_DATE,
      dischargeDate: patientData.IP_DISCHARGE_DATE,
      headingTitle: patientData.HEADING_TITLE,
      dischargeType: patientData.DISCHARGE_TYPE,
      title: patientData.TITLE
    };
    this.dischargeSummaryPatientInfo = data;
    this.getDischargeSummary(patientData.IP_NR, dischargeSummaryPrintPopup);
  }

  getDischargeSummary(ipNr, dischargeSummaryPrintPopup): void {
    const inputRequest = {
      ipNr,
    };
    this.nursestationsService.getDischargeSummaryInfo(inputRequest).subscribe(
      (dischargeSummaryInfo: any) => {
        // console.log("dischargeSummaryInfo", dischargeSummaryInfo);
        this.dischargeSummaryPatientInfo.dischargeType = dischargeSummaryInfo.DISCHARGE_TYPE
        this.dischargeSummaryPatientInfo.headingTitle = dischargeSummaryInfo.HEADING_TITLE
        this.dischargeSummaryPatientInfo.address = dischargeSummaryInfo.ADDRESS
        this.dischargeSummaryPatientInfo.refDoctorName = dischargeSummaryInfo.DOCTOR_NAME
        this.dischargeSummaryPatientInfo.ConsultDoctor = dischargeSummaryInfo.CONSULTANT_DOC_NAME
        this.existingDischargeSummaryInfo = dischargeSummaryInfo
        this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg' })
        this.existingRecord = true
      },
      (err) => {
        console.log('HTTP Error', err.error);
        // this.toastrService.error(err.error.message);
        this.existingRecord = false
        this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg' })
      }
    );
  }
  
  popupClose(){
    this.modalService.dismissAll();
  }
  clearSearchParameters(){
    this.advSearchForm.reset();
  }
}
