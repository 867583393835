import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';

@Component({
  selector: 'app-insurance-info',
  templateUrl: './insurance-info.component.html',
  styleUrls: ['./insurance-info.component.css']
})
export class InsuranceInfoComponent implements OnInit {

  bodyDisplay = 'insuranceInfo';

  receivablesForm: FormGroup;
  allocationsForm: FormGroup;
  agingReportsForm: FormGroup;
  userInfo: any;
  payeeNamesInfo: any = [];

  constructor(
    private modalService: NgbModal,
    private tokenService: TokenStorageService,
    private inpatientService: InpatientService,
    private router: Router
  ) { 
    this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {
    this.receivablesForm = new FormGroup ({
      PAYEENAME: new FormControl ('',[Validators.required]),
      CHEQUEAMOUNT: new FormControl ('',[Validators.required]),
      CHEQUENUMBER: new FormControl ('',[Validators.required]),
      CHEQUEDATE: new FormControl ('',[Validators.required]),
      REMARKS: new FormControl ('',[Validators.required])
    });

    this.allocationsForm = new FormGroup ({
      STARTDATE: new FormControl ('',[Validators.required]),
      ENDDATE: new FormControl ('',[Validators.required]),
      PAYEENAME: new FormControl ('',[Validators.required])
    });

    this.agingReportsForm = new FormGroup ({
      STARTDATE: new FormControl ('',[Validators.required]),
      ENDDATE: new FormControl ('',[Validators.required]),
      PAYEENAME: new FormControl ('',[Validators.required])
    });
    this.getPayeeNamesList();
  }

  get f(): any { return this.receivablesForm.controls; }
  get g(): any { return this.allocationsForm.controls; }
  get h(): any { return this.agingReportsForm.controls; }

  backTo() {
    this.router.navigate(['/billing']);
  }

  getPayeeNamesList(){
    const input = {
      hId: this.userInfo.hospitalID,
      ptmId: 2
    }
    this.inpatientService.getPayeenamesList(input).subscribe((response)=>{
      console.log("payeeNamesInfo: ", response);
      this.payeeNamesInfo = response;      
    });
  }

  creditBillSubmission(){
    this.router.navigate(['/insurance-patients']);
  }
  receivableEntryPopup(receivableEntryBlock) {
    this.modalService.open(receivableEntryBlock);
  }
  allocationsPopup(allocationsPopupBlock) {
    this.modalService.open(allocationsPopupBlock);
  }
  agingReportPopup(agingReportPopupBlock) {
    this.modalService.open(agingReportPopupBlock);
  }

  submitReceivablesForm(){
   // 
  }

  submitAllocationsForm(){
    //
  }

  submitAgingReportForm(){
    //
  }

  popUpClose() {
    this.modalService.dismissAll();
    //this.setValuestoEmpty();
  }
}
