<div class="container-fluid total-page">

    <div class="row page-header">
      <div class="col-md-11 text-center p-0">
        <div>
          <h4 class="text-uppercase bold font-weight-bold orange-color">
            IP DISCHARGE LIST
            <!-- <span class="descriptiveText" *ngIf="totalPatientsList != ''">
              (Total Patients: {{ totalPatientsList }})
            </span> -->
          </h4>
        </div>
        <!-- <span class="descriptiveText pr-1" *ngIf="selfPayList != 0">
          (Self-Pay Patients: {{ selfPayList }})
        </span>
        <span class="descriptiveText pr-1" *ngIf="insuranceList != 0">
          (Insurance Patients: {{ insuranceList }})
        </span> -->
      </div>
      <div class="col-md-1 text-center p-0" style="line-height: 80px;">
        <button type="button" (click)="backNav()" class="btn border-btn mr-3 px-4">Back</button>
      </div>
    </div>

    <!-- Advanced search block -->
    <div class="row">

     <!-- Advance search -->
     <div class="col-12 mb-1">
        <div class="p-3 mx-3">
          <form [formGroup]="advSearchForm" (ngSubmit)="onSearch()" class="form-inline" autocomplete="off">
            <div class="row advance-search">
              <div class="col-md-3 mb-2 mt-2">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname mb-3">UMR NO</label>
                  <div class="col-8 px-0">
                    <input formControlName="UMRID" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with full UMR number</span>
                  </div>
                </div>
              </div>

              <div class="col-md-4 mb-2 mt-2">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname mb-3 text-left">Patient Name</label>
                  <div class="col-6 px-0">
                    <input formControlName="PATIENTNAME" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with minimum 3 characters</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mb-2 mt-2">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname mb-3">IP NO</label>
                  <div class="col-8 px-0">
                    <input formControlName="IPNO" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with full IP number</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mb-2">
                <div class="form-group row no-gutters">
                  <label class="col-4 mb-2 modname">From Date </label>
                  <div class="col-8 px-0">
                    <input formControlName="FROMDATE" class="form-control input-textt1 w-100" type="date"/>
                  </div>
                </div>
              </div>

              <div class="col-md-4 mb-2">
                <div class="form-group row no-gutters">
                  <label class="col-md-4 mb-2 modname">To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                  <div class="col-md-6 px-0">
                    <input formControlName="TODATE" class="form-control input-textt1 w-100" type="date" />
                  </div>
                </div>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="col-sm-1 mb-2 text-left">
                <button type="submit" class="btn btn-primary btn-sm advanced-search-btn">Search</button>
              </div>
              <div class="col-sm-1 mb-2 text-left">
                <button type="reset" class="btn btn-primary btn-sm advanced-search-btn" (click)="clearSearchParameters()">Reset</button>
              </div>
              <!-- <button type="button" class="btn btn-light float-right light-gray-btn mt-4 px-4">Reset</button> -->
              
            </div>
          </form>
        </div>
      </div>
      <!-- ./END Advance search -->

    </div>
    <!-- ./Advanced search block -->

    <div class="row justify-content-center mb-2">

      <div class="col-md-12">

        <div class="matcontent">
          <mat-table #table [dataSource]="patientsData" matSort>

            <ng-container matColumnDef="IPNUM">
              <mat-header-cell *matHeaderCellDef class="center">
                <div>IP NO</div>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element" class="center">
                <div>{{ element.IP_NR }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="NAMEGENDERAGE">
              <mat-header-cell *matHeaderCellDef>
                <div>Name & Gender/Age</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.name }}">
                <div>
                  <a href="javascript:void(0)" (click)="patientQuickInfo(patientQuickInfoPopup, element)">
                    {{ element.TITLE }} {{ element.PATIENT_NAME }}
                  </a><br/>{{ element.GENDER }}/<br>{{element.AGE}}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SPONSOR">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Sponsor / Payee Name</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{ element.PIM_NAME }} </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="BILLAMOUNT">
              <mat-header-cell *matHeaderCellDef class="centre">
                <div class="text-left">Bill Amount</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="centre">
                <div>{{element?.BILL_AMOUNT}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ADMISSIONDATE">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Admission Date</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_ADMISSION_DATE }}">
                <div>{{ element.IP_ADMISSION_DATE }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DISCHARGETYPE">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="text-left">Discharge Type</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_DISCHARGE_TYPE }}">
                <div>{{ element.IP_DISCHARGE_TYPE }}</div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="DISCHARGEDDATE">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="text-left">Discharge Date</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_DISCHARGE_DATE }}">
                <div>{{ element.IP_DISCHARGE_DATE }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ACTION">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Action</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="form-inline" style="min-width: 100px;">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      *ngIf="element.PTM_ID > 1 && rolesInfo?.attributeLevel?.ward_bed_management_in_patient_list_credit_bill_submission"
                      (click)="actionRouting('creditBill', element)" mat-menu-item>
                      <span>Credit Bill Submission</span>
                    </button>
                  </mat-menu>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
          </mat-table>
      
          <div class="mat-norecords" *ngIf="patientsData.data.length == 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
          <mat-paginator
          [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons 
          style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>

<!-- Patient QuickInfo Start -->
<ng-template #patientQuickInfoPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Patient Quick Info</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              IP Number
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_NR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Next of Kin / Guardian Name
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinName || patientQuickInformation.NEXT_OF_KIN_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              UMR No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.P_ID}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Relation
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinRelatoin}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Mobile No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.mobileNo || patientQuickInformation.NEXT_OF_KIN_MOBILENR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Date
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_DATE}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Time
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_TIME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admitting Doctor
            </div>
            <div class="col">
              <span>{{patientQuickInformation.CONSULT_DOCTOR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Treating Doctor / Cross Consultants
            </div>
            <div class="col">
              <span>{{patientQuickInformation?.treatingDoctors?.DM_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              External Referral
            </div>
            <div class="col">
              <span>{{patientQuickInformation.externalRefDoctor}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
</ng-template>
<!-- Patient QuickInfo End -->

<!-- Start credit bill submission popup -->
<ng-template #creditBillSubmissionPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Credit Bill Submission Form</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <app-credit-bill-submission [ipPatientNO]="selectedIPPatientNO" [patientURMNo]="seletedPatientURMNo"
        (closePopup)='closeModalPopup($event)'></app-credit-bill-submission>
    </div>
</ng-template>
<!-- End credit bill submission popup -->

