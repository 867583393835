import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Editor, Validators } from 'ngx-editor';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { NursestationsService } from '../nursestations.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { WARD_API_URLS } from '../wardConstants';
import { AppointmentService } from 'src/app/appointments/appointment.service';

@Component({
  selector: 'app-discharge-summary',
  templateUrl: './discharge-summary.component.html',
  styleUrls: ['./discharge-summary.component.css'],
})
export class DischargeSummaryComponent implements OnInit, OnDestroy {

  editor: Editor;
  html: '';
  public selectedBed: any = {};
  public userInfo: any = [];
  public isDischargeSummaryExist = false;
  public existingDischargeSummaryInfo: any = [];
  public defaultDescription = '';
  public defaultdepartmentName = 'Department';
  public defaultdischargeType = 'Summary';
  public showErrorMessage = false;
  dischargeSummaryForm: FormGroup;
  public departments: any = [];
  @ViewChild('dischargeSummaryPrintPopup')
  private dischargeSummaryPrintPopup: ElementRef;
  icdPatientDetails: any = {}
  dischargeTypesList: any = [  ];
  templatesList: any = [];
  maxDate: any = ''
  diagnosisForm: FormGroup;
  followupForm: FormGroup;
  diagnosis: any;
  
  constructor(
    private router: Router,
    private tokenService: TokenStorageService,
    private nursestationsService: NursestationsService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private appointmentService: AppointmentService)
    {
      this.userInfo = this.tokenService.getUser();
      this.selectedBed = this.nursestationsService.getSelectedBedUserInfo();
    }

  ngOnInit(): void {

    this.editor = new Editor();
    this.getDischargeSummary();
    this.getDepartmentTypes();
    this.getDischargeTypes()

    this.dischargeSummaryForm = new FormGroup({
      editorContent: new FormControl('', Validators.required()),
      departmentType: new FormControl('', Validators.required()),
      dischargeType: new FormControl('', Validators.required()),
      dischargeDate: new FormControl(''),
      dischargeTemplate: new FormControl('') 
    });
    this.diagnosisForm = new FormGroup({
      provisionalDiagnosis: new FormControl('',[Validators.required()])
    });
    this.followupForm = new FormGroup({
      fdate: new FormControl('',[Validators.required()])
    });
    this.maxDate = this.appointmentService.requiredDateFormat(new Date());
    console.log(this.selectedBed);
    this.getDischargeTemplatesList();
  }

  get p(): any {
    return this.diagnosisForm.controls;
  }
  get f(): any {
    return this.followupForm.controls;
  }

  getDepartmentTypes()
  {
    this.nursestationsService.getDepartmentTypes()
      .subscribe(
        (response) =>
        {
          this.departments = response
        },
        (err) =>
        {
          console.log("Error:", err)
        }
        )
  }
  getDischargeTypes()
  {
    this.nursestationsService.getDischargeType()
      .subscribe(
        (response) =>
        {
          this.dischargeTypesList = response
        },
        (err) =>
        {
          console.log("Error:", err)
        }
        )
  }

  getDischargeSummary(): void {
    if (this.selectedBed) {
      const inputRequest = {
        ipNr: this.selectedBed.IpNr
      };
      this.nursestationsService.getDischargeSummaryInfo(inputRequest).subscribe(
        (response: any) => {
          this.isDischargeSummaryExist = true;
          this.existingDischargeSummaryInfo = response;
          this.selectedBed.admissionDate = response.IP_ADMISSION_DATE;
          this.selectedBed.dischargeDate = response.IP_DISCHARGE_DATE;
          this.selectedBed.headingTitle = response.HEADING_TITLE;
          this.selectedBed.dischargeType = response.DISCHARGE_TYPE;
          this.selectedBed.refDoctorName = response.DOCTOR_NAME;
          this.selectedBed.ConsultDoctor = response.CONSULTANT_DOC_NAME;
          this.selectedBed.address = response.ADDRESS;
          this.dischargeSummaryForm.patchValue({
            editorContent: response.DISCHARGE_SUMMARY_INFO,
            departmentType: response.HEADING_TITLE,
            dischargeType: response.DISCHARGE_TYPE,
            dischargeDate: this.commonService.dateFormate(response.DISCHARGE_DATETIME, 'yyyy-MM-DD')
          });
          // console.log("response response", this.dischargeSummaryForm);
        },
        (err) => {
          console.log('HTTP Error', err.error);
          // this.getDefaultTemplate();
          this.nursestationsService.getDefaultTemplate()
            .subscribe(
              (response: any) => {
                this.dischargeSummaryForm.patchValue({
                  editorContent: response.TEMPLATE_DESCRIPTION
                })
              },
              (err) => {
                //
              }
            )

          this.commonService.patentDemographicDetails(WARD_API_URLS.PATIENT_DEMOGRAPHIC_DETAILS, inputRequest)
            .subscribe(
              (response) =>
              {
                // console.log(response)
                this.selectedBed.admissionDate = response.IP_ADMISSION_DATE
                this.selectedBed.admissionTime = response.IP_ADMISSION_TIME
                this.selectedBed.payeeName = response.PIM_NAME

              },
              (err) => 
              {
                //
              }
            )
          this.dischargeSummaryForm.patchValue({
            editorContent: this.defaultDescription,
            departmentType: '',
            dischargeType: '',
          });
        }
      );
    }

    if (!this.selectedBed) {
      this.router.navigate(['/nursestation']);
    }
  }

  getDefaultTemplate()
  {
    this.nursestationsService.getDefaultTemplate()
      .subscribe(
        (response: any) => {
          this.defaultDescription = response.TEMPLATE_DESCRIPTION
        },
        (err) => {
          //
        }
      )
  }

  getDischargeTemplatesList()
  {
    this.nursestationsService.getDischargeTemplatesList()
      .subscribe(
        (response) =>
        {
          this.templatesList = response
        },
        (err) =>
        {
          console.log("Error:", err)
        }
        )
  }
  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  submitDischargeSummaryDetails(form): void {
    if (form.status === 'INVALID') {
      this.showErrorMessage = true;
    } else {
      const inputRequest = {
        ipNr: this.selectedBed?.IpNr,
        dischargeSummaryInfo: form.value.editorContent,
        headingTitle: form.value.departmentType,
        dischargeType: form.value.dischargeType,
        dischargeDate: form.value.dischargeDate,
        lastSavedBy: this.userInfo.username,
        createdUserId: this.userInfo.username,
      };
      if (this.isDischargeSummaryExist) {
        this.updateDischargeSumary(form);
      } else {
        this.insertDischargeSumary(inputRequest);
      }
    }
  }

  insertDischargeSumary(inputRequest): void {
    this.nursestationsService.insertDischargeSummary(inputRequest).subscribe(
      () => {
        this.toastr.success('Discharge summary details submited successfully..!');
        // this.router.navigate(['/nursestation']);
        this.getDischargeSummary();
      },
      (err) => {
        this.toastr.error('Something went wrong!');
        console.log('HTTP Error', err);
      }
    );
  }
  updateDischargeSumary(form): void {
    // console.log(form);
    if (this.existingDischargeSummaryInfo.DISCHARGE_SUMMARY_FINALIZED !== 'Y') {
      const inputRequest = {
        ipNr: this.selectedBed?.IpNr,
        dischargeSummaryInfo: form.value.editorContent,
        headingTitle: form.value.departmentType,
        dischargeType: form.value.dischargeType,
        dischargeDate: form.value.dischargeDate,
        lastSavedBy: this.userInfo.username,
        dischargeSummaryId: this.existingDischargeSummaryInfo.ID,
      };
      this.nursestationsService.updateDischargeSummary(inputRequest).subscribe(
        () => {
          this.toastr.success('Discharge summary details updated successfully..!');
          // this.router.navigate(['/nursestation']);
          this.getDischargeSummary();
        },
        (err) => {
          this.toastr.error('Something went wrong!');
          console.log('HTTP Error', err);
        }
      );
    }
  }

  finalizePopupOpen(finalizePopup): void {
    this.modalService.open(finalizePopup);
  }

  dischargeSummaryfinalizeConfirmation(): void {
    const inputReq = {
      ipNr: this.selectedBed.IpNr,
      dischargeSummaryId: this.existingDischargeSummaryInfo.ID,
      dischargeSummaryFinalize: 'Y',
    };
    // console.log(inputReq);

    this.nursestationsService
      .dischargeSummaryfinalizeConfirmation(inputReq)
      .subscribe(
        () => {
          this.toastr.success('Discharge summary details updated successfully..!');
          this.modalService.dismissAll();
          this.router.navigate(['/nursestation']);
        },
        (err) => {
          this.toastr.error('Something went wrong!');
          console.log('HTTP Error', err);
        }
      );
  }

  printDischargeSummary(dischargeSummaryPrintPopup): void {
    this.modalService.open(dischargeSummaryPrintPopup, { size: 'lg' });
  }

  navigate(path: string): void {
    this.router.navigate(['/' + path]);
  }

  actionIcdPop(icdPopup, patientData)
  {
    const patientInfo = {
      PATIENT_NAME: patientData.PatientName,
      P_ID: patientData.UMRNo,
      IP_NR: patientData.IpNr,
      PIM_NAME: patientData.payeeName,
      GENDER: patientData.GenderAge,
      AGE:'',
      treatingDoctors: '',
      IP_ADMISSION_DATE: patientData.admissionDate,
      IP_ADMISSION_TIME: patientData.admissionTime,
      CONSULT_DOCTOR: patientData.ConsultDoctor,
      WM_WARD_NAME: patientData.WardName,
      WM_BED_NO: patientData.BedName
    } 
    this.icdPatientDetails = patientInfo
    this.modalService.open(icdPopup, { size: 'xl' })
  }

  popupClose()
  {
    this.modalService.dismissAll()
  }

  updateTemplate(data)
  {
    const value = this.templatesList.filter(type => type.TEMPLATE_NAME === data.target.value);
    this.dischargeSummaryForm.patchValue({
      editorContent: value[0].TEMPLATE_DESCRIPTION
    })

  }

  onSubmitDiagnosis(){
    const input = {
      Ipno: this.selectedBed.IpNr,
      PD: "Y",
      FD: "N",
      prd: this.diagnosisForm.controls.provisionalDiagnosis.value,
      createName: this.userInfo.username,
      followupDate:""
    }
    this.nursestationsService.addProvisionalDaignosis(input).subscribe((response)=>{
    
      this.toastr.success(response.message);
      this.getProvisionalDiagnosis();
      this.diagnosisForm.reset();
    });
  }
  onSubmitFallowupDate(){
    const input = {
      Ipno: this.selectedBed.IpNr,
      PD: "N",
      FD: "Y",
      prd: '',
      createName: this.userInfo.username,
      followupDate: this.followupForm.controls.fdate.value
    }
    this.nursestationsService.addProvisionalDaignosis(input).subscribe((response)=>{
      this.toastr.success(response.message);
      this.followupForm.reset();
    });
  }

  getProvisionalDiagnosis() {
    const request = {
      Ipno: this.selectedBed.IpNr,
    }
    this.nursestationsService.getProvisionalDaignosis(request).subscribe((response)=>{
      this.diagnosis = response.PD;
    });
  }

}
