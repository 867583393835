<div class="row">
  <div class="col-md-5 my-customsr bgbox ml-1 pb-5 p-0 scroll-1 pt-4">
    <div class="row mt-1 px-2">
      <div class="col-sm-6 col-md-6">
            <div class="form-group search_align">
              <div class="input-group input-group-sm mb-0 inputRadius">
                <input placeholder="Search by Name" type="text" id="searchName" name="patientNameSearch"
                  class="form-control" [(ngModel)]="nameValue" #pname pattern="[A-Za-z]+"
                  [class.is-invalid-error]="pname.value.length > 0 && pname.value.length <= 2"
                  (keydown)="checkingName($event)" (keyup)="keyUpEnterFunction($event, 'name')">
                  <!-- <span class="input-group-text text-size">{{textCount}}</span> -->
                  
                <div class="input-group-append">                
                  <span class="handCursor input-group-text" (click)="searchByNumber(pname, 'name')">
                    <i class="fas fa-search text-danger"></i></span>
                </div>
              </div>
              <small class="searchLabel my-1">Search with minimum 3 characters </small>
            </div>
      </div>
      <div class="col-sm-6 col-md-6">
            <div class="form-group search_align">
              <div class="input-group input-group-sm mb-0 inputRadius">
                <input placeholder="Search by Mobile Number" type="number" id="searchMobile" name="patientNameSearch"
                  class="form-control" [(ngModel)]="mobileValue" #mobileNumber 
                  [class.is-invalid-error]="mobileNumber.value.length > 0 && mobileNumber.value.length < 10"
                  (keydown)="checkingMobileNumber($event)" (keyup)="keyUpEnterFunction($event, 'mobile')">
                  <!-- <span class="input-group-text text-size">{{textCount}}</span> -->
                  
                <div class="input-group-append">                
                  <span class="handCursor input-group-text" (click)="searchByNumber(mobileNumber, 'mobile')">
                    <i class="fas fa-search text-danger"></i></span>
                </div>
              </div>
              <small class="searchLabel my-1"
                [class.is-invalid-error]="mobileNumber.value.length > 0 && mobileNumber.value.length < 10">Search with
                10-digit Mobile Number</small>
            </div>
      </div>
      <div class="offset-6 col-md-6 mb-3">
        <h6 class="cont btn btn-sm float-md-right my-sm-2 my-1 my-md-0 btn-light" (click)="setValuestoEmpty()">New Patient</h6>
      </div>
    </div>
    <div class="row mt-2 mb-3 px-2">
          <div class="col-md-12">        
              <form class="row " [formGroup]="rangeFormGroup">  
                <div class="row col-md-12 mb-2">
                  <div class="col-md-6">                  
                    <label class="col-md-5" style="font-size: 14px;"><b>FromDate:</b></label>
                    <input class="col-md-7 form-control-sm border border-primary" type="date" id="fromDate" formControlName="start"
                    [ngClass]="{ 'is-invalid': d.start.errors }" placeholder="dd-mm-yyyy">
                    <!-- <div *ngIf="d.start.errors" class="invalid-feedback">
                      <div *ngIf="d.start.errors.required">From Date is required</div>
                    </div> -->
                  </div>
                  <div class="col-md-6">                
                    <label class="col-md-5" style="font-size: 14px;"><b>ToDate:</b></label>
                    <input class="col-md-7 form-control-sm border border-primary" type="date" id="toDate" formControlName="end"
                    [ngClass]="{ 'is-invalid': d.end.errors }" placeholder="dd-mm-yyyy">
                    <!-- <div *ngIf="d.end.errors" class="invalid-feedback">
                      <div *ngIf="d.end.errors.required">End Date is required</div>
                    </div> -->
                  </div>
                </div>              
                  <div class="col-md-12">
                    <h6 class="cont btn btn-sm text-center my-sm-2 my-1 my-md-0 btn-light" (click)="getDateWiseList(rangeFormGroup);">Search</h6>
                  </div>                           
              </form>
            </div>          
          <!-- <div id="demo"></div> -->           
          <!-- <div class="json">  
              <h2>Integrated Form JSON</h2>  
              <code><pre>{{rangeFormGroup.value | json}}</pre></code>  
            </div> -->  
    </div>
    <div class="responsive ml-1" style="overflow-x: auto">
          <table class="table table-hover table-striped">

            <!--Table head-->
            <thead>
              <tr>
                <th class=" fweight600 " style="min-width: 50px">Patient Name </th>
                <th class=" fweight600 wid120">Mobile No</th>
                <th class=" fweight600  wid120">Vaccine Type</th>
                <th class="fweight600 wid120">Dose Type</th>
                <th class="fweight600 wid120">Action</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let patient of patientsData, let i = index" class="handCursor">
                <td class="text-capitalize">{{patient.patientName}} </td>
                <td>{{patient.mobileNo}}</td>
                <td>{{patient.vaccineTypeName}}</td>
                <td>{{patient.doseType}}</td>
                <td>
                  <div>
                    <!-- <a href="javascript:void(0)"
                      (click)="gettingPatientData(patient.adharNo, i)"
                      class="mr-1 font-small-icon">
                      <i class="fa fa-pencil-alt"></i>
                    </a> -->
                    <a href="javascript:void(0)" 
                      (click)="getPatientInfoModal(patientInfoModal, patient)"
                      title="View Visit" class="font-small-icon">
                      <i class="fa fa-eye"></i>
                    </a>                    
                  </div>                  
                </td>                
              </tr>
            </tbody>
          </table>
          <div *ngIf="patientsData.length <=0 " class="text-info text-center font-weight-bold">
            Waiting for Patient Information
          </div>
    </div>
  </div>
  <ng-container>
    <div class="col-md-7 my-custom-scrollbar float-right scroll-1">    
      <fieldset class="col-12 mt-1 legend-block mt-3" >
        <legend class="legend-title blue-title mb-0" *ngIf="!editButton">Vaccination Registration & Billing Form</legend>
        <legend class="legend-title mb-0" *ngIf="editButton">Patient Information</legend>
          <div class="text-center mt-1 clearfix">
            <i class="fas fa-edit close handCursor" (click)="editOption = false" *ngIf="editButton" style="color: #173267"
              title="click here to edit form"></i>
          </div>
          <form [formGroup]="vaccineForm" (ngSubmit)="submitData(vaccineForm)">
            <div style="font-size: 16px;">
              <div class="row mt-3 mb-3">
                <label for="pname" class="col-md-5 font-weight-bold font">Vaccine Type
                <span class="requiredSymbol">*</span></label>
                <div class="col-md-7">
                  <select class="form-control-sm form-control border border-primary" name="vaccineType"
                    formControlName="vaccineType" [attr.disabled]="(editOption) ? '' : null"
                    [ngClass]="{ 'is-invalid': submitted && f.vaccineType.errors }" id="vaccineType"
                    (change)="getVaccinePrice($event)">                    
                    <option value="">Select Vaccine Type</option>
                    <!-- <option value="Covishield">COVISHIELD</option> -->
                    <option *ngFor="let vaccine of selectedVaccineInfo; let i = index" [ngValue]="vaccine.vaccineTypeId" >
                      {{vaccine.vaccineTypeName}}
                    </option>
                  </select>
                  <div *ngIf="submitted && f.vaccineType.errors" class="invalid-feedback">
                    <div *ngIf="f.vaccineType.errors.required">Vaccine Type is required</div>
                  </div>
                </div>                                
              </div>
                  
              <div class="row offset-5 text-left font-weight-bold mt-3 mb-3 font">
                  <div class="col-md-6">
                      <p>Vaccine Price: &nbsp;<b>{{vaccineAmount}}</b></p>
                  </div>
                  <!-- <div class="col-md-6">
                      <p>Hospital Price: &nbsp;<b>{{hospitalAmount}}</b></p>
                  </div> -->
              </div>       

              <div class="row mt-3 mb-3"> 
                  <label class="col-md-5 font-weight-bold font">Dose Type: </label>
                  <div class="col-md-7">
                    <label class="radio-inline" for="radio16">
                    <input type="radio"
                            class="mx-2 font" 
                            id="radio16" 
                            name="doseType"                            
                            formControlName="doseType"
                            [ngClass]="{ 'is-invalid': submitted && f.doseType.errors }"                        
                            value="DOSE-1"><span class="font">Dose I</span>
                    </label>
                
                    <label class="radio-inline" for="radio17">
                    <input type="radio" 
                          class="mx-2 font" 
                          id="radio17" 
                          name="doseType"
                          formControlName="doseType"
                          [ngClass]="{ 'is-invalid': submitted && f.doseType.errors }"           
                          value="DOSE-2"><span class="font">Dose II</span>
                    </label>

                    <label class="radio-inline" for="radio18">
                      <input type="radio" 
                            class="mx-2 font" 
                            id="radio18" 
                            name="doseType"
                            formControlName="doseType"
                            [ngClass]="{ 'is-invalid': submitted && f.doseType.errors }"           
                            value="PRECAUTION_DOSE-1"><span class="font">Precaution Dose-I</span>
                      </label>
                  </div>
              </div>

              <div class="row mt-3 mb-3">
                  <label for="pname" class="col-md-5 font-weight-bold font">Patient Name
                      <span class="requiredSymbol">*</span></label>
                  <div class="col-md-7">
                    <input type="text" class="form-control-sm form-control border border-primary" 
                    name="pname" [readOnly]="editOption"
                    formControlName="ptname"
                    [ngClass]="{ 'is-invalid': submitted && f.ptname.errors }"
                      id="pname" required>
                    
                    <div *ngIf="submitted && f.ptname.errors" class="invalid-feedback">
                      <div *ngIf="f.ptname.errors.required">Patient Name is required</div>
                    </div>
                  </div>
              </div>
              <div class="row mt-3 mb-3">
                <label class="col-md-5 font-weight-bold font">Gender
                    <span class="requiredSymbol">*</span></label>
                <div class="col-md-7">
                    <label class="radio-inline" for="radio122">
                      <input type="radio" class="mx-1" id="radio122" value="MALE" name="gender"
                        formControlName="gender" required="required"
                        ><span class="font">Male</span>
                    </label>
                    <label class="radio-inline" for="radio222">
                      <input type="radio" class="mx-1" id="radio222" value="FEMALE" name="gender"
                        formControlName="gender" required="required"
                        ><span class="font">Female</span>
                    </label>
                    
                    <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                      <div *ngIf="f.gender.errors.required">Gender is required</div>              
                    </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                  <label class="col-md-5 font-weight-bold font">Aadhaar No
                      <!-- <span class="requiredSymbol">*</span> --></label>
                  <div class="col-md-7">
                      <input type="text" class="form-control-sm form-control border border-primary" 
                      name="aadhaarNo" [readOnly]="editOption"
                      id="aadhaarNo" 
                      formControlName="aadhaarNo"                      
                      pattern="^[0-9]{12}$"
                      maxlength="12">
                      <!-- [ngClass]="{ 'is-invalid': submitted && f.aadhaarNo.errors }" -->
                      <!-- <div *ngIf="submitted && f.aadhaarNo.errors" class="invalid-feedback">
                        <div *ngIf="f.aadhaarNo.errors.required">Aadhar Number is required</div>
                        <div *ngIf="f.aadhaarNo.errors.maxlength">Aadhar Number must be 12 characters</div>
                      </div> -->
                  </div>
              </div>
            
              <div class="row mt-3 mb-3">
                <label class="col-md-5 font-weight-bold font">D.O.B
                    <!-- <span class="requiredSymbol">*</span> -->
                  </label>
                <div class="col-md-7">
                  <input type="date" class="form-control-sm form-control border border-primary"
                  placeholder="dd-mm-yyyy" formControlName="dob" [max]="maxDate"
                  id="dob1">
                  <!-- [readOnly]="editOption" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
                    <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                      <div *ngIf="f.dob.errors.required">Date of Birth is required</div>
                  </div> -->
                </div>
              </div>

              <div class="row mt-3 mb-3">
                  <label class="col-md-5 font-weight-bold font">Mobile No
                    <span class="requiredSymbol">*</span></label>
                  <div class="col-md-7">
                    <input type="number" class="form-control-sm form-control  border border-primary"
                      name="mobileNo" formControlName="mobileNo" [readOnly]="editOption"
                      [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }"
                      id="mobileNo" maxlength="10"  type="text" pattern="\d*">
                      <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                        <div *ngIf="f.mobileNo.errors.required">Mobile Number is required</div>
                        <div *ngIf="f.mobileNo.errors.maxlength">Mobile Number must be 10 characters</div>
                      </div>
                  </div>
              </div>

              <div class="row mt-3 mb-3">
                  <label class="col-md-5 font-weight-bold font">COWIN-NO / ID
                    <!-- <span class="requiredSymbol">*</span> --></label>
                  <div class="col-md-7">
                    <input type="number" class="form-control-sm form-control  border border-primary"
                      name="cowinNo"  maxlength="8"  type="text" pattern="\d*" id="cowinNo"    
                      formControlName="cowinNo" [readOnly]="editOption">
                    <!-- [ngClass]="{ 'is-invalid': submitted && f.cowinNo.errors }" -->
                      <!-- <div *ngIf="submitted && f.cowinNo.errors" class="invalid-feedback">
                        <div *ngIf="f.cowinNo.errors.required">COWIN Number / ID is required.</div>
                        <div *ngIf="f.cowinNo.errors.maxlength">COWIN Number minimum 4 characters</div>
                      </div> -->
                    <!-- <span *ngIf="cowinNo.errors?.pattern" class="bg-warningtxt">Invalid Cowin number / ID</span> -->
                  </div>
              </div>

              <div class="row mt-3 mb-3">
                <label class="col-md-5 font-weight-bold font">Discount
                  <!-- <span class="requiredSymbol">*</span> --></label>
                <div class="col-md-7">
                  <input type="number" class="form-control-sm form-control  border border-primary"
                    name="discount" [readOnly]="editOption"     
                    formControlName="discount"              
                    id="discount">            
                    <!-- <div *ngIf="submitted && f.transactionId.errors" class="invalid-feedback">
                      <div *ngIf="f.transactionId.errors.required">Transaction ID is required.</div> 
                    </div> -->          
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <label class="col-md-5 font-weight-bold font">Mode of Payment
                  <span class="requiredSymbol">*</span></label>
                  <div class="col-md-7">
                    <label class="radio-inline" for="cash">
                    <input type="radio"
                            class="mx-2" 
                            id="paymentMode" 
                            name="paymentMode"
                            formControlName="paymentMode"                     
                            value="CASH"><span class="font">Cash</span>
                    </label>                   
            
                    <label class="radio-inline" for="card">
                    <input type="radio" 
                            class="mx-2" 
                            id="paymentMode" 
                            name="paymentMode"
                            formControlName="paymentMode"                      
                            value="CARD"><span class="font">Card</span>
                    </label>       
            
                    <label class="radio-inline" for="wallet">
                    <input type="radio" 
                            class="mx-2" 
                            id="paymentMode" 
                            name="paymentMode"
                          formControlName="paymentMode"                     
                            value="WALLET"><span class="font">Wallet</span>
                    </label>         
                  </div>
              </div>

              <div class="row mt-3 mb-3">
                <label class="col-md-5 font-weight-bold font">Transaction Details
                  <!-- <span class="requiredSymbol">*</span> --></label>
                <div class="col-md-7">
                  <input type="text" class="form-control-sm form-control  border border-primary"
                    name="transactionInfo" [readOnly]="editOption"    
                    formControlName="transactionId"              
                    id="transactionId">            
                    <!-- <div *ngIf="submitted && f.transactionId.errors" class="invalid-feedback">
                      <div *ngIf="f.transactionId.errors.required">Transaction ID is required.</div> 
                    </div> -->          
                </div>
              </div>                
            </div>

            <div class="text-right mt-5 mb-1 mr-5">
              <button (click)="setValuestoEmpty()" type="button"
                class="btn mx-sm-2 resetSub mb-2 p-2 text-white"
                style="background-color: #173267; width: 80px">Reset</button>
              <button type="submit" class="btn mx-sm-2 resetSub mb-2 p-2 text-white"
                style="background-color: #173267; width: 80px">Submit</button>
                <!-- <button type="submit" class="btn sm-2 resetSub mb-2  text-white"
                style="background-color: #173267; width: 80px">Print</button> -->
            </div>      
          </form>
      </fieldset>
    </div>  
  </ng-container>
</div>

<ng-template #patientInfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Vaccinated Patient Info</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row form-info">
      <div class="row col-md-12 ml-3 mr-3">        
          <div class="col-md-4">
                  <div class="row">
                      <div class="col-md-6"><label>Patient Name</label></div>
                      <div class="col-md-6">
                          <span>{{patientInfo?.patientName}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6"><label>Gender</label></div>
                      <div class="col-md-6">
                          <span>{{patientInfo?.gender}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6"><label>Mobile No</label></div>
                      <div class="col-md-6">
                          <span>{{patientInfo?.mobileNo}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6"><label>D.O.B</label></div>
                      <div class="col-md-6">
                          <span>{{patientInfo?.DOB}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-6"><label>Aadhaar No</label></div>
                      <div class="col-md-6">
                          <span>{{patientInfo?.adharNo}}</span>
                      </div>
                  </div>
          </div>
          <div class="col-md-1">            
          </div>
          <div class="col-md-5">
                  <div class="row">
                      <div class="col-md-7"><label>Vaccine Type</label></div>
                      <div class="col-md-5">
                          <span>{{patientInfo?.vaccineTypeName}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-7"><label>Dose Type</label></div>
                      <div class="col-md-5">
                          <span>{{patientInfo?.doseType}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-7"><label>Vaccination Date</label></div>
                      <div class="col-md-5">
                          <span>{{patientInfo?.vaccinationDate}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-7"><label>Cowin-No</label></div>
                      <div class="col-md-5">
                          <span>{{patientInfo?.cowinNo}}</span>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7"><label>patientVaccinationId</label></div>
                    <div class="col-md-5">
                        <span>{{patientInfo?.patientVaccinationId}}</span>
                    </div>
                </div>
          </div>
      </div>      
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
      <button type="submit" class="btn mx-sm-2 resetSub mb-2 p-2 text-white"
      style="background-color: #173267; width: 80px;" (click)="getPrint(patientInfo)">Print</button>
      </div> 
    </div>
  </div>  
</ng-template>
  
<ng-template #patientsListPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Vaccinated Patients List</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-bordered table-striped">
      <!--Table head-->
      <thead>
        <tr>
          <th class=" fweight600 wid120">S.No </th>
          <th class=" fweight600 wid120">Patient Name </th>
          <th class="fweight600 wid120">Mobile No</th>
          <th class="fweight600 wid120">Patient VaccineID</th>
          <th class=" fweight600  wid120">Vaccine Type</th>
          <th class="fweight600 wid120">Dose Type</th>
          <th class="fweight600 wid120">Vaccination Date</th>
          <th class="fweight600 wid120">Hospital Price</th>
          <th class="fweight600 wid120">Vaccine Price</th>
          <th class="fweight600 wid120">Discount</th>
          <th class="fweight600 wid120">Payment Mode</th>                  
        </tr>
      </thead>
  
      <tbody>
        <tr *ngFor="let patient of patientsList, let i = index" class="handCursor">
          <td>{{i+1}}</td>
          <td class="text-capitalize">{{patient.patientName}} </td>
          <td>{{patient.mobileNo}}</td>
          <td>{{patient.patientVaccinationId}}</td>
          <td>{{patient.vaccineTypeName}}</td>
          <td>{{patient.doseType}}</td>
          <td>{{patient.vaccinationDate | date:"dd-MM-yyyy"}}</td>
          <td>{{patient.hospitalAmount}}</td>
          <td>{{patient.vaccineAmount}}</td>          
          <td>{{patient.discountAmount}}</td>
          <td>{{patient.paymentMode}}</td>   
        </tr>
      </tbody>
    </table>
    <div class="row mt-5">
      <div class="row col-md-8 ml-5 mb-4">
        <table class="table1 table-bordered table-striped">
          <thead>            
            <th class="fweight600 wid120">Total Cash Amount (Rs)</th>
            <th class="fweight600 wid120">Total Card Amount (Rs)</th>
            <th class="fweight600 wid120">Total Wallet Amount (Rs)</th>
            <th class="fweight600 wid120">Total Bill Amount (Rs)</th>
            <th class="fweight600 wid120">Total Discount Amount (Rs)</th>
            <th class="fweight600 wid120">Total Collection Amount (Rs)</th>
          </thead>
          <tbody>
            <tr class="text-center p-2">              
              <td>{{amountInfo.cashTotal}}</td>
              <td>{{amountInfo.cardTotal}}</td>
              <td>{{amountInfo.walletTotal}}</td>
              <td>{{amountInfo.total}}</td>
              <td>{{amountInfo.discountTotal}}</td>
              <td>{{collection}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div class="col-md-12">
      <div class="row">
        <div class="row col-md-12 font-15">
          <label class="col-md-4 font-weight-bold font-15">Total Bills Amount :</label>
          <div class="col-md-3 font-weight-bold">{{amountInfo.total}}</div>
        </div>
      </div>
      <div class="row ">
        <div class="row col-md-12 font-15">
          <label class="col-md-4 font-weight-bold font-15">Total Cash Amount :</label>
          <div class="col-md-3 font-weight-bold">{{amountInfo.cashTotal}}</div>
        </div>
      </div>
      <div class="row">
        <div class="row col-md-12 font-15">
          <label class="col-md-4 font-weight-bold font-15">Total Card Amount :</label>
          <div class="col-md-3 font-weight-bold">{{amountInfo.cardTotal}}</div>
        </div>
      </div>
      <div class="row">
        <div class="row col-md-12 font-15">
          <label class="col-md-4 font-weight-bold font-15">Total Wallet Amount :</label>
          <div class="col-md-3 font-weight-bold">{{amountInfo.walletTotal}}</div>
        </div>
      </div>
      <div class="row">
        <div class="row col-md-12 font-15">
          <label class="col-md-4 font-weight-bold font-15">Total Discount Amount :</label>
          <div class="col-md-3 font-weight-bold">{{amountInfo.discountTotal}}</div>
        </div>
      </div>
      <div class="row">
        <div class="row col-md-12 font-15">
          <label class="col-md-4 font-weight-bold font-15">Total Collection :</label>
          <div class="col-md-3 font-weight-bold">{{collection}}</div>
        </div>
      </div>
    </div>
     <div class="row">
        <div class="row col-md-6">
          <label class="col-md-4 font-weight-bold">Total Card Amount :</label>
          <div class="col-md-3 font-weight-bold">{{amountInfo.cardTotal}}</div>
        </div>
    </div> -->
    <div class="text-center">
      <button class="btn btn-primary mx-2 mb-2 p-2 text-white" (click)="printPatientsList()" type="submit" value="Print">Print</button>
      <button class="btn btn-primary mx-2 mb-2 p-2 text-white" (click)="patientListPopupClose()" type="submit" value="Close">Close</button>      
    </div>
  </div>  
</ng-template>

<div id="print-section" style="display: none" class="print-form mt-5">

  <fieldset class="col-md-12 printbill-border mb-2">
    <legend class="printbill-border" style="font-size: 19px">Patient Details</legend>
    <div class="row col-md-12 d-flex p-3 form-data">
      
      <div class="col-md-12 d-flex p-1">
        <div class="col-md-6 d-flex p-0">
          <div class="col-4 p-0"><b>Patient Name</b></div>
          <div class="col-6 p-0"><b>: {{printDetails?.patientName}}</b></div>
        </div>
        <div class="col-md-6 d-flex p-0">
          <div class="col-5 p-0"><b>COWIN-ID</b></div>
          <div class="col-7 p-0 pl-2"><b>: {{printDetails?.cowinNo}}</b></div>
        </div>
      </div>

      <div class="col-md-12 d-flex p-1">
        <div class="col-md-6 d-flex p-0">
          <div class="col-4 p-0"><b>Gender</b></div>
          <div class="col-6 p-0"><b>: {{printDetails?.gender}}</b></div>
        </div>
        <div class="col-md-6 d-flex p-0">
          <div class="col-5 p-0"><b>Vaccine Name</b></div>
          <div class="col-7 p-0 pl-2"><b>: {{printDetails?.vaccineTypeName}}</b></div>
        </div>
      </div>

      <div class="col-md-12 d-flex p-1">
        <div class="col-md-6 d-flex p-0">
          <div class="col-4 p-0"><b> D.O.B</b></div>
          <div class="col-6 p-0"><b>: {{printDetails?.DOB | date:"dd-MM-yyyy"}}</b></div>
        </div>
        <div class="col-md-6 d-flex p-0">
          <div class="col-5 p-0"><b>Dose Type</b></div>
          <div class="col-7 p-0 pl-2"><b>: {{printDetails?.doseType}}</b></div>
        </div>
      </div>
      <div class="col-md-12 d-flex p-1">
        <div class="col-md-6 d-flex p-0">
          <div class="col-4 p-0"><b>Aadhaar No</b></div>
          <div class="col-6 p-0"><b>: {{printDetails?.adharNo}}</b></div>
        </div>
        <div class="col-md-6 d-flex p-0">
          <div class="col-5 p-0"><b>Vaccination Date</b></div>
          <div class="col-7 p-0 pl-2"><b>: {{printDetails?.vaccinationDate | date:"dd-MM-yyyy"}}</b></div>
        </div>
      </div>
      <div class="col-md-12 d-flex p-1">
        <div class="col-md-6 d-flex p-0">
          <div class="col-4 p-0"><b>Mobile No</b></div>
          <div class="col-6 p-0"><b>: {{printDetails?.mobileNo}}</b>  </div>
        </div>
        <div class="col-md-6 d-flex p-0">
          <div class="col-5 p-0"><b>PatientId</b></div>
          <div class="col-7 p-0 pl-2"><b>: {{printDetails?.patientVaccinationId}}</b></div>
        </div>
      </div>
    </div>
  </fieldset>

  <fieldset class="col-md-12 printbill-border d-flex mb-2">
    <legend class="printbill-border" style="font-size: 19px">Investigation Details</legend>
    <ng-container>      
      <div class="col-md-12 table1 font-weight-bold">
        <div class="row form-data">        
          <div class="col-md-1">
            SI No.
          </div>
          <div class="col-md-2">
            Test Code
          </div>
          <div class="col-md-5">
            Test Description
          </div>
          <div class="col-md-1">
            Charge
          </div>
          <div class="col-md-1">
            Quantity
          </div>
          <div class="col-md-2">
            Net Charge
          </div>
        </div>
        <div class="col-md-12">
          <hr class="border border-dark" />
        </div>      
      </div>                 
    </ng-container>      
    <ng-container>
      <div class="col-md-12 table1 font-weight-bold pb-1">
        <div class="row form-data">
          <div class="col-md-1">
            1.
          </div>
          <div class="col-md-2">
            MS060
          </div>
          <div class="col-md-5">
            COVISHIELD Vaccine charges-Single Dose
          </div>
          <div class="col-md-1">
            {{printDetails?.vaccineAmount}}
          </div>
          <div class="col-md-1">
            1
          </div>
          <div class="col-md-2">
            {{printDetails?.vaccineAmount}}
          </div>
        </div>
      </div>
      <div class="col-md-12 table1 font-weight-bold pb-1">
        <div class="row form-data">
          <div class="col-md-1">
            2.
          </div>
          <div class="col-md-2">
            MS061
          </div>
          <div class="col-md-5">
            Administration & Service Charges
          </div>
          <div class="col-md-1">
            {{printDetails?.hospitalAmount}}
          </div>
          <div class="col-md-1">
            1
          </div>
          <div class="col-md-2">
            {{printDetails?.hospitalAmount}}
          </div>
        </div>
      </div>
    </ng-container>    
  </fieldset>

  <fieldset class="col-md-12 printbill-border">
    <legend class="printbill-border" style="font-size: 19px">Payment Details</legend>
    <div class="row d-flex p-1 form-data">
      <div class="offset-6 col-md-6">
        <div class="row">
          <div class="col-md-6 text-right"><b>Total Amount</b></div>
          <div class="col-md-4 text-right pr-5"><span><b>{{printDetails?.ttlAmount}}</b></span></div>
        </div>
        <div class="row" *ngIf="damnt == true">
          <div class="col-md-6 text-right"><b>Discount Amount</b></div>
          <div class="col-md-4 text-right pr-5"><span><b>{{printDetails?.discountAmount}}</b></span></div>
        </div>
        <div class="row">
          <div class="col-md-6 text-right"><b>Paid Amount</b></div>
          <div class="col-md-4 text-right pr-5"><span><b>{{total}}</b></span></div>
        </div>        
      </div>
      <div class="col-md-12">
        <div class="col-md-12 text-left"><b>Mode of payments:</b> <span><b>By &nbsp;&nbsp; {{printDetails?.paymentMode}}</b></span></div>
        <div class="col-md-12 text-left"><b>Print User: &nbsp;&nbsp;<span> {{printDetails?.createdBy}}</span></b></div>
        <div class="col-md-12 text-left"><b>Billed User: &nbsp;&nbsp;<span> {{printDetails?.createdBy}}</span></b></div>
        <div class="col-md-12 text-left"><b>Print Date: &nbsp;&nbsp;<span> {{printDetails?.printDate}}</span></b></div>
      </div>
      <div class="col-md-12 text-right pt-4 pr-5"><b> Authorized Signature</b></div>
    </div>
  </fieldset>
</div>

<div id="patients-list" style="display: none;" class="print-form mt-5">
  <div class="row m-5">
    <div class="row col-md-12">
      <h4 class="text-center font-weight-bold">Vaccinated Patients List</h4>
      <hr class="m-0 hr" />  
      <table class="table table-bordered table-striped">
        <!--Table head-->
        <thead>
          <tr>
            <th class=" fweight600 wid120">S.No</th>
            <th class=" fweight600 wid120">Patient Name </th>
            <th class="fweight600 wid120">Mobile No</th>
            <th class="fweight600 wid120">Patient VaccineID</th>
            <th class=" fweight600  wid120">Vaccine Type</th>
            <th class="fweight600 wid120">Dose Type</th>
            <th class="fweight600 wid120">Vaccination Date</th>
            <th class="fweight600 wid120">Hospital Price</th>
            <th class="fweight600 wid120">Vaccine Price</th>
            <th class="fweight600 wid120">Discount</th>
            <th class="fweight600 wid120">Payment Mode</th>            
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let patient of patientsList, let i = index" class="handCursor">
            <td>{{i+1}}</td>
            <td class="text-capitalize">{{patient.patientName}} </td>
            <td>{{patient.mobileNo}}</td>
            <td>{{patient.patientVaccinationId}}</td>
            <td>{{patient.vaccineTypeName}}</td>
            <td>{{patient.doseType}}</td>
            <td>{{patient.vaccinationDate | date:"dd-MM-yyyy"}}</td>
            <td>{{patient.hospitalAmount}}</td>
            <td>{{patient.vaccineAmount}}</td>            
            <td>{{patient.discountAmount}}</td>
            <td>{{patient.paymentMode}}</td>   
          </tr>
        </tbody>
      </table>
      <ng-container>
        <div class="col-md-10 mt-3 pt-3 table1 font-weight-bold">
          <div class="row">
              <div class="col-md-2">
                <b>Total Cash Amount (Rs)</b>
              </div>
              <div class="col-md-2">
                <b>Total Card Amount (Rs)</b>
              </div>
              <div class="col-md-2">
                <b>Total Wallet Amount (Rs)</b>
              </div>
              <div class="col-md-2">
                <b>Total Bill Amount (Rs)</b>
              </div>
              <div class="col-md-2">
                <b>Total Discount Amount (Rs)</b>
              </div>
              <div class="col-md-2">
                <b>Total Collection Amount (Rs)</b>
              </div>
          </div>    
        </div>
        
        <div class="col-md-10 table1 font-weight-bold">
          <div class="row">            
            <div class="col-md-2">
              <b>{{amountInfo.cashTotal}}</b>
            </div>
            <div class="col-md-2">
              <b>{{amountInfo.cardTotal}}</b>
            </div>
            <div class="col-md-2">
              <b>{{amountInfo.walletTotal}}</b>
            </div>
            <div class="col-md-2">
              <b>{{amountInfo.total}}</b>
            </div>
            <div class="col-md-2">
              <b>{{amountInfo.discountTotal}}</b>
            </div>
            <div class="col-md-2">
              <b>{{collection}}</b>
            </div>
          </div>     
        </div>
      </ng-container>      
    </div>
  </div>
</div>