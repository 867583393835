import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { BillingService } from '../billing.service';
import { BillingId, 
          DoctorServicesList, 
          GetDoctorsList, 
          PatientDetails,
          PatientRecordsByVisitID, 
          PayeeName, 
          PaymentModes, 
          LoadServices, 
          ServiceInfo, 
          BillPayment, 
          printBill, 
          cancelBilling, 
          prescriptionService,visitStatus,patientVisitStatus } from '../billingconstants';
import { CommonService } from '../../shared/services/common.service';
@Component({
  selector: 'app-viewoutpatientbill',
  templateUrl: './viewoutpatientbill.component.html',
  styleUrls: ['./viewoutpatientbill.component.css'],
  providers: [DatePipe]
})
export class ViewoutpatientbillComponent implements OnInit {

  @Input() registerVisitId: any;

  @ViewChild('myModal11') myModal11;

  public show = false;
  public buttonName = 'Select';
  // public buttoncheck = 'Check out';

  public outPatientList: any = [];
  public patientInfo: any = {};
  public doctorsList: any = [];
  public doctorServicesList: any = [];
  public selectedServices: any = [];
  public grandTotal: any;
  public discountAmount: any;
  public discountAmountPercenttage: any;
  public netAmount: any;
  public serviceNetAmount: any;
  public collectedAmount: any = 0;
  public transactionId: any;
  public bankName: any;
  public mobileNumber: any;
  public returnAmount: any;
  public selectedPaymentType: any = '';
  public paymentTypes: any = [];
  public selectedDoctorName: any = '';
  public selecteddepartmentID: any = 0;
  public selectedDoctorId: any = 0;
  public showPayment: any = false;
  public searchVisitId: any;
  public searchUMR: any;
  public searchPatientName: any;
  public searchDate: any;
  public billingId: any;
  public userInfo: any = {};
  public paymentModeList: any = [];
  public payeeName: any = '';
  public searchServiceName: any = '';
  public allServices: any = [];
  public selectedAllServices: any = [];
  public searchServiceNameVal = '';
  public searchServiceType = '';
  public printDetailsData: any = {};
  public prescriptionData: any = {};
  public printPrescription = false;
  public billingPaymentSubmitDetails = [];
  public billingCancelDetails = [];
  public billingCancelList = [];
  public billingFinalBillDetailModel = {};
  public billingDoctorInformation = {};
  public hospitalModel = {};
  public patientModel = {};
  public paymentIndex = 0;
  public disableCancel: any = false;
  public visitExpireMessage = 'The Visit for this patient is checked out. Please add a new visit';
  public visitPatientStatus:any = true;
  public bodyDisplay = 'viewoutPatientBill';
  public visit = 1;
  public currentDate:any = new Date();
  public totalBillAmount =0;
  public BillPayType = "";
  public cardNmbr: any;

  walkin: boolean = false;

  public FinalBillId = "";
  public cancelBillPrint = [];
  printedDoctor: boolean = false;
  due: boolean;

  constructor(
            private billingService: BillingService, 
            private tokenService: TokenStorageService,
            private toastrService: ToastrService, 
            private printerService: NgxPrinterService,
            private confirmDialogService: ConfirmDialogService,
            private modalService: NgbModal,
            private datePipe: DatePipe,
            private commonService: CommonService
            ) {

    this.userInfo = this.tokenService.getUser();
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    // this.userInfo.hospitalID = 2;
  }

  ngOnInit(): void {
    this.getOutPatientList();
    this.getPaymentModeList();
  }

  ngOnChanges() {
    if (this.registerVisitId !== undefined) {
      this.getPatientInfo(null, this.registerVisitId);
    }
  }

  getOutPatientList(): any {
    this.outPatientList = [];
  }

  getPaymentModeList(): any {
    this.billingService.getPaymentModes(PaymentModes + this.userInfo.hospitalID)
      .subscribe(response => {
        this.paymentModeList = response;
      });
  }
 enterKeytriggerFunction(event, id, type): any {
   if (event.keyCode === 38 || event.keyCode === 40) {
     return false;
   }
   if (type === 'visitId') {
      this.searchUMR = null;
      this.searchPatientName = null;
      this.searchDate = null;
    }
    else if (type === 'patientId') {
      this.searchVisitId = null;
      this.searchPatientName = null;
      this.searchDate = null;
    } else if (type === 'patientName') {
      this.searchVisitId = null;
      this.searchUMR = null;
      this.searchDate = null;

    } else if (type === 'visitDate') {
      this.searchVisitId = null;
      this.searchUMR = null;
      this.searchPatientName = null;
    }
   if (event.keyCode === 13) {
      this.searchOutpatients(id, type);
    }
 }
  public searchOutpatients(searchId: any, type: any): any {
    this.outPatientList = [];
    const searchObj = {
      patientID: 0,
      visitID: 0,
      patientName: '',
      visitDate: '',
      visitStatus: this.visit
    };
    if (type === 'visitId') {
      searchObj.visitID = searchId;
    }
    if (type === 'patientId') {
      searchObj.patientID = searchId;
    }
    if (type === 'patientName') {
      searchObj.patientName = searchId;
    }
    if (type === 'visitDate') {
      searchObj.visitDate = searchId;
    }

    this.billingService.getOutpatientsByVisitId(PatientRecordsByVisitID , searchObj)
      .subscribe(response => {
        
        if(response.opInfo.length > 0){
        this.outPatientList = response.opInfo;
        }else{
          this.toastrService.error('No Records Found');
        }
      });
  }

  // public checkOutVisitStatus(visitID:any): void {
  //   const checkOutVisitId = {
  //     visitID: visitID
  //   };

  //   this.confirmDialogService.confirmThis(
  //     'Do you want to Check out ?',
  //     () => {
  //       // console.log('Yes');
  //       this.billingService.setPatientVisitSatus(visitStatus ,checkOutVisitId)
  //       .subscribe(response => {
  //         if(response.status){
  //           this.searchOutpatients(response.patientId, 'patientId');
  //           this.show = false;
  //         }
  //       });
  //     },
  //     () => {
  //       // console.log('No');
  //     }
  //   );
  // }

   /** pre-auth form popup */
   OnpreAuth(preAuthPopup): void {
    this.modalService.open(preAuthPopup, { size: 'xl' });
  }
  onClosePopup():void {
    this.modalService.dismissAll();
  }

  public getPatientInfo(patientInfo: any, regVisitId?: any): any {
    let visitID: any;
    let isRegVisit = false;
    if (regVisitId !== undefined && regVisitId !== null) {
      visitID = regVisitId;
      isRegVisit = true;
    } else {
      visitID = patientInfo.visitId;
    }
    
    this.billingService.getPatientDetails(PatientDetails +  visitID + '/' + this.visit)
    .subscribe(response => {
      this.patientInfo = response;
      this.doctorsList = [];
      if(this.patientInfo.length !== 0)
      {
        this.show = true; // !this.show;
        this.billingService.getDoctorsInfo(GetDoctorsList + visitID)
        .subscribe(serResponse => {
          this.doctorsList = serResponse;
          if (this.doctorsList.length !== 0 && isRegVisit) {
            this.getServiceInfo(this.doctorsList[0], isRegVisit);
          }
        });
        this.billingService.getBillingId(BillingId + visitID)
        .subscribe(serverResponse => {
          if (serverResponse !== null) {
            this.billingId = serverResponse.visitBillingID;
          }
        });
        this.billingService.getPayeeName(PayeeName + this.userInfo.hospitalID + '/' + patientInfo.payeeId)
        .subscribe(serverResponse => {
          if (response !== null) {
            this.payeeName = serverResponse;
          }
        });
      }
      else{
        this.show = false; // !this.show;
      }
    });
  }

  public getServiceInfo(doctorObj: any, isInisde?: any): any {
    this.searchServiceData();
    this.searchServiceNameVal = '';
    this.searchServiceType = '';
    this.selectedServices = [];
    this.selectedAllServices = [];
    this.grandTotal = 0;
    this.discountAmount = 0;
    this.discountAmountPercenttage = 0;
    this.netAmount = 0;
    this.selectedDoctorName = doctorObj.dmName;
    this.selectedDoctorId = doctorObj.doctorID;
    this.selecteddepartmentID = doctorObj.departmentID;
    this.showPayment = false;
    this.allServices = [];
    this.searchServiceName = '';
    if (isInisde) {
      setTimeout(() => {
        const ele = document.getElementById('proceed');
        if (ele !== null) {
          ele.click();
        }
      }, 100);
    }
    this.getDoctorServiceInfo(doctorObj);
      
  }


  public getDoctorServiceInfo(doctorobj: any): any {

    const serviceSearchObj = {
      dmId: doctorobj.doctorID,
      visitID: doctorobj.patientVisitID
    };

    this.doctorServicesList = [];
    this.billingService.getDoctorsServiceList(DoctorServicesList, serviceSearchObj)
      .subscribe(response => {
        this.doctorServicesList = response;
      });
  }

  public onSelectService(serviceObj: any): any {
    if (serviceObj.isSelected) {
      if (serviceObj.serviceUnitQty === 0) {
        serviceObj.serviceUnitQty = 1;
      }
      serviceObj.totalPrice = serviceObj.serviceUnitQty * serviceObj.servicePrice;
      serviceObj.serviceNetAmt = serviceObj.serviceUnitQty * serviceObj.servicePrice;
      this.selectedServices.push(serviceObj);
    } else {
      const hasObj = this.selectedServices.filter(obj => obj.serviceName === serviceObj.serviceName);
      if (hasObj.length !== 0) {
        const index = this.selectedServices.indexOf(hasObj[0]);
        this.selectedServices.splice(index, 1);
      }
      if (serviceObj.ismain) {
          const index = this.doctorServicesList.indexOf(serviceObj);
          this.doctorServicesList.splice(index, 1);
      }
    }
    this.calculateTotalAmount();
  }

  public calculateTotalAmount(): any {
    this.grandTotal = 0;
    this.discountAmount = 0;
    this.discountAmountPercenttage = 0;
    this.netAmount = 0;
    this.selectedServices.forEach(element => {
      element.totalPrice = element.serviceUnitQty * element.servicePrice;
      this.grandTotal = this.grandTotal + element.totalPrice;
      this.grandTotal =  Number(this.grandTotal.toFixed(2));
      if (element.discount !== undefined && element.discount !== '') {
        const disAmt = ((element.totalPrice / 100) * element.discount);
        this.discountAmount = Number(this.discountAmount + disAmt);
        element.serviceNetAmt = (element.serviceUnitQty * element.servicePrice) - disAmt;
      } else if (element.discountRuppes !== undefined && element.discountRuppes !== '') {
        this.discountAmount = Number(this.discountAmount + Number(element.discountRuppes));
        element.serviceNetAmt = (element.serviceUnitQty * element.servicePrice) - Number(element.discountRuppes);
      } else{
        element.serviceNetAmt = (element.serviceUnitQty * element.servicePrice);
      }
    });
    this.discountAmount = Number(this.discountAmount.toFixed(2));
    this.netAmount = this.grandTotal - this.discountAmount;
    this.netAmount = Number(this.netAmount.toFixed(2));
    this.discountAmountPercenttage = ((this.grandTotal - this.netAmount) / this.grandTotal) * 100;
    this.discountAmountPercenttage = Number(this.discountAmountPercenttage.toFixed(2));
  }

  public proceedPayment(): any {
    this.showPayment = true;
    this.clearAmounts();
    this.getPaymentModeList();
  }

  public clearAmounts(): any {
    this.selectedPaymentType = '';
    this.collectedAmount = 0;
    this.transactionId = '';
    this.bankName = '';
    this.mobileNumber = '';
    this.cardNmbr = '';
  }

  public cancelPayment(): any {
    this.showPayment = false;
  }

  public getServiceDiscountAmount(element: any) {
    if (element.discount !== undefined && element.discount !== '') {
      return ((element.servicePrice / 100) * element.discount);
    } else if (element.discountRuppes !== undefined && element.discountRuppes !== '') {
      return Number(element.discountRuppes);
    } else {
      return 0;
    }
  }

  public getServiceNetAmount(element: any) {
    if (element.discount !== undefined && element.discount !== '') {
      const disAmt = ((element.totalPrice / 100) * element.discount);
      return (element.serviceUnitQty * element.servicePrice) - disAmt;
    } else if (element.discountRuppes !== undefined && element.discountRuppes !== '') {
      return (element.serviceUnitQty * element.servicePrice) - Number(element.discountRuppes);
    } else{
      return (element.serviceUnitQty * element.servicePrice);
    }
  }


  public saveServicesBillingInfo(): any {
    const saveServiceList = [];
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    this.selectedServices.forEach(element => {
      const obj = {
        createdDateTime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
        createdId: this.userInfo.username,
        departmentId: this.selecteddepartmentID,
        doctorId: this.selectedDoctorId,
        sbiId: 0,
        serviceAmount: element.servicePrice,
        serviceBilledDate: dateFormat,
        serviceBilledTime: date.toTimeString().substr(0, 8),
        serviceDiscountAmt: this.getServiceDiscountAmount(element),
        serviceDiscountPerc: element.discount !== undefined ? element.discount : 0,
        serviceGrossAmt: element.servicePrice,
        serviceMasterId: element.serviceMasterID,
        serviceName: element.serviceName,
        serviceNetAmt: this.getServiceNetAmount(element),
        serviceQuantity: element.serviceUnitQty,
        vBillingId: this.billingId,
        visitId: this.patientInfo.visitId
      };

      saveServiceList.push(obj);
    });

    const saveServices = [];
    this.selectedServices.forEach(element => {
      const obj = {
        hospitalId: this.userInfo.hospitalID,
        serviceMasterId: element.serviceMasterID,
        serviceName: element.serviceName,
        servicePrescribedBy: this.selectedDoctorId,
        serviceQuantity: element.serviceUnitQty,
        serviceRequestDate: dateFormat,
        serviceRequestRemarks: '',
        serviceRequestTime: date.toTimeString().substr(0, 8),
        serviceTypeId: element.serviceTypeId !== undefined ? element.serviceTypeId : element.serviceTypeID,
        vBillingId: this.billingId,
        visitId: this.patientInfo.visitId
      };
      saveServices.push(obj);
    });

    const paymentObj = this.paymentModeList.filter(obj => obj.paymentModeName === this.selectedPaymentType);
    let cashamount = 0;
    let cardAmount = 0 ;
    let walletAmount = 0;
    this.paymentModeList.forEach(element => {
      if (element.cashAmount !== undefined && element.cashAmount !== '') {
        cashamount = cashamount + Number(element.cashAmount);
      }
      if (element.cardAmount !== undefined && element.cardAmount !== '') {
        cardAmount = cardAmount + Number(element.cardAmount);
      }
      if (element.walletAmount !== undefined && element.walletAmount !== '') {
        walletAmount = walletAmount + Number(element.walletAmount);
      }
    });
    const saveBillingJSON = {
      ServiceBillingInfo: saveServiceList,
      ServiceOrderRequestInfo: saveServices,
      amountCollected: this.collectedAmount,
      balanceAmount: this.collectedAmount - this.netAmount,
      bankingAmount: 0,
      bankingDate: dateFormat,
      bankingName: this.bankName,
      cardAmount,
      cardNumber: this.cardNmbr,
      cashAmount: cashamount,
      createdDateTime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
      createdId: this.userInfo.username,
      creditPayeeId: 0,
      grossAmount: this.grandTotal,
      hospitalId: this.userInfo.hospitalID,
      paymentDate: dateFormat,
      paymentID: 0,
      paymentModeType: '1',
      paymentReceiptNo: 0,
      paymentStatus: '',
      paymentTime: date.toTimeString().substr(0, 8),
      paymentType: this.selectedPaymentType,
      transactionId: this.transactionId !== undefined ? this.transactionId : 0,
      transactionMobileNr: this.mobileNumber,
      transactionReferenceId: 0,
      vBillingId: this.billingId,
      visitId: this.patientInfo.visitId,
      walletsAmount: walletAmount,
      finalBillAmount: this.grandTotal,
      finalBillDate: dateFormat,
      finalBillTime: date.toTimeString().substr(0, 8),
      finalDiscountAmount: this.discountAmount,
      finalDiscountPercentage: this.discountAmountPercenttage,
      finalID: 0,
      finalInvoiceNr: this.billingId,
      finalNetAmount: this.netAmount
    };

    this.billingService.saveServicesBillingInfo(BillPayment, saveBillingJSON)
      .subscribe(response => {
        this.toastrService.success('Payement done successfully!');
        document.getElementById('closePopup').click();
        this.printBillDetails(this.doctorsList);
      });

  }

  public saveServicesInfo(): any {
    const saveServices = [];
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    this.selectedServices.forEach(element => {
      const obj = {
        hospitalId: this.userInfo.hospitalID,
        serviceMasterId: element.serviceMasterID,
        serviceName: element.serviceName,
        servicePrescribedBy: this.selectedDoctorId,
        serviceQuantity: element.serviceUnitQty,
        serviceRequestDate: dateFormat,
        serviceRequestRemarks: '',
        serviceRequestTime: date.toTimeString().substr(0, 8),
        serviceTypeId: element.serviceMasterID,
        vBillingId: this.billingId,
        visitId: this.patientInfo.visitId
      };
      saveServices.push(obj);
    });
    // this.billingService.saveServicesInfo(SaveServicesInfo, saveServices)
    //   .subscribe(response => {
    //     this.saveOpPaymentDetails();
    //   });

  }

  public saveOpPaymentDetails(): any {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    const paymentObj = this.paymentModeList.filter(obj => obj.paymentModeName === this.selectedPaymentType);
    const saveJSON = {
      amountCollected: this.collectedAmount,
      balanceAmount: this.collectedAmount - this.netAmount,
      bankingAmount: 0,
      bankingDate: dateFormat,
      bankingName: this.bankName,
      cardAmount: 0,
      cashAmount: this.collectedAmount,
      createDatetime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
      createId: this.userInfo.username,
      creditPayeeId: 0,
      grossAmount: this.grandTotal,
      hospitalID: this.userInfo.hospitalID,
      paymentDate: dateFormat,
      paymentID: 0,
      paymentModeType: paymentObj[0].paymentModeID,
      paymentReceiptNo: 0,
      paymentStatus: '',
      paymentTime: date.toTimeString().substr(0, 8),
      paymentType: this.selectedPaymentType,
      transactionId: 0,
      transactionMobileNr: this.mobileNumber,
      transactionReferenceId: 0,
      visitBillingID: this.billingId,
      visitID: this.patientInfo.visitId,
      walletsAmount: 0
    };
    // this.billingService.saveOpPaymentDetails(SaveOpPaymentDetails, saveJSON)
    //   .subscribe(response => {
    //     this.saveOpBillDetails();
    //   });
  }

  public saveOpBillDetails(): any {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    const saveJson = {
      createDatetime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
      createId: this.userInfo.username,
      finalBillAmount: this.grandTotal,
      finalBillDate: dateFormat,
      finalBillTime: date.toTimeString().substr(0, 8),
      finalDiscountAmount: this.discountAmount,
      finalDiscountPercentage: 0,
      finalID: 0,
      finalInvoiceNr: this.billingId,
      finalNetAmount: this.netAmount,
      paymentType: this.selectedPaymentType,
      visitID: this.patientInfo.visitId
    };
    // this.billingService.saveOpBillDetails(SaveOpBillDetails, saveJson)
    //   .subscribe(response => {
    //     this.toastrService.success('Payement done successfully!');
    //     document.getElementById('closePopup').click();
    //   });
  }

  public searchServiceData(): any {
    // let serviceObj = this.allServices.filter(obj => obj.serviceName === this.searchServiceName);
        const searchJson: any = {
            gender: 'BOTH',
            hospitalId: this.userInfo.hospitalID,
            serviceName: ''
        };
        this.allServices = [];
        this.selectedAllServices = [];
        this.billingService.getAllServices(LoadServices, searchJson)
        .subscribe(response => {
          if (response !== null) {
            this.allServices = response;
            this.selectedAllServices = response;
          }
        });
  }

  public printBillDetails(docInfo: any): any {
    const searchJson: any = {
      hospitalID: this.userInfo.hospitalID,
      visitID: this.patientInfo.visitId,
      patientID: this.patientInfo.patientId,
    };
    this.printDetailsData = {};
    this.billingFinalBillDetailModel = {};
    this.billingPaymentSubmitDetails = [];
    this.billingCancelDetails = [];
    this.billingCancelList = [];
    this.cancelBillPrint = [];
    this.billingDoctorInformation = {
      "doctorName":docInfo.dmName,
      "externalReferal" : docInfo.referalDoctorName
    };
    /* if(docInfo.dmName == "N.Bhuvaneshwer Rao"){
      this.printedDoctor = true;
    } */
    this.billingService.getPrintDetails(printBill, searchJson)
    .subscribe(response => {
      if (response !== null) {
        this.printDetailsData = response;
        this.printDetailsData.patientModel.age  = this.gettingAge(this.printDetailsData.patientModel.dateOfBirth);
        if (this.printDetailsData !== undefined &&
          this.printDetailsData.billingPaymentSubmitModel  !== undefined &&
          this.printDetailsData.billingPaymentSubmitModel.length !== 0) {
            if (this.printDetailsData.billingPaymentSubmitModel[0].ServiceBillingInfoList) {
              this.billingPaymentSubmitDetails = this.printDetailsData.billingPaymentSubmitModel[0].ServiceBillingInfoList;

              this.billingFinalBillDetailModel = this.printDetailsData.billingPaymentSubmitModel[0].billingFinalBillDetailModel;

              this.FinalBillId = this.printDetailsData.billingPaymentSubmitModel[0].billingFinalBillDetailModel[0].finalID;

              this.billingCancelDetails = this.printDetailsData.billingPaymentSubmitModel[0].ServiceBillingCancelInfoList;

              if(this.billingCancelDetails[this.FinalBillId]) {
                this.billingCancelList = this.billingCancelDetails[this.FinalBillId];
              }
              this.billingPaymentSubmitDetails.forEach(element => {
                if(element.sbiStatus == "cancelled") {
                    this.cancelBillPrint.push(element);
                }
              });
              // this.billingPaymentSubmitDetails = this.printDetailsData.billingPaymentSubmitModel[0].ServiceBillingInfoList;
              // this.billingCancelDetails = this.printDetailsData.billingPaymentSubmitModel[0].ServiceBillingCancelInfoList;
              // this.billingFinalBillDetailModel = this.printDetailsData.billingPaymentSubmitModel[0].billingFinalBillDetailModel;
              // this.billingDoctorInformation = this.printDetailsData.billingPaymentSubmitModel[0].billingDoctorInformation;
            }
          }
          this.getTotalBillAmoutAndType();
          this.OnpreAuth(this.myModal11);
      }
    });
  }

  public printBillToPrinter(): any {
     const htmlele = document.getElementById('print-section');
     htmlele.style.display = 'block';
    //  let htmlele1 = document.getElementById('cancelHeader');
    //  htmlele1.style.display = 'none';
    //  let htmlele2 = document.getElementById('remarksHeader');
    //  htmlele2.style.display = 'none';
    //  let htmlele3 = document.getElementById('cancelRow');
    //  htmlele3.style.display = 'none';
    //  let htmlele4 = document.getElementById('remarksRow');
    //  htmlele4.style.display = 'none';
     this.printerService.printDiv('print-section');
     setTimeout(() => {
     const htmlele = document.getElementById('print-section');
     htmlele.style.display = 'none';
    //   let htmlele1 = document.getElementById('cancelHeader');
    //   htmlele1.style.removeProperty("display");
    //   let htmlele2 = document.getElementById('remarksHeader');
    //   htmlele2.style.removeProper//ty("display");
    //   let htmlele3 = document.getElementById('cancelRow');
    //   htmlele3.style.removeProperty("display");
    //   let htmlele4 = document.getElementById('remarksRow');
    //   htmlele4.style.removeProperty("display");
      }, 1000);

  }


  printComponent(cmpName) {
    const printContents = document.getElementById(cmpName).innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    // document.body.innerHTML = originalContents;
}

  public getGrossAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus != 'cancelled') {
              amount = amount + element.serviceGrossAmt;
            }
          });
    }
    return amount.toFixed(2);
  }

  public getDiscountAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus != 'cancelled') {
              amount = amount + element.serviceDiscountAmt;
            }
          });
    }
    return amount.toFixed(2);
  }

  public getNetAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus != 'cancelled') {
              amount = amount + element.serviceNetAmt;
            }
          });
    }
    return amount.toFixed(2);
  }

  public getCancelAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus == 'cancelled') {
              amount = amount + element.serviceNetAmt;
            }
          });
    }
    return amount.toFixed(2);
  }


  setServicesIndex(index: any) {
    this.paymentIndex = index;
    this.cancelBillPrint = [];
    if (index !== undefined) {
      this.FinalBillId = this.printDetailsData.billingPaymentSubmitModel[index].billingFinalBillDetailModel[0].finalID;
      this.billingPaymentSubmitDetails = this.printDetailsData.billingPaymentSubmitModel[index].ServiceBillingInfoList;
      this.billingFinalBillDetailModel = this.printDetailsData.billingPaymentSubmitModel[index].billingFinalBillDetailModel;
      this.billingDoctorInformation = this.printDetailsData.billingPaymentSubmitModel[index].billingDoctorInformation;

      if(this.billingCancelDetails[this.FinalBillId]) {
        this.billingCancelList = this.billingCancelDetails[this.FinalBillId];
      } else {
        this.billingCancelList = [];
      }

      this.printDetailsData.billingPaymentSubmitModel[index].ServiceBillingInfoList.forEach(element => {
        if(element.sbiStatus == "cancelled") {
            this.cancelBillPrint.push(element);
        }
      });
    }
    this.getTotalBillAmoutAndType();
  }

  getFinalBillDate(): any{
    let finalDate = this.getDate();
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingFinalBillDetailModel;
      if (model !== undefined) {
          finalDate = model[0].finalBillDate;
      }
    }
    return finalDate;
  }

  getCardAmount() {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.cardAmount > 0) ? model.cardAmount : 0;
        return amount;
      }
    }
    return amount;
  }

  getWalletAmount() {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.walletsAmount > 0) ? model.walletsAmount : 0;
        return amount;
      }
    }
    return amount;
  }

  getCashAmount() {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.cashAmount > 0) ? model.cashAmount : 0;
        return amount;
      }
    }
    return amount;
  }

  getDueAmount(): any {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined)
      //&&   this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel.paymentModeType === '3' 
      {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.cashAmount > 0) ? model.cashAmount : 0;
        return amount;
      }
    }
    return amount;
  }
  getTransactionNo() {
    let transaction  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        transaction = (model.transactionId > 0) ? model.transactionId : 0;
        return transaction;
      }
    }
    return transaction;
  }

  getTransactionReferenceNo() {
    let transactionRef  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        transactionRef = (model.transactionReferenceId > 0) ? model.transactionReferenceId : 0;
        return transactionRef;
      }
    }
    return transactionRef;
  }

  getMobileNo() {
    let mobileNr  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        mobileNr = (model.transactionMobileNr > 0) ? model.transactionMobileNr : 0;
        return mobileNr;
      }
    }
    return mobileNr;
  }

  getBankName() {
    let name  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        name = (model.bankingName) ? model.bankingName :"";
        return name;
      }
    }
    return name;
  }

  getCardNumber() {
    let cardNo  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        cardNo = (model.cardNumber) ? model.cardNumber :"";
        return cardNo;
      }
    }
    return cardNo;
  }

  getCreatedBy() {
    let name  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        name = model.createId;
        return name;
      }
    }
    return name;
  }

  getCreatedDate() {
    let date  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        date = model.createDatetime;
        return date;
      }
    }
    return date;
  }

  getDate() {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    return dateFormat + ' ' + date.toTimeString().substr(0, 8);
  }

  getDateToPrint(){
    const date = new Date();
    const dateFormat = this.changeDateFormat(date);
    return dateFormat + ' ' + date.toTimeString().substr(0, 8);
  }

  isDisabled() {
    let disable = false;
    if (this.billingPaymentSubmitDetails.length !== 0  && (this.getFinalBillDate() === this.currentDate)) {
      let hasSel = false;
      this.billingPaymentSubmitDetails.forEach(element => {
        if (element.isSelect && element.sbiStatus !== 'cancelled') {
          if (element.remarks === undefined || element.remarks === '' || element.remarks.length < 10) {
            disable = true;
          }
          hasSel = true;
        }
      });
      if (hasSel) {
        return disable;
      }
      return true;
    }
    return true;
  }


  public cancelPaymentService(): any {
    this.disableCancel = true;
    if (this.billingPaymentSubmitDetails !== undefined){
      const selectedServices = [];
      this.billingPaymentSubmitDetails.forEach(element => {
        if (element.isSelect) {
          selectedServices.push(element);
        }
      });
      selectedServices.forEach(element => {

      });

      if (selectedServices.length !== 0) {
        const date = new Date();
        const dateFormat = this.requiredDateFormat(date);
        const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
        const cancelJson = {
          BillingPaymentDetail: {
            amountCollected: 0,
            balanceAmount: 0,
            bankingAmount: 0,
            bankingDate: '',
            bankingName: '',
            cardAmount: 0,
            cashAmount: 0,
            createDatetime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
            createId: this.userInfo.username,
            creditPayeeId: 0,
            grossAmount: 0,
            hospitalID: this.userInfo.hospitalID,
            paymentDate: dateFormat + ' ' + date.toTimeString().substr(0, 8),
            paymentID: 0,
            paymentModeType: 'Cash',
            paymentReceiptNo: 0,
            paymentStatus: '',
            paymentTime: date.toTimeString().substr(0, 8),
            paymentType: '2',
            transactionId: this.billingFinalBillDetailModel[0].finalID,
            transactionMobileNr: 0,
            transactionReferenceId: 0,
            visitBillingID: model.visitBillingID,
            visitID: this.billingFinalBillDetailModel[0].visitID,
            walletsAmount: 0
          },
          ServicesCancellationDetail: []
        };

        let amount = 0;
        selectedServices.forEach(element => {
          amount = amount + element.serviceAmount;
          const obj = {
            finalID: this.billingFinalBillDetailModel[0].finalID,
            createDateTime: element.createdDateTime,
            createId: element.createdId,
            deptId: element.departmentId,
            dmId: element.doctorId,
            sbiId: element.sbiId,
            scdId: 0,
            scdRemarks: element.remarks,
            serviceAmount: element.serviceAmount,
            serviceCancelDate: dateFormat,
            serviceCancelTime: date.toTimeString().substr(0, 8),
            serviceDiscountAmount: element.serviceDiscountAmt,
            serviceDiscountedPercentage: element.serviceDiscountPerc,
            serviceGrossAmount: element.serviceGrossAmt,
            serviceName: element.serviceName,
            serviceNetAmount: element.serviceNetAmt,
            serviceUnitQty: element.serviceQuantity,
            smId: element.serviceMasterId,
            visitBillingId: element.vBillingId,
            visitId: element.visitId
          };
          cancelJson.ServicesCancellationDetail.push(obj);

        });
        cancelJson.BillingPaymentDetail.cashAmount = amount;
        this.billingService.getPrintDetails(cancelBilling, cancelJson)
        .subscribe(response => {
          if (response.errorMessage !== undefined && response.errorMessage !== '' &&
          response.errorMessage !== null) {
            this.toastrService.error(response.errorMessage);
            this.disableCancel = false;
          } else {
            this.toastrService.success('Cancel billing successfully!');
            selectedServices.forEach(element => {
              element.sbiStatus = 'cancelled';
            });
          }
        });

      }

    }
  }

  public servicenameSearch(): any {
    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    }else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => (obj.serviceName.toLowerCase().indexOf(this.searchServiceNameVal.toLowerCase()) !== -1));
    }else {
      this.selectedAllServices = this.allServices.filter(obj => (obj.serviceName.toLowerCase().indexOf(this.searchServiceNameVal.toLowerCase()) !== -1));
    }
  }

  public serviceTypeSearch(): any {
    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    } else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => (obj.serviceTypeName.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1));
    } else {
      this.selectedAllServices = this.allServices.filter(obj => (obj.serviceTypeName.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1));
    }
  }

  public selectService(): any {
    const serviceObj = this.allServices.filter(obj => obj.serviceName === this.searchServiceName);
    this.selectedAllServices.push(serviceObj[0]);
    this.searchServiceName = '';
    this.allServices = [];
  }

  public addService(service: any): any {
    const serviceJson = {
      hospitalId: this.userInfo.hospitalID,
      serviceMasterId : service.smId,
      visitId: this.patientInfo.visitId
    };
    const hasService = this.doctorServicesList.filter(obj => obj.serviceMasterID === service.smId);
    if (hasService.length === 0) {
      this.billingService.getServiceDetail(ServiceInfo, serviceJson)
        .subscribe(response => {
          if (response !== null) {
            const obj = response[0];
            if (obj !== undefined) {
              obj.ismain = true;
              obj.isSelected = true;
              response[0].serviceTypeId = service.serviceTypeId;
              this.doctorServicesList.push(response[0]);
              this.onSelectService(response[0]);
            }
          }
      });
    }
  }

  public isPaymentSelected(): any {
    const hasObj = this.paymentModeList.filter(obj => (obj.paymentModeName !== 'CASH' && obj.selectedPaymentType));
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }

  public clearAmount(paymentType: any) {
    paymentType.collectedAmount = '';
  }

  public checkCollectAmount(): any {
    let collectAmount = 0;
    let hastrans = false;
    this.paymentModeList.forEach(element => {
      if (element.paymentModeID == 1) {
        element.cashAmount = element.collectedAmount;
      } else if ((element.paymentModeID == 2 || element.paymentModeID == 3) && element.collectedAmount !== undefined) {
        element.cardAmount = element.collectedAmount;
        hastrans = true;
      }  else if ((element.paymentModeID == 4 || element.paymentModeID == 5 || element.paymentModeID == 6) && element.collectedAmount !== undefined) {
        element.walletAmount = element.collectedAmount;
        hastrans = true;
      }
      if (element.selectedPaymentType && element.collectedAmount !== undefined && element.collectedAmount !== '') {
        collectAmount = collectAmount + Number(element.collectedAmount);
      }
    });

    this.paymentModeList.forEach(element => {
      if (collectAmount == this.netAmount && !element.selectedPaymentType) {
        element.isDisable = true;
      } else {
        element.isDisable = false;
      }
    });

    if (hastrans && this.transactionId !== undefined && this.transactionId !== '' &&
      this.bankName !== undefined && this.bankName !== '' &&
      this.mobileNumber !== undefined && this.mobileNumber !== ''
      && this.mobileNumber.length == 10 && this.transactionId.length == 12) {
      this.collectedAmount = collectAmount;
      if (collectAmount == this.netAmount) {
        return false;
      }
    } else if (hastrans) {
      return true;
    }
    this.collectedAmount = collectAmount;
    if (collectAmount == this.netAmount) {
      return false;
    }
    return true;
  }

  public requiredDateFormat(dateRec: Date): string {
    const funYear = dateRec.getFullYear();
    const funMonth = dateRec.getMonth() + 1;
    const funDay = dateRec.getDate();
    return funYear + '-' + this.checkingTwoDigits(funMonth) + '-' + this.checkingTwoDigits(funDay);
  }

  public changeDateFormat(dateRec: Date): string {
    const funYear = dateRec.getFullYear();
    const funMonth = dateRec.getMonth() + 1;
    const funDay = dateRec.getDate();
    return this.checkingTwoDigits(funDay) + '-' + this.checkingTwoDigits(funMonth) + '-' + funYear ;
  }

  public checkingTwoDigits(info): string {
    const twoDig = info.toString().length < 2 ? '0' + info : '' + info;
    return twoDig;
  }

  numberOnly(event: any, data: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (data !== undefined) {
      const value = data + event.key;
      if (value > 100) {
        return false;
      }
    }
    return true;
  }

  numberOnlyOther(event: any, data: any, total: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (data !== undefined) {
      const value = data + event.key;
      if (value > total) {
        return false;
      }
    }
    return true;
  }

  numberOnlyVal(event: any, data: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public getreturnAmount(): any {
    const amount = this.collectedAmount - this.netAmount;
    return amount.toFixed(2);
  }

  gettingAge(dobValue: any): any {
      if (dobValue !== undefined && dobValue !== '') {
        const fullDate = new Date(dobValue);
        const todayDate = new Date();
        let patientDobYear = todayDate.getFullYear() - fullDate.getFullYear();
        let patientDobMonth = todayDate.getMonth() - fullDate.getMonth();
        let patientDobDate = todayDate.getDate() - fullDate.getDate();

        if (patientDobMonth <= 0) {
          patientDobYear--;
          patientDobMonth = (12 + patientDobMonth);
        }
        if (patientDobDate < 0) {
          patientDobMonth--;
          patientDobDate = 31 + patientDobDate;
          if(patientDobDate == 31) {
            patientDobMonth++;
            patientDobDate = 0;
          }
        }  
      if (patientDobMonth == 12) {
        patientDobYear = patientDobYear + 1;
        patientDobMonth = 0;
      }
        const patientAge = patientDobYear + 'Y ' + patientDobMonth + 'M ' + patientDobDate + 'D';
        return patientAge;
    }
      return 0;
  }

  /**
   * print for outpatient prescription
   */
 private prescriptionPrint(patientId, visitId, doctorId): any{
    const inputRequest: any = {
      hospitalID: this.userInfo.hospitalID,
      patientID: patientId,
      visitID: visitId,
      doctorID: doctorId
    };
    this.billingService.prescriptionDoctorsInfo(inputRequest, prescriptionService)
      .subscribe(response => {
        if (response !== null) {
          this.prescriptionData = response;
          this.prescriptionData.patientModel.age  = this.gettingAge(this.prescriptionData.patientModel.dateOfBirth);
          this.billingDoctorInformation = this.prescriptionData.billingDoctorInformation;
          if(this.prescriptionData.billingDoctorInformation.externalReferal == "Walk-In"){
            this.walkin = true;
          }
          if(this.prescriptionData.billingDoctorInformation.doctorName == "N.Bhuvaneshwer Rao"){
            this.printedDoctor = true;
          }
        }
      });

    this.printPrescription = true;
    setTimeout(() => {
      this.printerService.printDiv('print-prescription-section');
    }, 2000);
    setTimeout(() => {
      this.printPrescription = false;
    }, 3000);
  }

  getFollowupDate() {
    if(this.billingDoctorInformation['freeFollowUpDays'] > 0) {
      let fullDate = new Date(this.prescriptionData.visitDate);
      fullDate.setDate( fullDate.getDate() + ((this.billingDoctorInformation['freeFollowUpDays'])-1));
      const dateFormat = this.requiredDateFormat(fullDate);
      return dateFormat;
    }
    return '';
  }

  /* getFollowUpDateFiveDays() {
    if(this.billingDoctorInformation['freeFollowUpDays'] > 0) {
      let fullDate = new Date(this.prescriptionData.visitDate);
      fullDate.setDate( fullDate.getDate() + ((this.billingDoctorInformation['freeFollowUpDays'])-3));
      const dateFormat = this.requiredDateFormat(fullDate);
      return dateFormat;
    }
    return '';
  } */

  getTotalBillAmoutAndType():void{
    let amount = 0;
    this.totalBillAmount = 0;
    this.BillPayType = "";
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        if (model.cashAmount > 0)
        {
          this.totalBillAmount = Number(this.totalBillAmount) + Number(model.cashAmount);
          if(model.paymentModeType != 13){
            this.due = false;
            if ( this.BillPayType !== ''){
              this.BillPayType = ' AND CASH';
            } else {
              this.BillPayType = 'CASH';
            }
          } else {
             this.due = true;
            if ( this.BillPayType !== ''){
              this.BillPayType = ' AND DUE';
            } else {
              this.BillPayType = 'DUE';
            }
          }
          
        }

        if(model.walletsAmount > 0)
        {
          this.due = false;
          this.totalBillAmount = Number(this.totalBillAmount) + Number(model.walletsAmount);
          if( this.BillPayType != ""){
            this.BillPayType = this.BillPayType + " AND WALLET" ;
          } else {
            this.BillPayType = "WALLET";
          } 
        }

        if(model.cardAmount > 0)
        {
          this.due = false;
          this.totalBillAmount = Number(this.totalBillAmount) + Number(model.cardAmount);
          if( this.BillPayType != ""){
            this.BillPayType = this.BillPayType + " AND CARD"; 
          } else {
            this.BillPayType = "CARD";
          }
        }
      }
    }
  }
  getAmountInWords(){
    return this.commonService.toWords(Math.round(this.totalBillAmount));
  }

}
