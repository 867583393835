import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/appointments/appointment.service';
import { AppointmentDepartmentsList, ExternalReferralList } from 'src/app/appointments/appointmentconstants';
import { LabService } from 'src/app/lab-module/lab.service';
import { GetAllWards } from 'src/app/lab-module/labconstants';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  // @ViewChild('DoctorWisePatientsListPopup') 
  // private DoctorWisePatientsListPopup: ElementRef; 

  fileName1 = 'DoctorWiseCollectionsReport.xlsx';
  fileName2 = 'DoctorWisePatientDetails.xlsx';
  fileName3 = 'IPBillingDetails.xlsx';

  public bodyDisplay = 'reports';
  userInfo: any;
  doctorsList: any;
  consultsListForm: FormGroup;
  patientsListForm: FormGroup;
  revenuesListForm: FormGroup;
  control: AbstractControl;
  doctorWiseInfo: any;
  paymentModes = [
    { name: 'Cash', id: '1' },
    { name: 'Card', id: '2' },
    { name: 'Wallet', id: '3' },
  ];
  submitted = false;
  totalCashAmnt: any;
  totalCardAmnt: any;
  toatlWalletsAmnt: any;
  navigation: boolean;
  pathname: string;
  doctorWisePatientsInfo: any;
  doctorName: any;
  sponsersList: any = [];
  consultDoctorsList: any = [];
  searchType = 'IP';
  displayType = 'ResultEntry';
  wardListArr:any = [];
  patientInfo: any;
  departmentList: any = [];
  extReferalList: any = [];
  extRefList: any;
  payeeNamesInfo: any;
  payeeNamesList: any = [];
  payeeId: any;
  depmtId: any;

  constructor(
    private appointmentService: AppointmentService,
    private tokenService: TokenStorageService,
    private inpatientService: InpatientService,
    private printerService: NgxPrinterService,
    private toasterService: ToastrService,
    private labService: LabService,
    private route: ActivatedRoute,
    private router: Router, 
    private modalService: NgbModal) 
    { 
      this.userInfo = this.tokenService.getUser();

      this.route.url.subscribe((param) => {
        console.log("Parameters :",param[0].path);
        this.pathname = param[0].path;
      });
    }

  ngOnInit(): void {
    
    this.consultsListForm = new FormGroup({
      startDate: new FormControl('',[Validators.required]),
      endDate: new FormControl('',[Validators.required]),
      doctorName: new FormControl('',[Validators.required]),
      paymentType: new FormControl('',[Validators.required])
    });
    this.patientsListForm = new FormGroup({
      startDate: new FormControl('',[Validators.required]),
      endDate: new FormControl('',[Validators.required]),
      doctorName: new FormControl('',[Validators.required]),
      paymentType: new FormControl('',[Validators.required])
    });
    this.revenuesListForm = new FormGroup({
      DEPARTMENT: new FormControl('',[Validators.required]),
      CONSULTDOCTOR: new FormControl('',[Validators.required]),
      REFERRALDOCTOR: new FormControl('',[Validators.required]),
      SPONSER: new FormControl('',[Validators.required]),
      PAYEENAME: new FormControl('',[Validators.required]),
      WARDNAME: new FormControl('',[Validators.required]),
      startDate: new FormControl('',[Validators.required]),
      endDate: new FormControl('',[Validators.required])      
    });
    this.getDoctorsList();
    this.getConsultDoctorsList();
    this.getSponsersList();
    this.getAllWardsList();
    this.getDepartmentList();
    this.getExternalReferal();
    //this.getPayeeNamesList();
  }

  get f(): any { return this.consultsListForm.controls; }
  get g(): any { return this.patientsListForm.controls; }
  get h(): any { return this.revenuesListForm.controls; }

  getDoctorsList() {
    const input = {
      hid: this.userInfo.hospitalID,
      serviceTypeId: 1
    }
    this.inpatientService.getServiceDoctorsList(input).subscribe((response)=>{
      //console.log("Service Doctors list: ", response);
      this.doctorsList = response;
    });
  }

  backTo(){
    if(this.pathname == 'registration'){
      this.router.navigate(['/registration']);
    }else {
      this.router.navigate(['/billing']);
    }
  }
  
  getDepartmentList(): any {
    this.inpatientService.getDepartments()
      .subscribe( (departments) => {
        console.log("Departments: ", departments);
        this.departmentList = departments;
      });
  }

  getConsultDoctorsList(): void {      
    const inputRequest = {
      deptId: '',
    };
    this.inpatientService.getDepartmentWiseDoctors(inputRequest)
      .subscribe((response) => {     
        this.consultDoctorsList = response;
      });
  }

  getExternalReferal(): any {
    this.appointmentService.getExternalReferralList(ExternalReferralList)
      .subscribe( referals => {
        this.extRefList = referals;        
        this.extReferalList = [];
        this.extRefList.forEach( refList => {
          this.extReferalList.push(refList.doctorName);
        });
      });
  }

  getSponsersList(): void {
    const inputRequest = {
      HospitalID: this.userInfo.hospitalID,
    };
    this.inpatientService.sponsersList(inputRequest).subscribe((response) => {
      //this.loading = false;
      console.log("Sponsers: ",response);
      this.sponsersList = response;
    });
  }

  public getAllWardsList():void {
    const inputReq = {
      "searchType": this.searchType,
      "patientType" : this.displayType,
      "hospitalID": this.userInfo.hospitalID
    }
    this.labService.getAllWardsList(GetAllWards,inputReq)
    .subscribe(response => {
      console.log(response);
        this.wardListArr = response;
      });
  }

  billingReportsPopup(billingReportsPopupBlock) {
    this.modalService.open(billingReportsPopupBlock);
  }
  doctorReportsPopup(doctorReportsPopupBlock) {
    this.modalService.open(doctorReportsPopupBlock);
  }
  admittedReportsPopup(admittedReportsPopupBlock) {
    this.modalService.open(admittedReportsPopupBlock);
  }

  submitData(DoctorWisePatientsListPopup){    
    this.getDoctorWiseInfo(DoctorWisePatientsListPopup);
  }
  submitForm(PatientsListPopup){
    this.getDoctorWisePatientsInfo(PatientsListPopup);
  }
  submitRevenueForm(RevenueListPopup) {
    this.getAddmittedWisePopup(RevenueListPopup);
  }

  changeDoctor() {
    this.consultDoctorsList = [];
    this.depmtId = this.revenuesListForm.controls.DEPARTMENT.value;
    const inputRequest = {
      deptId: this.depmtId,
    };
    this.inpatientService.getDepartmentWiseDoctors(inputRequest)
      .subscribe((response) => {
        this.consultDoctorsList = response;
      });
  }

  changePayeeType() {
    this.payeeNamesInfo = [];
    this.payeeId = this.revenuesListForm.controls.SPONSER.value;
    const input = {
      hId: this.userInfo.hospitalID,
      ptmId: this.payeeId
    }
    this.inpatientService.getPayeenamesList(input).subscribe((response)=>{
      //console.log("payeeNamesInfo: ", response);
      this.payeeNamesInfo = response;      
    });
      console.log("payeeNamesInfo: ", this.payeeNamesInfo);
  }
  getDoctorWiseInfo(DoctorWisePatientsListPopup): any {  
    this.submitted = true;
    const request = {
      hid: this.userInfo.hospitalID,
      fromDate: this.consultsListForm.controls.startDate.value,
      toDate: this.consultsListForm.controls.endDate.value,
      paymentMode: this.consultsListForm.controls.paymentType.value,
      serviceId: this.consultsListForm.controls.doctorName.value
    };
    this.inpatientService.getDoctorWiseServiceSearch(request).subscribe((response)=>{
      
        if(response.data != 0){
        this.doctorWiseInfo = response.data;
        this.doctorName = this.doctorWiseInfo[0].serviceName;
        this.submitted = false;        
        this.openDoctorWiseListPopup(DoctorWisePatientsListPopup);
        } else {
          this.toasterService.error("No Records Found");
        }
    });
  }

  getDoctorWisePatientsInfo(PatientsListPopup){
    this.submitted = true;
    const request = {
      hid: this.userInfo.hospitalID,
      fromDate: this.patientsListForm.controls.startDate.value,
      toDate: this.patientsListForm.controls.endDate.value,
      paymentMode: '',
      serviceId: this.patientsListForm.controls.doctorName.value
    };
    this.inpatientService.getDoctorWiseServiceSearch(request).subscribe((response)=>{
      
        if(response.data != 0){
        this.doctorWisePatientsInfo = response.data;
        this.doctorName = this.doctorWisePatientsInfo[0].serviceName;
        this.submitted = false;        
        this.openPatientsListPopup(PatientsListPopup);
        } else {
          this.toasterService.error("No Records Found");
        }
    });
  }
  
  getAddmittedWisePopup(RevenueListPopup) {
   const input = {
    start: "",
    limit: "",
    ser_hospitalid: this.userInfo.hospitalID,
    sear_ptmid: this.revenuesListForm.controls.SPONSER.value,
    fromDate: this.revenuesListForm.controls.startDate.value,
    toDate: this.revenuesListForm.controls.endDate.value,
    sear_consult_doctor: this.revenuesListForm.controls.CONSULTDOCTOR.value,
    wardId: this.revenuesListForm.controls.WARDNAME.value,
    department: this.revenuesListForm.controls.DEPARTMENT.value,
    referralDoctor: this.revenuesListForm.controls.REFERRALDOCTOR.value,
    payeeName: this.revenuesListForm.controls.PAYEENAME.value 
  } 
  this.inpatientService.getAdmittedWardWiseInfo(input).subscribe((response)=>{
    if(response.status != "fail"){
      console.log("patientInfo: ", response);
      this.patientInfo = response;
    }else {
      this.toasterService.error(response.message);
    }
    
  });  
   this.openRevenueListPopup(RevenueListPopup);  
  }
  
  public openDoctorWiseListPopup(DoctorWisePatientsListPopup): any {
    this.modalService.open(DoctorWisePatientsListPopup, {size:'xl', backdrop:'static'});
  }
  public openPatientsListPopup(PatientsListPopup): any {
    this.modalService.open(PatientsListPopup, {size:'xl', backdrop:'static'});
  }
  public openRevenueListPopup(RevenueListPopup): any {
    this.modalService.open(RevenueListPopup, {size:'xl', backdrop:'static'});
  }
  
  printCollectionsList(){
    const htmlele = document.getElementById('collections-print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('collections-print-section');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  }


  printPatientsList(){
    const htmlele = document.getElementById('patients-print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('patients-print-section');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  }

  printRevenueList(){
    const sheet = document.createElement('style');
    sheet.innerHTML = '@page {size: landscape;}';
    document.getElementById('revenue-print-section').appendChild(sheet);
    
    const htmlele = document.getElementById('revenue-print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('revenue-print-section');
   
    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  }
  patientListPopupClose() {
    this.modalService.dismissAll();
  } 
  popUpClose() {
    this.modalService.dismissAll();
    this.setValuestoEmpty();
  } 
  setValuestoEmpty() {
    this.consultsListForm.reset();
    this.patientsListForm.reset();
    this.revenuesListForm.reset();
    this.getConsultDoctorsList();
  }
  
  dataExport(): void 
    {
       /* table id is passed over here */   
       const element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName1);			
    }

    dataToExport(): void 
    {
       const element = document.getElementById('excel-data'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       XLSX.writeFile(wb, this.fileName2);			
    }

    exportToExcel(): void 
    {
       const element = document.getElementById('revenue-data'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       XLSX.writeFile(wb, this.fileName3);			
    }

}
