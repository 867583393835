
<div id="print-prescription-section" class="print-form" *ngIf="finalBillPatientInfo !== undefined">
    <div *ngIf="userInfo.hospitalShortName != 'DPMTA' && userInfo.hospitalShortName != 'KPMTA' 
    && userInfo.hospitalShortName != 'CPMTA' && userInfo.hospitalShortName != 'JPMTA'">
        <div class="row no-gutters heading-block">
            <div class="col-md-2 logo">
                <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
            </div>
            <div class="col-md-9">
                <div class="text-center">
                    <h4 class="main-title mb-0">{{ finalBillPatientInfo?.HospitalName }}</h4>
                    <span style="font-size: 12px; font-weight: normal; display: inline-block; ">{{ finalBillPatientInfo?.HospitalTagLine }}</span>
                    <p>{{ finalBillPatientInfo?.HospitalAddress }}</p>
                    <h3 class="mb-1 font-weight-bold">
                    <u>{{ finalBillPatientInfo?.InpatientFinalBIllLabel }}</u>
                    </h3>
                </div>
            </div>
        </div>
        <!-- Patient information -->
        <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
        <div class="col-md-6">
        
            <div class="row" *ngIf="finalBillPatientInfo?.BillNo !== ''">
                <div class="col-md-3">
                    <b>Bill No</b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.BillNo}}
                </div>
            </div>
        
            <div class="row" *ngIf="finalBillPatientInfo?.BillDate !== ''">
                <div class="col-md-3">
                    <b>Bill Date </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.BillDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Patient Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.PatientName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Admission Date
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.DateOfAdmission}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Consultant
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.Consultant}}
                </div>
            </div>
            <div class="row" *ngIf="finalBillPatientInfo?.Referral !== ''">
                    <div class="col-md-3">
                        Referral
                    </div>
                    <div class="col-md-9">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}({{finalBillPatientInfo?.ReferralCity}})
                        </div>                
                    </div>
                </div>
            <div class="row">
                <div class="col-md-3">
                    Department
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.Department}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Address
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.Address}}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-3">
                    <b>Admission No </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.AdmissionNo}}
                </div>
            </div>
            <div class="row" *ngIf="finalBillPatientInfo?.DischargeDate !== ''">
                <div class="col-md-3">
                    <b>Discharge Date </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.DischargeDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    S-W-D-B/O
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.SWDBO}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    UMR No
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.UMRNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Age / Gender
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.AgeSex}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Admitted Ward
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo?.AdmittedWard}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Payee Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo.PayeeName}}
                </div>
            </div>
        </div>
        </div>
        <!-- ./END Patient information -->
        <div class="mt-2 text-center border border-dark p-2" *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)" style="font-size: 17px;">
        <b>Hospitalisation Charges from
            <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesFrom}}</span>
            <span class="pl-2">To</span>
            <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesTo}}</span>
        </b>
        </div>
        <ng-container *ngIf="finalBillServicesList.length !==0 && finalBillPatientInfo?.SummaryBillBtn">
        <div class="row table1 font-weight-bold"  style="font-size: 17px;">
        <div class="col-12">
            <hr class="my-2 border border-dark" />
        </div>
        <div class="col-md-2">
            Service TypeId
        </div>
        <div class="col-md-4">
            Service Type
        </div>
        <div class="col-md-3">
            Total
        </div>
        <div class="col-md-2">
            <span *ngIf="billServiceDiscountStatus && false">Discount</span>
        </div>
        <div class="col-md-1">
            Net Amount
        </div>
        </div>
        <div class="row table1"  style="font-size: 16px;">
         <div class="col-12">
            <hr class="my-2 border border-dark" />
          </div>
         <ng-container *ngFor="let service of finalBillServicesList;">
            <div class="col-md-2">
                {{service?.Service_typeid}}
            </div>
            <div class="col-md-4">
                {{service?.Service_typename}}
            </div>
            <div class="col-md-3">
                {{service?.totalAmount}}
            </div>
            <div class="col-md-2">
                <span *ngIf="billServiceDiscountStatus && false">{{service?.Discount}}</span>
        
            </div>
            <div class="col-md-1 text-right">
                <!-- {{service?.netAmount}} -->
                {{service?.totalAmount}}
            </div>
         </ng-container>
        </div>
        </ng-container> 
        <ng-container  *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && finalBillServicesList.length !== 0">
        
            <!-- Daywise Break button table header -->
            <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                <div class="row table1 font-weight-bold">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-2">
                        Service Date
                    </div>
                    <div class="col-md-3">
                        Services/Investigation
                    </div>
                    <div class="col-md-1">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-1 text-right">
                        <span *ngIf="false">Discount</span>
                    </div>
                    <div class="col-md-1">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
        
            <!-- Full Break button table header -->
            <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                <div class="row table1 font-weight-bold" style="font-size: 16px;">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-3">
                        Services/Investigation
                    </div>
                    <div class="col-md-2">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-2 text-center">
                        <span *ngIf="false">Discount</span>
                    </div>
                    <div class="col-md-1 text-right">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
            <div *ngFor="let service of finalBillServicesList;">
                <!-- <div class="row pdf-blue-text font-weight-bold">
                    <div class="col-md-6">
                        {{service?.ServiceType}}
                    </div>
                    <div class="col pdf-blue-text text-right">
                        <span>{{service?.ServiceTotalAmount}}</span>
                    </div>
                </div> -->
                <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                    <div class="row table2" style="font-size: 16px;">            
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-3 text-right" style="padding-right: 40px;">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>
                </div>
                <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                    <div class="row table2">
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>   
                </div>
        
                <ng-container *ngIf="!(finalBillPatientInfo?.SummaryBillBtn)">
                    <!-- Daywise Break button table data -->
                    <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">   
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span *ngIf="false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                    </div>
                    <!-- Full Break button table data -->
                    <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                            <div class="col-md-2">
                                {{subService?.ServiceCode}}
                            </div>
                            <div class="col-md-3">
                                {{subService?.Services}}
                            </div>
                            <div class="col-md-1">
                                {{subService?.Qty}}
                            </div>
                            <div class="col-md-1 text-right">
                                *
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                            </div>
                            <div class="col-md-2 text-center">
                                <span *ngIf="false">{{subService?.Discount}}</span>
                            </div>
                            <div class="col-md-1 text-right">
                                <span class="tablecol">{{ subService?.Amount}}</span>
                            </div>
                        </div>
                    </div>      
                </ng-container>
                
                <ng-container *ngIf="finalBillPatientInfo?.SummaryBillBtn">
                    <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2" *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1">
                            <span *ngIf="billServiceDiscountStatus && false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        
        <br />
        <hr class="my-2 border border-dark" />
        
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount}}</b>
            </div>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Discount > 0">
            <div class="offset-6 col-3 text-right">
                <b>Discount Amount</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Discount}}</b>
            </div>
            <div class="offset-6 col-3 text-right" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn) ">
                <div *ngIf="billingSummaryDetails?.discountApprovedBy != ''">
                    <b>Discount Approved By</b><br>
                    <b>({{billingSummaryDetails?.discountApprovedBy}})</b>
                </div>
            </div>
        </div>
        
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.NetAmount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{(billingSummaryDetails?.NetAmountLabel) ? billingSummaryDetails?.NetAmountLabel : 'NetAmount'}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.NetAmount}}</b>
            </div>
        </div>

        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Receipt_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Receipt_Amount_Label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Receipt_Amount}}</b>
            </div>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Balance_Amount != '0.00'">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Balance_label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{ (billingSummaryDetails?.Balance_Amount < 0) ? (billingSummaryDetails?.Balance_Amount * -1) : billingSummaryDetails?.Balance_Amount }}</b>
            </div>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.AmountRefundedAmount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{ billingSummaryDetails?.AmountRefundedLabel }}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{ billingSummaryDetails?.AmountRefundedAmount }}</b>
            </div>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Refund_payble_Balance_amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{ billingSummaryDetails?.Refund_payble_Amount_label }}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{ billingSummaryDetails?.Refund_payble_Balance_amount }}</b>
            </div>
        </div>
        <br />
        
        <div *ngIf="finalBillReceiptsList.length !== 0">
        <div>
            <b style="font-size: 17px;">Receipt / Payment Details</b>
        </div>
        <hr class="my-2 border border-dark" />
        <div class="row table1" style="font-size: 17px;">
            <div class="col-md-1"><b>Recpt. No</b></div>
            <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
            <div class="col-md-2"><b>Cash Amt</b></div>
            <div class="col-md-2"><b>Cheque Amt</b></div>
            <div class="col-md-1"><b>Card/ <br/>Wallet Amt</b></div>
            <div class="col-md-2 text-right"><b>Recpt. Amt.</b></div>
            <div class="col-md-1"><b>Recpt.<br/> Type</b></div>
            <div class="col-md-1 p-0"><b>Remarks</b></div>
        </div>
        <hr class="my-2 border border-dark" />
        <div class="row" style="font-size: 16px;" *ngFor="let receptInfo of finalBillReceiptsList">
            <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
            <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
            <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
            <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
            <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
            <div class="col-md-2 text-right">{{receptInfo?.RecptAmt}}</div>
            <div class="col-md-1">{{receptInfo?.ReceiptType}}</div>
            <div class="col-md-1 p-0">
                <span *ngIf="receptInfo?.Remarks !== ''">
                    {{receptInfo?.Remarks}}:</span>
            </div>
        </div>
        <hr class="my-2 border border-dark" />
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Total_Receipt_Amount > 0">
            <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
            <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
        </div>
        </div>
        
        <hr class="my-2 border border-dark" />
        
        <div>
        <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
        </div>
        <div>
        <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}}
        </b>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn)">
            <div class="col-6 text-left">
                <div class="row mt-5 mb-3">
                    <div class="col-4"><b>Admission Created By</b></div>
                    <div class="col">{{finalBillPatientInfo?.admissionCreatedBy}}</div>
                </div>
            </div>
            <div class="col-6 text-right">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div>
        </div>
        
        <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
        <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
        </div>
        <div class="row" style="font-size: 16px;">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared By</b>
                </div>
                <div class="col">
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared Dt</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PreparedDt}}
                </div>
            </div>
        </div>
        </div>
        <div class="row" style="font-size: 16px;">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed By</b>
                </div>
                <div class="col">
                    {{userInfo?.username}}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed On</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PrintedOn}}
                </div>
            </div>
        </div>
        </div>
        
        <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
        <div class="mt-5">
            <div class="text-center">
                <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-8"></div>
            <div class="col-4">
                <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
            </div>
        
        </div>
        
        <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
            and I agree
            and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
            i
            acknowledge the bill.</p>
        
        <div class="text-center mt-5">
            <div class="row justify-content-center">
                <div class="col-7">
                    <div class="row my-4">
                        <div class="col-3">Signature</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Name</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Ph No</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Date</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>

    <div class="margin" *ngIf="userInfo.hospitalShortName == 'DPMTA'">
        <div class="row no-gutters">
                <div class="col-md-12">
                    <div class="text-center">
                        <h3 class="mb-1 font-weight-bold">
                        <u>{{ finalBillPatientInfo?.InpatientFinalBIllLabel }}</u>
                        </h3>
                    </div>
                </div>
        </div>
            <!-- Patient information -->
        <div class="row mt-2" style="font-weight: bolder; font-size: 17px;">
            <div class="col-md-6">
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillNo !== ''">
                    <div class="col-md-3">
                        <b>Bill No</b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillNo}}
                    </div>
                </div>
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillDate !== ''">
                    <div class="col-md-3">
                        <b>Bill Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillDate | date: "dd-MM-yyyy"}} 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Patient Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admission Date
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DateOfAdmission}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Consultant
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Consultant}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.Referral !== ''">
                    <div class="col-md-3">
                        Referral
                    </div>
                    <div class="col-md-9">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}({{finalBillPatientInfo?.ReferralCity}})
                        </div>                
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Department
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Department}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Patient Address
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Address}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">
                        <b>IP No </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmissionNo}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.DischargeDate !== ''">
                    <div class="col-md-3">
                        <b>Discharge Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DischargeDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        S-W-D-B/O
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.SWDBO}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.UMRNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                Gender
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AgeSex}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Mobile No
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.MobileNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admitted Ward
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmittedWard}}
                    </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                    Payee Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo.PayeeName}}
                </div>
            </div>
            </div>
        </div>
        <!-- ./END Patient information -->
        <div class="mt-2 text-center border border-dark p-2" *ngIf="finalBillPatientInfo?.SummaryBillBtn" style="font-size: 18px;">
            <b>Hospitalisation Charges from
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesFrom}}</span>
                <span class="pl-2">To</span>
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesTo}}</span>
            </b>
        </div>
        <ng-container *ngIf="finalBillServicesList.length !==0 && finalBillPatientInfo?.SummaryBillBtn && btnStatus == 'N'">
        <div class="row table1 font-weight-bold" style="font-size: 16px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <div class="col-md-2">
                Service TypeId
            </div>
            <div class="col-md-4">
                Service Type
            </div>
            <div class="col-md-3">
                Total
            </div>
            <div class="col-md-2">
                  <span *ngIf="billServiceDiscountStatus && false">Discount</span>
            </div>
            <div class="col-md-1">
                Net Amount
            </div>
        </div>
        <div class="row table1" style="font-size: 16px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <ng-container *ngFor="let service of finalBillServicesList;">
                <div class="col-md-2">
                    {{service?.Service_typeid}}
                </div>
                <div class="col-md-4">
                    {{service?.Service_typename}}
                </div>
                <div class="col-md-3">
                    {{service?.totalAmount}}
                </div>
                <div class="col-md-2">
                      <span *ngIf="billServiceDiscountStatus && false">{{service?.Discount}}</span>        
                </div>
                <div class="col-md-1 text-right">
                      {{service?.totalAmount}}
                </div>
            </ng-container>
        </div>        
        </ng-container> 
        <ng-container  *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && finalBillServicesList.length !== 0">
        
            <!-- Daywise Break button table header -->
            <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                <div class="row table1 font-weight-bold">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-2">
                        Service Date
                    </div>
                    <div class="col-md-3">
                        Services/Investigation
                    </div>
                    <div class="col-md-1">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-1 text-right">
                              <span *ngIf="false">Discount</span>
                    </div>
                    <div class="col-md-1">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
        
            <!-- Full Break button table header -->
            <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn) && btnStatus == 'N'">
                <div class="row table1 font-weight-bold" style="font-size: 17px;">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Category
                    </div>
                    <div class="col-md-4">
                        Services/Investigation
                    </div>
                    <div class="col-md-2">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-2 text-right">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div>
            <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn) && btnStatus == 'Y'">
                <div class="row font-weight-bold" style="font-size: 16px;">
                    <div class="col-12 text-center">
                        <hr class="my-2 border border-dark" />
                        <h4 class="font-weight-bold"><U>WELL BABY CHARGES - RS 5000/- </U></h4>
                    </div>
                </div>
                <div class="row font-weight-bold" style="font-size: 18px;">
                    <div class="col-md-4">
                        S.No
                    </div>
                    <div class="col-md-5">
                        Service Name
                    </div>
                    <div class="col-md-3 text-right">
                        Amount
                    </div>
                </div>
                <hr class="my-2 border border-dark" />
                <div class="row font-weight-bold p-2" style="font-size: 17px;">
                    <div class="col-md-4">1</div>
                    <div class="col-md-5">Baby registration</div>
                    <div class="col-md-3 text-right">500.00</div>
                </div>
                <div class="row font-weight-bold p-2" style="font-size: 17px;">
                    <div class="col-md-4">2</div>
                    <div class="col-md-5">Baby resuscitation at birth</div>
                    <div class="col-md-3 text-right">1350.00</div>
                </div>
                <div class="row font-weight-bold p-2" style="font-size: 17px;">
                    <div class="col-md-4">3</div>
                    <div class="col-md-5">Neonatal consultations</div>
                    <div class="col-md-3 text-right">2100.00</div>
                </div>
                <div class="row font-weight-bold p-2" style="font-size: 17px;">
                    <div class="col-md-4">4</div>
                    <div class="col-md-5">Blood Investigations
                        <ul type="dot">
                            <li>Blood grouping</li>
                            <li>TSH</li>
                        </ul>
                    </div>
                    <div class="col-md-3 text-right">550.00</div>
                </div>
                <div class="row font-weight-bold" style="font-size: 17px;">
                    <div class="col-md-4">5</div>
                    <div class="col-md-5">Immunisation
                        <ul type="dot">
                            <li>BCG</li>
                            <li>OPV</li>
                            <li>HEPATITIS-B</li>
                        </ul>
                    </div>
                    <div class="col-md-3 text-right">500.00</div>
                </div>
            </div>
            <div *ngFor="let service of finalBillServicesList;">
                
                <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn) && btnStatus == 'N'">
                    <div class="row table2" style="font-size: 17px;">            
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right" style="padding-right: 20px;">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>
                </div>
                <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                    <div class="row table2">
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>   
                </div>
        
                <ng-container *ngIf="!(finalBillPatientInfo?.SummaryBillBtn)">
                    <!-- Daywise Break button table data -->
                    <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">   
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                                  <span *ngIf="false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                    </div>
                    <!-- Full Break button table data -->
                    <div class="mb-3" *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn) && btnStatus == 'N'">
                        <div class="row table2" *ngFor="let subService of service?.SubCategory" style="font-size: 17px;">
                            <div class="col-md-2">
                                
                            </div>
                            <div class="col-md-4">
                                {{subService?.Services}}
                            </div>
                            <div class="col-md-1">
                                {{subService?.Qty}}
                            </div>
                            <div class="col-md-1 text-right">
                                *
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol">{{ subService?.Amount}}</span>
                            </div>
                        </div>
                    </div>      
                </ng-container>
                
                <ng-container *ngIf="finalBillPatientInfo?.SummaryBillBtn">
                    <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2" *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1">
                                  <span *ngIf="billServiceDiscountStatus && false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        
        <!-- <br /> -->
        <hr class="my-2 border border-dark" />
        
        <div class="row" style="font-size: 17px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
            </div>
            <div class="col-3 text-right" *ngIf="btnStatus == 'N'">
                <b>{{billingSummaryDetails?.Gross_Amount}}</b>
            </div>
            <div class="col-3 text-right" *ngIf="btnStatus == 'Y'">
                <b>5000.00</b>
            </div>
        </div>
        <div class="row" style="font-size: 17px;" *ngIf="billingSummaryDetails?.Discount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>Discount Amount</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Discount}}</b>
                  </div>
                  <div class="offset-6 col-3 text-right" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn) ">
                      <div *ngIf="billingSummaryDetails?.discountApprovedBy != ''">
                          <b>Discount Approved By</b><br>
                          <b>({{billingSummaryDetails?.discountApprovedBy}})</b>
                      </div>
                  </div>
        </div>              
        <div class="row" style="font-size: 17px;" *ngIf="billingSummaryDetails.NetAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{(billingSummaryDetails?.NetAmountLabel) ? billingSummaryDetails?.NetAmountLabel : 'NetAmount'}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.NetAmount}}</b>
                  </div>
        </div>
        <div class="row" style="font-size: 17px;" *ngIf="billingSummaryDetails.Receipt_Amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount_Label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount}}</b>
                  </div>
        </div>
        <div class="row" style="font-size: 17px;" *ngIf="billingSummaryDetails.Balance_Amount != '0.00'">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Balance_label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ (billingSummaryDetails?.Balance_Amount < 0) ? (billingSummaryDetails?.Balance_Amount * -1) : billingSummaryDetails?.Balance_Amount }}</b>
                  </div>
        </div>
        <div class="row" style="font-size: 17px;" *ngIf="billingSummaryDetails?.AmountRefundedAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedLabel }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedAmount }}</b>
                  </div>
        </div>
        <div class="row" style="font-size: 17px;" *ngIf="billingSummaryDetails?.Refund_payble_Balance_amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Amount_label }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Balance_amount }}</b>
                  </div>
        </div>
        <br />
        
        <div *ngIf="finalBillReceiptsList.length !== 0" style="font-size: 17px;">
            <div>
                <b>Receipt / Payment Details</b>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row table1">
                <div class="col-md-1"><b>Recpt. No</b></div>
                <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
                <div class="col-md-2"><b>Cash Amt</b></div>
                <div class="col-md-2"><b>Cheque Amt</b></div>
                <div class="col-md-1"><b>Card/ <br/>Wallet Amt</b></div>
                <div class="col-md-2 text-right"><b>Recpt. Amt.</b></div>
                <div class="col-md-1"><b>Recpt.<br/> Type</b></div>
                <div class="col-md-1 p-0"><b>Remarks</b></div>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" *ngFor="let receptInfo of finalBillReceiptsList">
                <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
                <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
                <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
                <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
                <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
                <div class="col-md-2 text-right">{{receptInfo?.RecptAmt}}</div>
                <div class="col-md-1">{{receptInfo?.ReceiptType}}</div>
                <div class="col-md-1 p-0">
                    <span *ngIf="receptInfo?.Remarks !== ''">
                        {{receptInfo?.Remarks}}:</span>
                </div>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" *ngIf="billingSummaryDetails.Total_Receipt_Amount > 0">
                <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
                <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
            </div>
        </div>
        
        <hr class="my-2 border border-dark" />
        
        <div>
            <b style="text-transform: capitalize; font-size: 17px;" *ngIf="inWordsReceiptAmount && btnStatus == 'N'">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
            <b style="text-transform: capitalize; font-size: 17px;" *ngIf="btnStatus == 'Y'">Total Received Amount in Words: Five Thousand Rupees Only</b>
        </div>
        <div>
            <b style="text-transform: capitalize; font-size: 17px;" *ngIf="inWordsGrossAmount && btnStatus == 'N'">Gross Amount in Words: {{inWordsGrossAmount}}</b>
            <b style="text-transform: capitalize; font-size: 17px;" *ngIf="btnStatus == 'Y'">Gross Amount in Words: Five Thousand Rupees Only</b>
        </div>
        <div class="row" style="font-size: 17px;" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn)">
            <div class="col-6 text-left">
                <div class="row mt-5 mb-3">
                    <div class="col-4"><b>Admission Created By</b></div>
                    <div class="col">{{finalBillPatientInfo?.admissionCreatedBy}}</div>
                </div>
            </div>
            <div class="col-6 text-right">
                <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div>
        </div>
        
        <div class="text-right" style="font-size: 17px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
        </div>
        <div class="row" style="font-size: 17px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared By</b>
                    </div>
                    <div class="col">
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared Dt</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PreparedDt | date:"dd-MM-yyyy"}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="font-size: 17px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed By</b>
                    </div>
                    <div class="col">
                        {{userInfo?.username}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed On</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PrintedOn | date:"dd-MM-yyyy"}}
                    </div>
                </div>
            </div>
        </div>
        
        <div style="font-size: 17px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
            <div class="mt-5">
                <div class="text-center">
                    <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-8"></div>
                <div class="col-4">
                    <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                    <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
                </div>
        
            </div>
        
            <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
                and I agree
                and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
                i
                acknowledge the bill.</p>
        
            <div class="text-center mt-5">
                <div class="row justify-content-center">
                    <div class="col-7">
                        <div class="row my-4">
                            <div class="col-3">Signature</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Name</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Ph No</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Date</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="userInfo.hospitalShortName == 'KPMTA'">
        <div class="row no-gutters">
            <div class="col-md-12">
                <div class="text-center">
                    <h3 class="mb-1 font-weight-bold">
                    <u>{{ finalBillPatientInfo?.InpatientFinalBIllLabel }}</u>
                    </h3>
                </div>
            </div>
        </div>
        <!-- Patient information -->
        <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
            <div class="col-md-6">
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillNo !== ''">
                    <div class="col-md-3">
                        <b>Bill No</b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillNo}}
                    </div>
                </div>
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillDate !== ''">
                    <div class="col-md-3">
                        <b>Bill Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Patient Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admission Date
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DateOfAdmission}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Consultant
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Consultant}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.Referral !== ''">
                    <div class="col-md-3">
                        Referral
                    </div>
                    <div class="col-md-9">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}({{finalBillPatientInfo?.ReferralCity}})
                        </div>                
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Department
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Department}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Address
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Address}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">
                        <b>IP No </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmissionNo}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.DischargeDate !== ''">
                    <div class="col-md-3">
                        <b>Discharge Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DischargeDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        S-W-D-B/O
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.SWDBO}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Relation
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Relation}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.UMRNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                Gender
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AgeSex}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admitted Ward
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmittedWard}}
                    </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                    Payee Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo.PayeeName}}
                </div>
            </div>
            </div>
        </div>
        <!-- ./END Patient information -->
        <div class="mt-2 text-center border border-dark p-2" *ngIf="finalBillPatientInfo?.SummaryBillBtn" style="font-size: 17px;">
            <b>Hospitalisation Charges from
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesFrom}}</span>
                <span class="pl-2">To</span>
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesTo}}</span>
            </b>
        </div>
        <ng-container *ngIf="finalBillServicesList.length !==0 && finalBillPatientInfo?.SummaryBillBtn">
        <div class="row table1 font-weight-bold" style="font-size: 17px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <div class="col-md-2">
                Service TypeId
            </div>
            <div class="col-md-4">
                Service Type
            </div>
            <div class="col-md-3">
                Total
            </div>
            <div class="col-md-2">
                  <span *ngIf="billServiceDiscountStatus && false">Discount</span>
            </div>
            <div class="col-md-1">
                Net Amount
            </div>
        </div>
        <div class="row table1" style="font-size: 16px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <ng-container *ngFor="let service of finalBillServicesList;">
                <div class="col-md-2">
                    {{service?.Service_typeid}}
                </div>
                <div class="col-md-4">
                    {{service?.Service_typename}}
                </div>
                <div class="col-md-3">
                    {{service?.totalAmount}}
                </div>
                <div class="col-md-2">
                      <span *ngIf="billServiceDiscountStatus && false">{{service?.Discount}}</span>
        
                </div>
                <div class="col-md-1 text-right">
                      {{service?.totalAmount}}
                </div>
            </ng-container>
        </div>
        </ng-container> 
        <ng-container  *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && finalBillServicesList.length !== 0">
        
            <!-- Daywise Break button table header -->
            <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                <div class="row table1 font-weight-bold">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-2">
                        Service Date
                    </div>
                    <div class="col-md-3">
                        Services/Investigation
                    </div>
                    <div class="col-md-1">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-1 text-right">
                              <span *ngIf="false">Discount</span>
                    </div>
                    <div class="col-md-1">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
        
            <!-- Full Break button table header -->
            <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                <div class="row table1 font-weight-bold" style="font-size: 16px;">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-4">
                        Services/Investigation
                    </div>
                    <div class="col-md-2">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <!-- <div class="col-md-2 text-center">
                        <span>Discount</span>
                    </div> -->
                    <div class="col-md-2 text-right">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
            <div *ngFor="let service of finalBillServicesList;">
                
                <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                    <div class="row table2" style="font-size: 16px;">            
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right" style="padding-right: 20px;">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>
                </div>
                <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                    <div class="row table2">
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>   
                </div>
        
                <ng-container *ngIf="!(finalBillPatientInfo?.SummaryBillBtn)">
                    <!-- Daywise Break button table data -->
                    <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">   
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                                  <span *ngIf="false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                    </div>
                    <!-- Full Break button table data -->
                    <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                            <div class="col-md-2">
                                {{subService?.ServiceCode}}
                            </div>
                            <div class="col-md-4">
                                {{subService?.Services}}
                            </div>
                            <div class="col-md-1">
                                {{subService?.Qty}}
                            </div>
                            <div class="col-md-1 text-right">
                                *
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                            </div>
                            <!-- <div class="col-md-2 text-center">
                                <span>{{subService?.Discount}}</span>
                            </div> -->
                            <div class="col-md-2 text-right">
                                <span class="tablecol">{{ subService?.Amount}}</span>
                            </div>
                        </div>
                    </div>      
                </ng-container>
                
                <ng-container *ngIf="finalBillPatientInfo?.SummaryBillBtn">
                    <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2" *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1">
                                  <span *ngIf="billServiceDiscountStatus && false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        
        <br />
        <hr class="my-2 border border-dark" />
        
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount}}</b>
            </div>
        </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Discount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>Discount Amount</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Discount}}</b>
                  </div>
                  <div class="offset-6 col-3 text-right" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn) ">
                      <div *ngIf="billingSummaryDetails?.discountApprovedBy != ''">
                          <b>Discount Approved By</b><br>
                          <b>({{billingSummaryDetails?.discountApprovedBy}})</b>
                      </div>
                  </div>
              </div>
              
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.NetAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{(billingSummaryDetails?.NetAmountLabel) ? billingSummaryDetails?.NetAmountLabel : 'NetAmount'}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.NetAmount}}</b>
                  </div>
              </div>
      
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Receipt_Amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount_Label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount}}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Balance_Amount != '0.00'">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Balance_label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ (billingSummaryDetails?.Balance_Amount < 0) ? (billingSummaryDetails?.Balance_Amount * -1) : billingSummaryDetails?.Balance_Amount }}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.AmountRefundedAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedLabel }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedAmount }}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Refund_payble_Balance_amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Amount_label }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Balance_amount }}</b>
                  </div>
              </div>
        <br />
        
        <div *ngIf="finalBillReceiptsList.length !== 0">
            <div style="font-size: 17px;">
                <b>Receipt / Payment Details</b>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row table1" style="font-size: 17px;">
                <div class="col-md-1"><b>Recpt. No</b></div>
                <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
                <div class="col-md-2"><b>Cash Amt</b></div>
                <div class="col-md-2"><b>Cheque Amt</b></div>
                <div class="col-md-1"><b>Card/ <br/>Wallet Amt</b></div>
                <div class="col-md-2 text-right"><b>Recpt. Amt.</b></div>
                <div class="col-md-1"><b>Recpt.<br/> Type</b></div>
                <div class="col-md-1 p-0"><b>Remarks</b></div>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" style="font-size: 16px;" *ngFor="let receptInfo of finalBillReceiptsList">
                <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
                <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
                <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
                <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
                <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
                <div class="col-md-2 text-right">{{receptInfo?.RecptAmt}}</div>
                <div class="col-md-1">{{receptInfo?.ReceiptType}}</div>
                <div class="col-md-1 p-0">
                    <span *ngIf="receptInfo?.Remarks !== ''">
                        {{receptInfo?.Remarks}}:</span>
                </div>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Total_Receipt_Amount > 0">
                <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
                <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
            </div>
        </div>
        
        <hr class="my-2 border border-dark" />
        
        <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
        </div>
        <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}}
            </b>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn)">
            <div class="col-6 text-left">
                <div class="row mt-5 mb-3">
                    <div class="col-4"><b>Admission Created By</b></div>
                    <div class="col">{{finalBillPatientInfo?.admissionCreatedBy}}</div>
                </div>
            </div>
            <div class="col-6 text-right">
                <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div>
        </div>
        
        <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
        </div>
        <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared By</b>
                    </div>
                    <div class="col">
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared Dt</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PreparedDt}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed By</b>
                    </div>
                    <div class="col">
                        {{userInfo?.username}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed On</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PrintedOn}}
                    </div>
                </div>
            </div>
        </div>
        
        <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
            <div class="mt-5">
                <div class="text-center">
                    <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-8"></div>
                <div class="col-4">
                    <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                    <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
                </div>
        
            </div>
        
            <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
                and I agree
                and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
                i
                acknowledge the bill.</p>
        
            <div class="text-center mt-5">
                <div class="row justify-content-center">
                    <div class="col-7">
                        <div class="row my-4">
                            <div class="col-3">Signature</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Name</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Ph No</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Date</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="userInfo.hospitalShortName == 'CPMTA'">
        <div class="row no-gutters heading-block" >
            <div class="col-md-2 logo">
            <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
            </div>
            <div class="col-md-9">
                <div class="text-center">
                    <h4 class="main-title mb-0">{{ finalBillPatientInfo?.HospitalName }}</h4>
                    <span style="font-size: 12px; font-weight: normal; display: inline-block; ">{{ finalBillPatientInfo?.HospitalTagLine }}</span>
                    <p>{{ finalBillPatientInfo?.HospitalAddress }}</p>
                    <h3 class="mb-1 font-weight-bold">
                    <u>{{ finalBillPatientInfo?.InpatientFinalBIllLabel }}</u>
                    </h3>
                </div>
            </div>
        </div>
        <!-- Patient information -->
        <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
            <div class="col-md-6">
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillNo !== ''">
                    <div class="col-md-3">
                        <b>Bill No</b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillNo}}
                    </div>
                </div>
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillDate !== ''">
                    <div class="col-md-3">
                        <b>Bill Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Patient Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admission Date
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DateOfAdmission}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Consultant
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Consultant}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.Referral !== ''">
                    <div class="col-md-3">
                        Referral
                    </div>
                    <div class="col-md-9">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}({{finalBillPatientInfo?.ReferralCity}})
                        </div>                
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Department
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Department}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Address
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Address}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">
                        <b>Admission No </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmissionNo}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.DischargeDate !== ''">
                    <div class="col-md-3">
                        <b>Discharge Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DischargeDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        S-W-D-B/O
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.SWDBO}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.UMRNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                Gender
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AgeSex}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admitted Ward
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmittedWard}}
                    </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                    Payee Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo.PayeeName}}
                </div>
            </div>
            </div>
        </div>
        <!-- ./END Patient information -->
        <div class="mt-2 text-center border border-dark p-2" *ngIf="finalBillPatientInfo?.SummaryBillBtn" style="font-size: 17px;">
            <b>Hospitalisation Charges from
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesFrom}}</span>
                <span class="pl-2">To</span>
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesTo}}</span>
            </b>
        </div>
        <ng-container *ngIf="finalBillServicesList.length !==0 && finalBillPatientInfo?.SummaryBillBtn">
        <div class="row table1 font-weight-bold" style="font-size: 17px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <div class="col-md-2">
                Service TypeId
            </div>
            <div class="col-md-4">
                Service Type
            </div>
            <div class="col-md-3">
                Total
            </div>
            <div class="col-md-2">
                  <span *ngIf="billServiceDiscountStatus && false">Discount</span>
            </div>
            <div class="col-md-1">
                Net Amount
            </div>
        </div>
        <div class="row table1" style="font-size: 16px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <ng-container *ngFor="let service of finalBillServicesList;">
                <div class="col-md-2">
                    {{service?.Service_typeid}}
                </div>
                <div class="col-md-4">
                    {{service?.Service_typename}}
                </div>
                <div class="col-md-3">
                    {{service?.totalAmount}}
                </div>
                <div class="col-md-2">
                      <span *ngIf="billServiceDiscountStatus && false">{{service?.Discount}}</span>
        
                </div>
                <div class="col-md-1 text-right">
                      {{service?.totalAmount}}
                </div>
            </ng-container>
        </div>
        </ng-container> 
        <ng-container  *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && finalBillServicesList.length !== 0">
        
            <!-- Daywise Break button table header -->
            <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                <div class="row table1 font-weight-bold">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-2">
                        Service Date
                    </div>
                    <div class="col-md-3">
                        Services/Investigation
                    </div>
                    <div class="col-md-1">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-1 text-right">
                              <span *ngIf="false">Discount</span>
                    </div>
                    <div class="col-md-1">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
        
            <!-- Full Break button table header -->
            <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                <div class="row table1 font-weight-bold" style="font-size: 16px;">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-4">
                        Services/Investigation
                    </div>
                    <div class="col-md-2">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <!-- <div class="col-md-2 text-center">
                        <span>Discount</span>
                    </div> -->
                    <div class="col-md-2 text-right">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
            <div *ngFor="let service of finalBillServicesList;">
                
                <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                    <div class="row table2" style="font-size: 16px;">            
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right" style="padding-right: 20px;">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>
                </div>
                <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                    <div class="row table2">
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>   
                </div>
        
                <ng-container *ngIf="!(finalBillPatientInfo?.SummaryBillBtn)">
                    <!-- Daywise Break button table data -->
                    <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">   
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                                  <span *ngIf="false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                    </div>
                    <!-- Full Break button table data -->
                    <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                            <div class="col-md-2">
                                {{subService?.ServiceCode}}
                            </div>
                            <div class="col-md-4">
                                {{subService?.Services}}
                            </div>
                            <div class="col-md-1">
                                {{subService?.Qty}}
                            </div>
                            <div class="col-md-1 text-right">
                                *
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                            </div>
                            <!-- <div class="col-md-2 text-center">
                                <span>{{subService?.Discount}}</span>
                            </div> -->
                            <div class="col-md-2 text-right">
                                <span class="tablecol">{{ subService?.Amount}}</span>
                            </div>
                        </div>
                    </div>      
                </ng-container>
                
                <ng-container *ngIf="finalBillPatientInfo?.SummaryBillBtn">
                    <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2" *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1">
                                  <span *ngIf="billServiceDiscountStatus && false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        
        <br />
        <hr class="my-2 border border-dark" />
        
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount}}</b>
            </div>
        </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Discount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>Discount Amount</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Discount}}</b>
                  </div>
                  <div class="offset-6 col-3 text-right" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn) ">
                      <div *ngIf="billingSummaryDetails?.discountApprovedBy != ''">
                          <b>Discount Approved By</b><br>
                          <b>({{billingSummaryDetails?.discountApprovedBy}})</b>
                      </div>
                  </div>
              </div>
              
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.NetAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{(billingSummaryDetails?.NetAmountLabel) ? billingSummaryDetails?.NetAmountLabel : 'NetAmount'}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.NetAmount}}</b>
                  </div>
              </div>
      
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Receipt_Amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount_Label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount}}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Balance_Amount != '0.00'">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Balance_label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ (billingSummaryDetails?.Balance_Amount < 0) ? (billingSummaryDetails?.Balance_Amount * -1) : billingSummaryDetails?.Balance_Amount }}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.AmountRefundedAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedLabel }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedAmount }}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Refund_payble_Balance_amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Amount_label }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Balance_amount }}</b>
                  </div>
              </div>
        <br />
        
        <div *ngIf="finalBillReceiptsList.length !== 0">
            <div style="font-size: 17px;">
                <b>Receipt / Payment Details</b>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row table1" style="font-size: 17px;">
                <div class="col-md-1"><b>Recpt. No</b></div>
                <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
                <div class="col-md-2"><b>Cash Amt</b></div>
                <div class="col-md-2"><b>Cheque Amt</b></div>
                <div class="col-md-1"><b>Card/ <br/>Wallet Amt</b></div>
                <div class="col-md-2 text-right"><b>Recpt. Amt.</b></div>
                <div class="col-md-1"><b>Recpt.<br/> Type</b></div>
                <div class="col-md-1 p-0"><b>Remarks</b></div>
            </div>
            <hr class="my-2 border border-dark" style="font-size: 16px;"/>
            <div class="row" *ngFor="let receptInfo of finalBillReceiptsList">
                <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
                <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
                <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
                <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
                <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
                <div class="col-md-2 text-right">{{receptInfo?.RecptAmt}}</div>
                <div class="col-md-1">{{receptInfo?.ReceiptType}}</div>
                <div class="col-md-1 p-0">
                    <span *ngIf="receptInfo?.Remarks !== ''">
                        {{receptInfo?.Remarks}}:</span>
                </div>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Total_Receipt_Amount > 0">
                <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
                <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
            </div>
        </div>
        
        <hr class="my-2 border border-dark" />
        
        <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
        </div>
        <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}}
            </b>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn)">
            <div class="col-6 text-left">
                <div class="row mt-5 mb-3">
                    <div class="col-4"><b>Admission Created By</b></div>
                    <div class="col">{{finalBillPatientInfo?.admissionCreatedBy}}</div>
                </div>
            </div>
            <div class="col-6 text-right">
                <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div>
        </div>
        
        <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
        </div>
        <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared By</b>
                    </div>
                    <div class="col">
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared Dt</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PreparedDt}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed By</b>
                    </div>
                    <div class="col">
                        {{userInfo?.username}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed On</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PrintedOn}}
                    </div>
                </div>
            </div>
        </div>
        
        <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
            <div class="mt-5">
                <div class="text-center">
                    <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-8"></div>
                <div class="col-4">
                    <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                    <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
                </div>
        
            </div>
        
            <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
                and I agree
                and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
                i
                acknowledge the bill.</p>
        
            <div class="text-center mt-5">
                <div class="row justify-content-center">
                    <div class="col-7">
                        <div class="row my-4">
                            <div class="col-3">Signature</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Name</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Ph No</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Date</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="userInfo.hospitalShortName == 'JPMTA'">
        <div class="row no-gutters">
            <div class="col-md-12">
                <div class="text-center">
                    <h3 class="mb-1 font-weight-bold">
                    <u>{{ finalBillPatientInfo?.InpatientFinalBIllLabel }}</u>
                    </h3>
                </div>
            </div>
        </div>
        <!-- Patient information -->
        <div class="row mt-2" style="font-weight: bolder; font-size: 16px;">
            <div class="col-md-6">
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillNo !== ''">
                    <div class="col-md-3">
                        <b>Bill No</b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillNo}}
                    </div>
                </div>
        
                <div class="row" *ngIf="finalBillPatientInfo?.BillDate !== ''">
                    <div class="col-md-3">
                        <b>Bill Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.BillDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Patient Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admission Date
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DateOfAdmission}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Consultant
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Consultant}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.Referral !== ''">
                    <div class="col-md-3">
                        Referral
                    </div>
                    <div class="col-md-9">
                        <div *ngIf="walkin == true">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}
                        </div>
                        <div *ngIf="walkin == false">
                            <b>:</b> {{finalBillPatientInfo?.Referral}}({{finalBillPatientInfo?.ReferralCity}})
                        </div>                
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Department
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Department}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Address
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Address}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">
                        <b>Admission No </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmissionNo}}
                    </div>
                </div>
                <div class="row" *ngIf="finalBillPatientInfo?.DischargeDate !== ''">
                    <div class="col-md-3">
                        <b>Discharge Date </b>
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.DischargeDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        S-W-D-B/O
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.SWDBO}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Relation
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.Relation}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.UMRNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                Gender
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AgeSex}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Admitted Ward
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{finalBillPatientInfo?.AdmittedWard}}
                    </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                    Payee Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{finalBillPatientInfo.PayeeName}}
                </div>
            </div>
            </div>
        </div>
        <!-- ./END Patient information -->
        <div class="mt-2 text-center border border-dark p-2" *ngIf="finalBillPatientInfo?.SummaryBillBtn" style="font-size: 17px;">
            <b>Hospitalisation Charges from
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesFrom}}</span>
                <span class="pl-2">To</span>
                <span class="color pl-2 text-danger">{{finalBillPatientInfo?.HospitalChargesTo}}</span>
            </b>
        </div>
        <ng-container *ngIf="finalBillServicesList.length !==0 && finalBillPatientInfo?.SummaryBillBtn">
        <div class="row table1 font-weight-bold" style="font-size: 17px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <div class="col-md-2" >
                Service TypeId
            </div>
            <div class="col-md-4">
                Service Type
            </div>
            <div class="col-md-3">
                Total
            </div>
            <div class="col-md-2">
                  <span *ngIf="billServiceDiscountStatus && false">Discount</span>
            </div>
            <div class="col-md-1">
                Net Amount
            </div>
        </div>
        <div class="row table1" style="font-size: 16px;">
            <div class="col-12">
                <hr class="my-2 border border-dark" />
            </div>
            <ng-container *ngFor="let service of finalBillServicesList;">
                <div class="col-md-2">
                    {{service?.Service_typeid}}
                </div>
                <div class="col-md-4">
                    {{service?.Service_typename}}
                </div>
                <div class="col-md-3">
                    {{service?.totalAmount}}
                </div>
                <div class="col-md-2">
                      <span *ngIf="billServiceDiscountStatus && false">{{service?.Discount}}</span>
        
                </div>
                <div class="col-md-1 text-right">
                      {{service?.totalAmount}}
                </div>
            </ng-container>
        </div>
        </ng-container> 
        <ng-container  *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && finalBillServicesList.length !== 0">
        
            <!-- Daywise Break button table header -->
            <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                <div class="row table1 font-weight-bold">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-2">
                        Service Date
                    </div>
                    <div class="col-md-3">
                        Services/Investigation
                    </div>
                    <div class="col-md-1">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <div class="col-md-1 text-right">
                              <span *ngIf="false">Discount</span>
                    </div>
                    <div class="col-md-1">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
        
            <!-- Full Break button table header -->
            <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                <div class="row table1 font-weight-bold" style="font-size: 16px;">
                    <div class="col-12">
                        <hr class="my-2 borer border-dark" />
                    </div>
                    <div class="col-md-2">
                        Service Code
                    </div>
                    <div class="col-md-4">
                        Services/Investigation
                    </div>
                    <div class="col-md-2">
                        Qty.
                    </div>
                    <div class="col-md-2 text-right" style="padding-right: 25px;">
                        Rate
                    </div>
                    <!-- <div class="col-md-2 text-center">
                        <span>Discount</span>
                    </div> -->
                    <div class="col-md-2 text-right">
                        Amount
                    </div>
                    <div class="col-12">
                        <hr class="my-2 pdf-thick-border" />
                    </div>
                </div>
            </div> 
            <div *ngFor="let service of finalBillServicesList;">
                
                <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                    <div class="row table2" style="font-size: 16px;">            
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right" style="padding-right: 20px;">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>
                </div>
                <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                    <div class="row table2">
                        <div class="col-md-6 ">
                            <b>{{service?.ServiceType}}</b>
                        </div>
                        <div class="col-md-4 text-right">
                            <b>{{service?.ServiceTotalAmount}}</b>
                        </div>
                    </div>   
                </div>
        
                <ng-container *ngIf="!(finalBillPatientInfo?.SummaryBillBtn)">
                    <!-- Daywise Break button table data -->
                    <div *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">   
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                                  <span *ngIf="false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                    </div>
                    <!-- Full Break button table data -->
                    <div *ngIf="!(finalBillPatientInfo?.daywiseBreakBtn)">
                        <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory" style="font-size: 16px;">
                            <div class="col-md-2">
                                {{subService?.ServiceCode}}
                            </div>
                            <div class="col-md-4">
                                {{subService?.Services}}
                            </div>
                            <div class="col-md-1">
                                {{subService?.Qty}}
                            </div>
                            <div class="col-md-1 text-right">
                                *
                            </div>
                            <div class="col-md-2 text-right">
                                <span class="tablecol" style="padding-right: 6px;">{{subService?.Rate}}</span>
                            </div>
                            <!-- <div class="col-md-2 text-center">
                                <span>{{subService?.Discount}}</span>
                            </div> -->
                            <div class="col-md-2 text-right">
                                <span class="tablecol">{{ subService?.Amount}}</span>
                            </div>
                        </div>
                    </div>      
                </ng-container>
                
                <ng-container *ngIf="finalBillPatientInfo?.SummaryBillBtn">
                    <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
                        <div class="col-md-2">
                            {{subService?.ServiceCode}}
                        </div>
                        <div class="col-md-2" *ngIf="finalBillPatientInfo?.daywiseBreakBtn">
                            {{subService?.ServiceBillDate}}
                        </div>
                        <div class="col-md-3">
                            {{subService?.Services}}
                        </div>
                        <div class="col-md-1">
                            {{subService?.Qty}}
                        </div>
                        <div class="col-md-1">
                            *
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{subService?.Rate}}</span>
                        </div>
                        <div class="col-md-1">
                                  <span *ngIf="billServiceDiscountStatus && false">{{subService?.Discount}}</span>
                        </div>
                        <div class="col-md-1 text-right">
                            <span class="tablecol">{{ subService?.Amount}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        
        <br />
        <hr class="my-2 border border-dark" />
        
        <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Gross_Amount > 0">
            <div class="offset-6 col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount_label}}</b>
            </div>
            <div class="col-3 text-right">
                <b>{{billingSummaryDetails?.Gross_Amount}}</b>
            </div>
        </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Discount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>Discount Amount</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Discount}}</b>
                  </div>
                  <div class="offset-6 col-3 text-right" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn) ">
                      <div *ngIf="billingSummaryDetails?.discountApprovedBy != ''">
                          <b>Discount Approved By</b><br>
                          <b>({{billingSummaryDetails?.discountApprovedBy}})</b>
                      </div>
                  </div>
              </div>
              
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.NetAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{(billingSummaryDetails?.NetAmountLabel) ? billingSummaryDetails?.NetAmountLabel : 'NetAmount'}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.NetAmount}}</b>
                  </div>
              </div>
      
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Receipt_Amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount_Label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{billingSummaryDetails?.Receipt_Amount}}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Balance_Amount != '0.00'">
                  <div class="offset-6 col-3 text-right">
                      <b>{{billingSummaryDetails?.Balance_label}}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ (billingSummaryDetails?.Balance_Amount < 0) ? (billingSummaryDetails?.Balance_Amount * -1) : billingSummaryDetails?.Balance_Amount }}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.AmountRefundedAmount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedLabel }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.AmountRefundedAmount }}</b>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails?.Refund_payble_Balance_amount > 0">
                  <div class="offset-6 col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Amount_label }}</b>
                  </div>
                  <div class="col-3 text-right">
                      <b>{{ billingSummaryDetails?.Refund_payble_Balance_amount }}</b>
                  </div>
              </div>
        <br />
        
        <div *ngIf="finalBillReceiptsList.length !== 0">
            <div>
                <b style="font-size: 17px;">Receipt / Payment Details</b>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row table1" style="font-size: 17px;">
                <div class="col-md-1"><b>Recpt. No</b></div>
                <div class="col-md-2"><b>Recpt.<br/> Date.</b></div>
                <div class="col-md-2"><b>Cash Amt</b></div>
                <div class="col-md-2"><b>Cheque Amt</b></div>
                <div class="col-md-1"><b>Card/ <br/>Wallet Amt</b></div>
                <div class="col-md-2 text-right"><b>Recpt. Amt.</b></div>
                <div class="col-md-1"><b>Recpt.<br/> Type</b></div>
                <div class="col-md-1 p-0"><b>Remarks</b></div>
            </div>
            <hr class="my-2 border border-dark" style="font-size: 16px;" />
            <div class="row" *ngFor="let receptInfo of finalBillReceiptsList">
                <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
                <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
                <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
                <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
                <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
                <div class="col-md-2 text-right">{{receptInfo?.RecptAmt}}</div>
                <div class="col-md-1">{{receptInfo?.ReceiptType}}</div>
                <div class="col-md-1 p-0">
                    <span *ngIf="receptInfo?.Remarks !== ''">
                        {{receptInfo?.Remarks}}:</span>
                </div>
            </div>
            <hr class="my-2 border border-dark" />
            <div class="row" style="font-size: 16px;" *ngIf="billingSummaryDetails.Total_Receipt_Amount > 0">
                <div class="offset-6 col-2 text-right"><b>{{billingSummaryDetails?.Receipt_list_total_Label}}</b></div>
                <div class="col-3 text-center"><b>{{billingSummaryDetails?.Total_Receipt_Amount}}</b></div>
            </div>
        </div>
        
        <hr class="my-2 border border-dark" />
        
        <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsReceiptAmount">Total Received Amount in Words: {{inWordsReceiptAmount}}</b>
        </div>
        <div>
            <b style="text-transform: capitalize; font-size: 16px;" *ngIf="inWordsGrossAmount">Gross Amount in Words: {{inWordsGrossAmount}}
            </b>
        </div>
        <div class="row" style="font-size: 16px;" *ngIf="!(finalBillPatientInfo?.SummaryBillBtn) && !(finalBillPatientInfo?.daywiseBreakBtn)">
            <div class="col-6 text-left">
                <div class="row mt-5 mb-3">
                    <div class="col-4"><b>Admission Created By</b></div>
                    <div class="col">{{finalBillPatientInfo?.admissionCreatedBy}}</div>
                </div>
            </div>
            <div class="col-6 text-right">
                <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
            </div>
        </div>
        
        <div class="text-right" style="font-size: 16px;" *ngIf="finalBillPatientInfo?.SummaryBillBtn || finalBillPatientInfo?.daywiseBreakBtn">
            <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
        </div>
        <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared By</b>
                    </div>
                    <div class="col">
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 0">{{userInfo?.username}}</span>
                        <span
                            *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus == 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Prepared Dt</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PreparedDt}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="font-size: 16px;">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed By</b>
                    </div>
                    <div class="col">
                        {{userInfo?.username}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                        <b>Printed On</b>
                    </div>
                    <div class="col">
                        {{finalBillFooterAcknowledgement?.PrintedOn}}
                    </div>
                </div>
            </div>
        </div>
        
        <div style="font-size: 16px;" *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
            <div class="mt-5">
                <div class="text-center">
                    <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-8"></div>
                <div class="col-4">
                    <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                    <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
                </div>
        
            </div>
        
            <p class="text-center">I Recieve the bill for my patient <b>{{finalBillPatientInfo?.PatientName}} ..</b>
                and I agree
                and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
                i
                acknowledge the bill.</p>
        
            <div class="text-center mt-5">
                <div class="row justify-content-center">
                    <div class="col-7">
                        <div class="row my-4">
                            <div class="col-3">Signature</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Name</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Ph No</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                        <div class="row my-4">
                            <div class="col-3">Date</div>
                            <div class="col-9" style="border-bottom:2px solid #000;padding-top:40px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
