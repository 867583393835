
<div class="d-flex">
  <div class="container-fluid total-page">
    <div class="row page-header">      
      <div class="col-md-12 col-12 col-lg-12 col-sm-12 mt-2 align-center">
          <div class="cont1 centercont">
              <button class="button-center bgg-clr col-6" (click)="bodyDisplay = 'IP';loadPatientsList();"
                >In-patient</button><!-- [class.cont]="bodyDisplay === 'IP'" (click)="bodyDisplay = 'IP';loadSampleCollectionList();" -->
              <!-- <button class="button-center bgg-clr col-6" 
                >Out-patient</button> --><!-- [class.cont]="bodyDisplay === 'OP'" (click)="bodyDisplay = 'OP';loadSampleCollectionList();" -->
          </div>
      </div>
    </div>
    <!-- Advance search -->
    <div class="row ml-1 col-md-12">        
          <div class="col-md-6 text-left">
              <div class="row">
                <form [formGroup]="advSearchForm" (ngSubmit)="onSearch()" autocomplete="off" class="form-inline"><!--(ngSubmit)="onSearch()"-->
                    <div class="row advance-search mt-1 mb-2">
                        <div class="col-md-12 d-flex">                                
                            <div class="row col-md-12 p-0">
                                <div class="col-md-12 form-group">
                                    <div class="col-md-9">
                                      <div class="row">
                                        <label class="col-4 modname align-box mb-3"> Patient Name:</label>
                                        <div class="col-6">
                                        <input  name="textinput" id="PATIENTNAME" formControlName="PATIENTNAME" class="form-control" type="text"/>
                                        <span class="helptxt">Search with minimum 3 characters</span>                         
                                        </div>
                                      </div>  
                                    </div>
                                    <div class="col-md-3">
                                      <div class="col-12 ml-1" style="text-align: right;">
                                        <button type="submit" class="btn btn-primary advanced-search-btn ">Search</button>
                                      </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <div class="col-sm-4 mb-2  p-0">
                                <div class="form-group">
                                    <label class="col-sm-3 modname align-box " >Date:</label>
                                    <div class="col-sm-6 pl-4">
                                    <input  name="searchDate" formControlName="LABDATE" class="form-control input-textt1 " type="date">
                                    </div>
                                </div>
                            </div>
                        </div> -->    
                    </div>
                </form>
              </div>
          </div>
          <div class="col-md-6">
            <div class="offset-6 col-md-6 p-3 text-right">
                <a href="javascript:void(0)" (click)="getDischargeList()"><b>Go to Discharge List</b></a>
            </div>
          </div>       
    </div>

    <div class="row justify-content-center mb-2" ><!-- [ngClass]="{ 'opDisplay': bodyDisplay == 'IP' }" -->
      <div class="col-md-12" >
          <div class="matcontent">
            <mat-table #table [dataSource]="patientsData" matSort>

            <ng-container matColumnDef="IPNUM">
              <mat-header-cell *matHeaderCellDef  mat-sort-header class="center">
                <div>IP NO</div>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element" class="center">
                <div>{{ element.IP_NR }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="NAMEGENDERAGE">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>Name & Gender/Age</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.name }}">
                <div>
                  <a href="javascript:void(0)" (click)="patientQuickInfo(patientQuickInfoPopup, element)">
                    {{ element.TITLE }} {{ element.PATIENT_NAME }}
                  </a><br/>{{ element.GENDER }}/<br>{{element.AGE}}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CONSULTDOCTORWARDBED">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="text-left">Admitting Doctor Name<br>& Ward/Bed</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div *ngIf="element.BED_STATUS != 'C'" class="bednormal"><span style="color: #1661eef5">{{ element.CONSULT_DOCTOR}}</span><br>
                  {{element?.WM_WARD_NAME}}<br>
                  <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span>
                </div>
                <div *ngIf="element.BED_STATUS == 'C'" class="bedconfirmflag">
                  {{ element.CONSULT_DOCTOR }}<br>{{element?.WM_WARD_NAME}}<br>
                  <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ADMISSIONDATEANDTIME">
              <mat-header-cell *matHeaderCellDef >
                <div class="text-left">Admission Date <br> Admission Time</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_ADMISSION_DATE }}">
                <div>{{ element.IP_ADMISSION_DATE }} <br> {{element.IP_ADMISSION_TIME}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="REFERRALDOCTOR">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Referral Doctor</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"><!-- matTooltip="{{ element.IP_DISCHARGE_DATE }}" -->
                <div>{{ element.externalRefDoctor }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LENGTHOFSTAY">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Length of Stay</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" ><!-- matTooltip="{{ element.description }}" -->
                <div>{{ element.LENGTH_OF_STAY }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ACTION">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Action</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="form-inline" style="min-width: 100px;">
                  <i class="fas fa-microscope p-2 back-hover" title="View Lab Reports" (click)="viewLabReportsPopup(labReportsPopup, element)"></i>
                    <i class="fas fa-clipboard-list p-2 back-hover" title="Discharge Summary" (click)="printDischargeSummary(dischargeSummaryPrintPopup, element)"></i>
                    <i class="fas fa-book-medical p-2 back-hover" title="ICD-10" (click)="actionIcdPop(icdPopup, element)"></i>
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                    <!-- <i class="material-icons">report</i> class="fas fa-tasks p-2 back-hover" class="material-icons p-2 back-hover" priority_high-->                    
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      (click)="viewLabReportsPopup(labReportsPopup, element)" mat-menu-item>
                      <span>View Lab Results</span>
                    </button>
                    <button (click)="printDischargeSummary(dischargeSummaryPrintPopup, element)" mat-menu-item>
                      <span>Discharge Summary </span>
                    </button>
                    <button (click)="actionIcdPop(icdPopup, element)" mat-menu-item>
                      <span>ICD-10</span>
                    </button>
                  </mat-menu>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
          </mat-table>
      
              <div class="mat-norecords" *ngIf="patientsData.data.length == 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
              <!-- <mat-paginator #opPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons 
              style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
              </mat-paginator> -->
          </div>
      </div>
    </div>    
  </div>
</div>
<!-- Patient QuickInfo Start -->
  <ng-template #patientQuickInfoPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Patient Quick Info</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              IP Number
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_NR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Next of Kin / Guardian Name
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinName || patientQuickInformation.NEXT_OF_KIN_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              UMR No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.P_ID}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Relation
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinRelatoin}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Mobile No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.mobileNo || patientQuickInformation.NEXT_OF_KIN_MOBILENR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Date
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_DATE}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Time
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_TIME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admitting Doctor
            </div>
            <div class="col">
              <span>{{patientQuickInformation.CONSULT_DOCTOR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Treating Doctor / Cross Consultants
            </div>
            <div class="col">
              <span>{{patientQuickInformation?.treatingDoctors?.DM_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              External Referral
            </div>
            <div class="col">
              <span>{{patientQuickInformation.externalRefDoctor}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-template>
  <!-- Patient QuickInfo End -->
  
<!-- print discharge summary popup -->
<ng-template #dischargeSummaryPrintPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Discharge Summary</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
    <app-discharge-summary-popup [selectedBed]="dischargeSummaryPatientInfo" [existingRecord]="existingRecord"
    [dischargeSummaryInfo]="existingDischargeSummaryInfo.DISCHARGE_SUMMARY_INFO"></app-discharge-summary-popup>
  </div>
</ng-template>
<!-- ./END print discharge summary popup -->

<!-- ICD Popup -->
<ng-template #icdPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">ICD-10 Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
    <app-icd [patientDetails]="icdPatientDetails" (ICDpopupClose)="popupClose()"></app-icd>
  </div>
</ng-template>
<!-- ./ END ICD Popup -->

<ng-template #labReportsPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Lab Results</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body">
  <app-view-lab-reports [patientIPNo]="patientIPNo" [patientUMRNo]="patientUMRNo" (reportsPopupClose)="popupClose()"></app-view-lab-reports>
  </div>
</ng-template>