import { environment } from 'src/environments/environment';

export const serverUrl = environment.apiUrl;

export const GET_IP_FLOORS_LIST = 'IpBedAllocation/getFloorslist';
export const GET_WARDS_INFO = 'IpBedAllocation/getFloorWardslist';
export const GET_BEDS_INFO = 'IpBedAllocation/getRoomBedslist';

export const WARD_LIST = 'Api/ward_list';
export const WARD_BEDS_LIST = 'Api/ward_beds_list';
export const PAYEE_NAME_LIST = 'Api/payeenameslist';
export const PATIENT_ADMISSION = 'Api/patient_addmission';
export const IPPATIENT_INFO_DATA = 'Api/ip_pateint_infodata';
export const BED_CONFIRMATION = 'IpBedAllocation/IpBedAllocatoinTransfer';
export const GET_AVAILABLE_BED_COUNTS = 'IpBedAllocation/getWardOccupiedAvailabilityBedsInfo';

export const DOCTORS_LIST = 'IpBedAllocation/getDoctorList';
export const DEPARTMENTS_LIST = 'IpBedAllocation/getDepartmentList';
export const CROSS_CONSULT_DOCTOR_FORM = 'IpBedAllocation/treatCrossConsultDoctorForm';

const GET_DISCHARGE_SUMMARY = 'DischargedPatients/getDischargeSummary';
const INSERT_DISCHARGE_SUMMARY = 'DischargedPatients/insertDischargeSummary';
const UPDATE_DISCHARGE_SUMMARY = 'DischargedPatients/updateDischargeSummary';
const DISCHARGE_SUMMARY_FINALIZE = 'DischargedPatients/dischargeSummaryFinalize';
const INSERT_IP_ADMISSION_DETAILS = 'IpAdmissionInfo/ipAdmissionInfoSubmit';
const GET_ADMISSION_INFORMATION = 'IpAdmissionInfo/getAdmissionInformation';
const GET_INPATIENT_PAYEE_INFO = 'Api/getInpatientPayeeInfo';
const GET_EXISTING_PAYEENAMES_LIST = 'Api/patientexistpayeenameslist';
const UPDATE_PAYEE_NAMETO_PATIENT = 'Api/UpdatePayeeNametoPatient';

const GET_IPLAB_SERVICE_LIST = 'v1/labmodule/getIPLabserviceList';
const SAMPLEIDS_BY_IPNR = 'v1/labmodule/getSampleIdsByIpNrService';
//const GET_SUBTESTS_BY_SERVICE = 'v1/labmodule/getAllSubtestsByService';
const GET_SUBTESTS_BY_SERVICE = 'v1/labmodule/getSubtestsByService';

const PATIENT_DEMOGRAPHIC_DETAILS = 'Patient/patientDemographicDetails'
const GET_DEPARTMENT_TYPES = 'DischargedPatients/getDepartmentType'
const GET_DISCHARGE_TYPE =  'DischargedPatients/getDischargeType'
const GET_DISCHARGE_SUMMARY_TEMPLATES = 'DischargedPatients/getDischargeTemplatesList'
const GET_REFERRALS_LIST = 'Api/getReferralList';
const EXTERNAL_REFERRAL_UPDATE = 'Api/externalReferralUpdate';
const IP_PATIENTS_LIST = 'UserMobileApp/ip_patients_list';
const DATA_LOG_LIST ='Api/ipPatientDataLogList';
const GET_DEFAULT_TEMPLATE = 'DischargedPatients/getDefaultTemplate';
const ADD_PROVISIONAL_DIAGNOSIS = 'Api/add_dischargeSummaryProvisionalDiagnosis';
const GET_DIAGNOSIS_NAME = 'Api/getProvisionalDiagIp';

export const WARD_API_URLS = {
    GET_IP_FLOORS_LIST: serverUrl + GET_IP_FLOORS_LIST,
    GET_FLOOR_INFO: serverUrl + GET_WARDS_INFO,
    GET_BEDS_INFO: serverUrl + GET_BEDS_INFO,

    WARD_LIST: serverUrl + WARD_LIST,
    WARD_BEDS_LIST: serverUrl + WARD_BEDS_LIST,
    PAYEE_NAME_LIST: serverUrl + PAYEE_NAME_LIST,
    PATIENT_ADMISSION: serverUrl + PATIENT_ADMISSION,
    IPPATIENT_INFO_DATA: serverUrl + IPPATIENT_INFO_DATA,
    BED_CONFIRMATION: serverUrl + BED_CONFIRMATION,
    GET_AVAILABLE_BED_COUNTS: serverUrl + GET_AVAILABLE_BED_COUNTS,
    INSERT_DISCHARGE_SUMMARY: serverUrl + INSERT_DISCHARGE_SUMMARY,
    GET_DISCHARGE_SUMMARY: serverUrl + GET_DISCHARGE_SUMMARY,
    UPDATE_DISCHARGE_SUMMARY: serverUrl + UPDATE_DISCHARGE_SUMMARY,
    DISCHARGE_SUMMARY_FINALIZE: serverUrl + DISCHARGE_SUMMARY_FINALIZE,
    GET_DEFAULT_TEMPLATE: serverUrl + GET_DEFAULT_TEMPLATE,

    DOCTORS_LIST: serverUrl + DOCTORS_LIST,
    DEPARTMENTS_LIST: serverUrl + DEPARTMENTS_LIST,
    CROSS_CONSULT_DOCTOR_FORM: serverUrl + CROSS_CONSULT_DOCTOR_FORM,
    INSERT_IP_ADMISSION_DETAILS: serverUrl + 'v1/' + INSERT_IP_ADMISSION_DETAILS,
    GET_ADMISSION_INFORMATION: serverUrl + 'v1/' + GET_ADMISSION_INFORMATION,
    GET_INPATIENT_PAYEE_INFO: serverUrl + GET_INPATIENT_PAYEE_INFO,
    GET_EXISTING_PAYEENAMES_LIST: serverUrl + GET_EXISTING_PAYEENAMES_LIST,
    UPDATE_PAYEE_NAMETO_PATIENT: serverUrl + UPDATE_PAYEE_NAMETO_PATIENT,

    PATIENT_DEMOGRAPHIC_DETAILS: PATIENT_DEMOGRAPHIC_DETAILS,

    GET_IPLAB_SERVICE_LIST: serverUrl + GET_IPLAB_SERVICE_LIST,
    SAMPLEIDS_BY_IPNR: serverUrl + SAMPLEIDS_BY_IPNR,
    GET_SUBTEST_BY_SERVICE: serverUrl + GET_SUBTESTS_BY_SERVICE,

    GET_DEPARTMENT_TYPES: serverUrl + GET_DEPARTMENT_TYPES,
    GET_DISCHARGE_TYPE: serverUrl + GET_DISCHARGE_TYPE,
    GET_DISCHARGE_SUMMARY_TEMPLATES: serverUrl + GET_DISCHARGE_SUMMARY_TEMPLATES,
    GET_REFERRALS_LIST: serverUrl + GET_REFERRALS_LIST,
    EXTERNAL_REFERRAL_UPDATE: serverUrl + EXTERNAL_REFERRAL_UPDATE,
    IP_PATIENTS_LIST: serverUrl + IP_PATIENTS_LIST,
    DATA_LOG_LIST: serverUrl + DATA_LOG_LIST,
    ADD_PROVISIONAL_DIAGNOSIS: serverUrl + ADD_PROVISIONAL_DIAGNOSIS,
    GET_DIAGNOSIS_NAME: serverUrl + GET_DIAGNOSIS_NAME

};

export const FloorInfo = '/floor/searchFloor';

