<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">  
      <div class="row page-header">
          <div class="col-md-11 text-center p-0">
           <div>
            <h4 class="text-uppercase bold font-weight-bold orange-color">
             Report Template List
            </h4>
           </div>
          </div>
          <div class="col-md-1 text-center p-2 mt-1">
              <button routerLink="/master-management-landing" class="btn btn-small btn-primary">Back</button>
          </div> 
      </div>
      <div class="row my-3">
        <div class="col-8">
            <form [formGroup]="advSearchForm" 
                  (ngSubmit)="onSearch()" 
                  class="row form-inline" 
                  autocomplete="off">
                <div class="col-5">
                    <div class="form-group row no-gutters">
                      <label class="col-4 modname">Service name</label>
                      <div class="col-8 px-0">
                        <input class="form-control input-textt1 w-100" 
                               formControlName="serviceName"
                               type="text" />
                      </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group row no-gutters">
                      <label class="col-4 modname">Template name</label>
                      <div class="col-8 px-0">
                        <input class="form-control input-textt1 w-100"
                               formControlName="templateName" 
                               type="text" />
                      </div>
                    </div>
                </div>
                <div class="col-2">
                    <button type="submit" class="btn btn-primary  advanced-search-btn">Search</button>
                </div>
            </form>
        </div>
        <div class="col-4 text-right">
            <button class="btn btn-primary" (click)="createTemplate()">Create Template</button>
        </div>
      </div> 
      <div class="row justify-content-center mb-2">
        <div class="col-md-12" >
            <div class="matcontent">
                <mat-table #table [dataSource]="ipSampleCollection" matSort >
    
                    <ng-container matColumnDef="RTM_ID">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <div>S No</div>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element; let i = index;">
                        <div>{{i + 1}}</div>
                    </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="SM_ID">
                    <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                        <div>SM ID</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"  matTooltip="">
                        <div>{{element.SM_ID}} ({{element.SM_ID_NAME}})</div>
                    </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="SERVICE_NAME">
                    <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                        <div>Service Type</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"  matTooltip="">
                        <div>{{element.SERVICE_TYPE_NAME}}</div>
                    </mat-cell>
                    </ng-container>
            
                    <ng-container matColumnDef="CREATE_DATETIME">
                    <mat-header-cell *matHeaderCellDef  mat-sort-header>
                        <div>Created Date</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div>{{element.CREATE_DATETIME | date:"dd MMM yyyy"}}</div>
                    </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="LAST_SAVED_BY">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>Last Saved By</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div>{{element.LAST_SAVED_BY}}</div>
                        </mat-cell>
                        </ng-container>
                    <ng-container matColumnDef="STATUS">
                    <mat-header-cell *matHeaderCellDef  mat-sort-header>
                        <div>Status</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div>{{element.STATUS}}</div>
                    </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="ACTION">
                    <mat-header-cell *matHeaderCellDef  mat-sort-header>
                        <div>ACTION</div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" >
                        <div>
                            <button class="btn btn-primary" (click)="editTemplate(element)" type="button" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-edit"></i></button>
                        </div>
                      
                    </mat-cell>
                    </ng-container>
            
                    <mat-header-row *matHeaderRowDef="ipcolumnsToDisplay; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let element; columns: ipcolumnsToDisplay"></mat-row>
                </mat-table>
        
                <div class="mat-norecords" *ngIf="ipSampleCollection.data.length === 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
                <mat-paginator #ipPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
                </mat-paginator>
            </div>
          </div>
          </div>
      </div>
      <ng-template #templateCreationPopUp let-modal>
        <div class="modal-header">
          <h4 class="modal-title" *ngIf="!existingRecord">Create Template</h4>
          <h4 class="modal-title" *ngIf="existingRecord">Edit Template</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form" (ngSubmit)="submitData(form)">
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <label class="font-weight-bold">Select Service Type<span
                                class="color pl-2 text-danger">*</span></label>
                        <select class="form-control rounded-0 bb" 
                                name="serviceType" 
                                id="serviceType"
                                formControlName="serviceType" 
                                required 
                                (change)="getServiceNames()"
                                [ngClass]="{ 'is-invalid': submitted && f.serviceType.errors }">
                            <option value="">-- Select Service Type --</option>
                            <option [ngValue]="type.SERVICE_TYPE_ID" *ngFor="let type of serviceTypeList">
                                {{type.SERVICE_TYPE_NAME}}</option>
                        </select>

                        <div *ngIf="submitted && f.serviceType.errors" class="invalid-feedback">
                            <div *ngIf="f.serviceType.errors.required">Service Type is required</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <label class="font-weight-bold">Select Service Name</label>
                        <select class="form-control rounded-0 bb" 
                                name="servicename" 
                                id="servicename"
                                formControlName="serviceName"
                                (change)="changeServiceName()" 
                                [ngClass]="{ 'is-invalid': submitted && f.serviceName.errors }">
                            <option value="">-- Select Service Name --</option>
                            <option [ngValue]="name.SM_ID" *ngFor="let name of serviceNamesList">
                                {{name.SERVICE_NAME}}</option>
                        </select>
                        <!-- <div *ngIf="submitted && f.serviceName.errors" class="invalid-feedback">
                            <div *ngIf="f.serviceName.errors.required">Service Name is required</div>
                        </div> -->
                    </div>

                    <div class="col-6 mt-3">
                        <label class="font-weight-bold">Template Name<span
                            class="color pl-2 text-danger">*</span></label>
                        <input type="text" 
                               class="form-control"
                               name="templateName"
                               id="templateName"
                               required
                               formControlName="templateName"
                               [ngClass]="{ 'is-invalid': submitted && f.templateName.errors }" />
                        <div *ngIf="submitted && f.templateName.errors" class="invalid-feedback">
                            <div *ngIf="f.templateName.errors.required">Template Name is required</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4 mb-1 pl-3 font-weight-bold">Template Summary<span class="color pl-2 text-danger">*</span>
            </div>
            <div class="NgxEditor__Wrapper mx-3">
                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                <ngx-editor [style]="{'height':'320px'}" [editor]="editor" formControlName="editorContent" [placeholder]="'Type here...'"
                    [ngModel]="html" ></ngx-editor>

            </div>
            <div *ngIf="(form.controls.editorContent.touched && form.controls.editorContent.invalid) || (showErrorMessage && form.controls.editorContent.invalid)"
                class="bg-warningtxt ml-3 mt-1">Result Summary is required</div> 


            <div class="col-12 mt-3">
                <div class="text-right">
                   
                    <button type="button" (click)="submitData(form)" class="btn btn-primary float-right mr-2 mb-2">
                        Submit </button>
                </div>
            </div>
            </form>
        </div>
      </ng-template>
    </div>
     
