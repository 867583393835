import { Injectable } from '@angular/core';
import {FacadeService} from '../shared/facade.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WARD_API_URLS } from './wardConstants';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class NursestationsService {

  public dischargeSummaryInfo:any;

  public headers: any = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(private facadeService: FacadeService, private http: HttpClient) { }
  
  public doctorsList(data: any): any{
    return this.http.post(WARD_API_URLS.DOCTORS_LIST, data);
  }
    
  public departmentsList(data: any): any{
    return this.http.post(WARD_API_URLS.DEPARTMENTS_LIST, data);
  }
   
  public crossConsultFormDetails(data: any): any{
    return this.http.post(WARD_API_URLS.CROSS_CONSULT_DOCTOR_FORM, data);
  }

  public floorsList(data: any): any {
    return this.http.post(WARD_API_URLS.GET_IP_FLOORS_LIST, data);
  }

  public floorWardList(data: any): any {
    return this.http.post(WARD_API_URLS.GET_FLOOR_INFO, data);
  }

  public wardRoomBedsList(data: any): any {
    return this.http.post(WARD_API_URLS.GET_BEDS_INFO, data);
  }

  public bedConfirmation(inputRequest:any) {
    return this.http.post(WARD_API_URLS.BED_CONFIRMATION, inputRequest);
  }
  public transferBed(inputRequest:any) {
    return this.http.post(WARD_API_URLS.BED_CONFIRMATION, inputRequest);
  }

  public getAvailableBedsCount(inputRequest) {
    return this.http.post(WARD_API_URLS.GET_AVAILABLE_BED_COUNTS, inputRequest);
  }

  public insertDischargeSummary(inputRequest) {
    return this.http.post(WARD_API_URLS.INSERT_DISCHARGE_SUMMARY, inputRequest, {headers: this.headers});
  }

  public updateDischargeSummary(inputRequest) {
    return this.http.post(WARD_API_URLS.UPDATE_DISCHARGE_SUMMARY, inputRequest, {headers: this.headers});
  }

  public getDischargeSummaryInfo(inputRequest) {
    return this.http.post(WARD_API_URLS.GET_DISCHARGE_SUMMARY, inputRequest)
  }

  public dischargeSummaryfinalizeConfirmation(inputRequest) {
    return this.http.post(WARD_API_URLS.DISCHARGE_SUMMARY_FINALIZE, inputRequest)
  }

  public setSelectedBedUserInfo(data) {
    this.dischargeSummaryInfo = data;
  }

  public getSelectedBedUserInfo() {
    return this.dischargeSummaryInfo;
  }

  public getDepartmentTypes()
  {
    return this.http.get(WARD_API_URLS.GET_DEPARTMENT_TYPES)
  }

  public getDischargeType()
  {
    return this.http.get(WARD_API_URLS.GET_DISCHARGE_TYPE)
  }

  /**
   * 
   * @returns get discharge summary templates list
   */
  public getDischargeTemplatesList()
  {
    return this.http.get(WARD_API_URLS.GET_DISCHARGE_SUMMARY_TEMPLATES)
  }

  public getDefaultTemplate()
  {
    return this.http.get(WARD_API_URLS.GET_DEFAULT_TEMPLATE);
  }

  public addProvisionalDaignosis(data: any): any {
    return this.http.post(WARD_API_URLS.ADD_PROVISIONAL_DIAGNOSIS, data);
  }

  public getProvisionalDaignosis(data: any): any {
    return this.http.post(WARD_API_URLS.GET_DIAGNOSIS_NAME, data);
  }

}
