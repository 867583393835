import { value } from './../../constants/commonConstants';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppointmentService} from '../../appointments/appointment.service';
import {
  AppointmentDepartmentsList,
  AppointmentPatientAllCities,
  AppointmentPatientRegistration,
  AppointmentPatientSearch,
  AppointmentSearchDoctor,
  AppointmentViewAppointments,
  ExternalReferralList,
  GettingCityInfoById,
  VisitTypeCategories,
  PayeeType,
  SavePatientVisitDetails,
  AppointmentSingleDoctorInfo,
  AppointmentSearchLocations,
  AppointmentSearchHospitals,
  AddDoctors,
  AddVisitStatus,
  PatientPreviousVisits
} from '../../appointments/appointmentconstants';
import {ViewAppointmentsModel} from '../../shared/appointmentsModels/viewAppointmentsModel';
import {PatientProfile} from '../../shared/appointmentsModels/patientProfile';
import {FormControl, Validators} from '@angular/forms';
import {NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {from, merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, repeat} from 'rxjs/operators';
import {SearchDepartments} from '../../shared/appointmentsModels/searchDepartments';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import { ToastrService } from 'ngx-toastr';
import {DoctorInfoModel} from '../../shared/appointmentsModels/doctorInfoModel';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import {GetDoctorsList, PatientRecordsByVisitID} from '../../billing-module/billingconstants';
import {BillingService} from '../../billing-module/billing.service';
import {SearchHospitals} from '../../shared/appointmentsModels/searchHospitals';
import { WardService } from 'src/app/ward-management/ward.service';
import { ActivatedRoute } from '@angular/router';

enum staticData {
  'hospitalId' = 1
}

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css']
})
export class RegistrationFormComponent implements OnInit {
  dataLoaded = false;
  date = new Date().getTime();
  toDayDate = new Date();
  // selectedId: any = 'Aadhar';
  UMRvalue: number = null;
  nameValue: string = null;
  mobileValue: string = null;
  appointmentsData: ViewAppointmentsModel[];
  patientsData: PatientProfile[] = [];
  patientAge: string;
  patientCityModel: {cityName: string, cityId: number}[] = [];
  patientDobYear = 0;
  patientDobMonth = 0;
  patientDobDate = 0;
  bodyDisplay = 'registration';
  editButton = false;
  addVisitAvailable = false;
  idPlaceholder = 'Enter Id Number';
  formFillPatient: PatientProfile;
  doctorNdepAvailable = false;
  editOption = true;
  duplicateRecords: PatientProfile[];
  idPattern: string;
  existingPatientsAvailability = false;
  doctorData: {docName: string, docId: number, depId: number}[];
  indexSelected: number;
  toggleOnOff = 'nothing';
  visitindex: number;
  maxDate: string;
  minDate: string;
  minYear: number;
  minMonth = 0;
  minDay = 0;
  maxYear: number;
  maxMonth = 11;
  maxDay = 30;
  yearError = false;
  monthError = false;
  addVisitArray: boolean[] = [];
  dateError = false;
  availableUMR: number[];
  existingUmr = false;
  selectedVisitId: number;
  selectedVisitIdArray: {};
  existingPatients: PatientProfile[];
  hospitalData: SearchHospitals[];
  public show: any = false;
  public  showin: any = false;
  public doctorList: any = [];
  public filterDoctorList: any = [];
  public filterDoctorListForVisit: any = [];

  public departmentList: SearchDepartments[];
  public payeeList: any = [];
  public extRefList: any = [];
  public visitTypeList: any = [];
  public patientVisitStatus: any = '';
  public itemDoctor = false;
  public itemExref  = false;
  public itemCity  = false;
  extReferalDoctor: string;
  extReferalList: string[] = [];
  payeeNames: any = [];
  doctorNameFilterKeyword = 'doctorName';
  externalReferenceKeyword = 'doctorName';
  citiesKeyword = 'cityName';
  @ViewChild('dataFillModal') private fillModal: ElementRef;
  @ViewChild('payeename') payeename;
  @ViewChild('duplicateRecordsMod') private duplicate: ElementRef;
  @ViewChild('StatusInfoModal') private statusInfo: ElementRef;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  @ViewChild('existingPatientVisitsModel') private patientVisitsInfo: ElementRef;
  extDoctor$ = new Subject<string>();
  extDoctorClick$ = new Subject<string>();
  nameKeyword = 'PIM_NAME';
  public titleList: any = [
    'B/O.', 'Master.', 'Miss.', 'Mr.', 'Ms.', 'Mrs.'
  ];
  public userInfo: any = {};
  public disableSave: any = false;
  public isView: any = false;
  public enableCrossConsultation = true;
  public doctorsList: any = [];
  public visitID: any;
  public visitIpExists = false;
  public ipExistText = 'Please Discharge the patient to proceed with Add Outpatient Visit';
  public patientTypeInfo: any = true;
  public patientVisits: any = [];
  public saveVisitFormData: any = {
    title: '',
    patientName: '',
    patientID: '',
    patientAge: '',
    dateOfBirth: '',
    mobileNumber: '',
    gender: '',
    doctorId: null,
    doctorName: '',
    departmentId: null,
    departmentName: '',
    patientType: '',
    patientTypeId: 1,
    payeeType: '',
    payeeTypeId: 1,
    payeeNameId: '',
    payeeInfo: '',
    payeeId: null,
    extReferralId: null,
    extReferralName: '',
    visitTypeId: null,
    visitTime: null,
    visitTypeName: '',
    patientVisitId : '',
    patientVisitDate : '',
    patientVisitTime : ''
  };

  public saveVisitFormDetails: any = {
    createdDateTime: '',
    createdID: '',
    departmentID: 0,
    doctorID: 0,
    externalReferalID: 0,
    followUpDate: '',
    hospitalID: 0,
    patientAge: 0,
    patientID: 0,
    patientType: '',
    payeeID: '',
    payeeType: 0,
    vcmId: 0,
    visitCategory: 0,
    visitDate: '',
    visitStatus: 0,
    visitTime: '',
    visitType: 0
  };

  model: PatientProfile = { address: '',
    city: '',
    country: 'India',
    createDateTime: '',
    createId: '',
    dateOfBirth: '',
    emailId: '',
    familyGroupId: '',
    familyGroupRegistration: '',
    gender: '',
    identityIdNr: '',
    identityType: '',
    mobileNumber: '',
    nextOfKin1Mobilenr: null,
    nextOfKin1Name: '',
    nextOfKin1Relation: '',
    nextOfKinMobilenr: '',
    nextOfKinName: '',
    nextOfKinRelation: '',
    patientID: 0,
    patientName: '',
    firstName: '',
    lastName: '',
    permanentHomeAddress: '',
    pincode: '',
    regDate: '',
    stateCountry: '',
    status: 1,
    title: '',
  };
  citiesArray = [];
  textCount: any;
  isShown = false;
  doctorBtn = false;
  // identityNumber: FormControl = new FormControl('', [Validators.required, Validators.pattern('^\\d{4}\\s\\d{4}\\s\\d{4}$')]);
  constructor(private appointmentService: AppointmentService, 
              private toastr: ToastrService,
              private modalService: NgbModal, 
              private tokenService: TokenStorageService,
              private  billingService: BillingService,
              private wardService: WardService) {
    this.userInfo = this.tokenService.getUser();

  }
  ngOnInit(): void {
    // this.gettingAllTheAppointments();
    this.updateOPVisitStatus();
    // this.loadHospitalsList();
    this.loadDoctorList();
    this.loadDepartmentList();
    this.loadExternalReferal();
    
    this.payeeTypeChange(1);
    if (this.appointmentService.appAddVisitPatient) {
      this.loadPatientDetails(this.appointmentService.appAddVisitPatient, null);
      this.appointmentService.appAddVisitPatient = undefined;
    }
  }
  formatter = (result: string) => result.toUpperCase();
  toggle(): any {
    this.show = !this.show;
    this.showin = false;
  }
  toggleShow(): void {
    this.isShown = !this.isShown;
    this.doctorBtn = true;
  }
  assigningPatterns(event): any{
    if (event.target.value === 'Aadhar'){
      this.idPattern = '^\\d{4}\\d{4}\\d{4}$';
      // this.idPlaceholder = '123412341234';
    }
    else if (event.target.value === 'Driving license'){
      this.idPattern = '^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$';
      // this.idPlaceholder = 'AA1220101234567';
    }
    else if (event.target.value === 'Voter ID'){
      this.idPattern =  '^([a-zA-Z]){3}([0-9]){7}?$';
      // this.idPlaceholder = 'ABC1234567';
    }
    else if (event.target.value === 'Pan Card'){
      this.idPattern = '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$';
      // this.idPlaceholder = 'ABCDE1234A';

    }
    else if (event.target.value === 'Passport'){
      this.idPattern = '^(?!^0+$)[a-zA-Z0-9]{6,9}$';
      // this.idPlaceholder = 'Passport Number';
    }
  }
  keyUpEnterFunction(event, idRec): any {
    if (idRec === 'num') {
      this.UMRvalue = null;
      this.nameValue = '';
    }
    else if (idRec === 'id') {
      this.nameValue = '';
      this.mobileValue = '';
      this.textCount = '';
    }
    else if (idRec === 'name') {
      this.mobileValue = '';
      this.UMRvalue = null;
      this.textCount = '';
    }
    if (event.keyCode === 13) {
      this.searchByNumber(event.target, idRec);
    }
  }

  clearAllData(): void {
    this.nameValue = '';
    this.mobileValue = '';
    this.UMRvalue = null;
    this.patientsData = [];
    this.visitID =  undefined;
    this.toggleOnOff = 'nothing';
  }
/** get the payee list */
payeeTypeChange(id): void {
 // this.saveVisitFormData.payeeInfo = '';
  this.wardService.getPayeeTypes(id, 'OP').subscribe((response) => {
    this.payeeNames = response;
   if( this.saveVisitFormData.payeeNameId == ''){
   } else{
    this.saveVisitFormData.payeeNameId = '';
    this.payeename.clear();
   }
   
    // if (id === 1) {
    //   this.saveVisitFormData.payeeInfo = this.payeeNames[0].PIM_NAME;
    // }

  });

}

  selectAutocompleteEvent(item: any, field: string): void {
    if (field === 'doctorname') {
      this.saveVisitFormData.doctorId = item.doctorId;
      this.itemDoctor = true;
      this.doctorChange(item.doctorId);
    }
    else if (field === 'externalReferral') {
      this.saveVisitFormData.extReferralId = item.referralId;
      this.itemExref = true;
    }
    else if (field === 'cities')
    {
      this.model.city = item.cityName;
      this.gettingCityInfo(item.cityId);
      this.itemCity = true;
    }
    else if (field === 'payeeName') {
      //    this.payeeNames.filter(option => option.PIM_NAME.toLowerCase().includes(item.PIM_NAME));
          this.saveVisitFormData.payeeNameId = item.PAYEE_ID;
         // this.payeeTypeChange(item.PAYEE_ID);
        }
  }

  onRemoveSelected(item: any, field: string): void {
    if (field === 'doctorname') {
      this.saveVisitFormData.doctorId = null ;
      this.saveVisitFormData.departmentId = '';
      this.itemDoctor = false;
    }
    else if (field === 'externalReferral') {
      this.saveVisitFormData.extReferralId = '';
      this.itemExref = false;
    }
    else if (field === 'cities') {
      this.model.city = '';
      this.itemCity = false;
    }
  }

  onChangeSearch(val: string): void {
    // return this.doctorsList.filter(option => option.DM_NAME.toLowerCase().includes(val));
    if (val.length < 3) { }
    else {
      this.gettingAllCitiesData(val);
    }
  }

  onFocused(e): void {
    // do something when input is focused
  }

  // gettingAllTheAppointments(): any {
  //   this.dataLoaded = true;
  //   let initialData: ViewAppointmentsModel[];
  //   this.availableUMR = [];
  //   this.appointmentService.getAppointmentViewAppointment(AppointmentViewAppointments).
  //     subscribe( (appointmentsData: ViewAppointmentsModel[]) => {
  //     initialData = appointmentsData.filter( (appointment: ViewAppointmentsModel) => {
  //       this.dataLoaded = false;
  //       return appointment.patientAppointmentDate === this.appointmentService.requiredDateFormat(this.toDayDate);
  //       });
  //     this.appointmentsData = [];
  //     initialData.forEach( eachPatient => {
  //       if (this.availableUMR.indexOf(eachPatient.patientID) < 0) {
  //         this.appointmentsData.push(eachPatient);
  //       }
  //       this.availableUMR.push(eachPatient.patientID);
  //      });
  //   });
  // }
  viewVisitFunction(recPatient): any {

    this.enableCrossConsultation = true;

    this.selectedVisitId = this.selectedVisitIdArray[recPatient.patientName + recPatient.patientID];
    this.doctorsList = [];
    this.billingService.getDoctorsInfo(GetDoctorsList + this.selectedVisitId)
      .subscribe(serResponse => {
        this.doctorsList = serResponse;

        // Set default doctor list before filter apply with existing doctor selection
        // this.filterDoctorList = this.doctorList;
        this.filterDoctorListForVisit = this.doctorList;

        this.doctorsList.forEach(element => {
          const hasobj = this.filterDoctorListForVisit.filter(obj => obj.doctorId === element.doctorID);
          if (hasobj.lenth !== 0) {
            const index = this.filterDoctorListForVisit.indexOf(hasobj[0]);
            this.filterDoctorListForVisit.splice(index, 1);
          }

          if (element.visitType === 'Diagnostics') {
            this.enableCrossConsultation = false;
          }

        });
      });
  }

  updateOPVisitStatus(): any {
    this.appointmentService.updateOPVisitStatus(AddVisitStatus + this.userInfo.hospitalID)
      .subscribe(response => {
        this.patientVisitStatus = response;
        });
  }

  loadHospitalsList(): any {
    this.appointmentService.getAppointmentSearchHospitals(AppointmentSearchHospitals + this.userInfo.locationID)
      .subscribe((data: SearchHospitals[]) => {
        this.hospitalData = data;
      });
  }
  gettingVisitStatus(recPatient): any {
    const searchObj = {
      patientID: recPatient.patientID,
      visitID: 0,
      visitDate: '',
      patientName: '',
      visitStatus: 0
    };
    const toDayVisitDate = this.appointmentService.requiredDateFormat(this.toDayDate);
    let visitArray = [];
    let visitpatient;
    this.billingService.getOutpatientsByVisitId(PatientRecordsByVisitID , searchObj)
      .subscribe(response => {
        if (response.ipInfo.length === 0) {
          if (response.opInfo.length === 0 || response.opInfo[0].hospitalID === this.userInfo.hospitalID)
          {
            this.visitIpExists = false;
          }else{
            this.visitIpExists = true;
            this.ipExistText = 'Visit for this UMR is active in ' + response.opInfo[0].hospitalName + '. Please ask the user to checkout to add Visit in your  location';
          }
        } else {
          this.visitIpExists = true;
          this.ipExistText = 'Please Discharge the patient to proceed with Add Outpatient Visit';
        }

        visitArray = response.opInfo.filter( eachVisit => {
          return eachVisit.visitDate === toDayVisitDate;
        });
        visitpatient = visitArray[0];
        if (visitArray.length > 0) {
          // this.selectedVisitIdArray.push(visitArray[0].visitId);
          this.selectedVisitIdArray[visitpatient.patientName + visitpatient.patientId] = visitpatient.visitId;
        }
        else {
          this.selectedVisitIdArray[recPatient.patientName + recPatient.patientID] = 0;
        }
        // this.addVisitArray.push(visitArray.length > 0);
        // this.selectedVisitIdArray[visitpatient.patientName + visitpatient.patientId][1] = visitArray.length > 0;
        // this.addVisitAvailable = visitArray.length > 0;
        // return visitArray;
      });
  }

  clearSearch(): void {
    this.textCount = '';
    this.nameValue = '';
    this.mobileValue = '';
    this.UMRvalue = null;
    this.patientsData = [];
    this.visitID =  undefined;
  }
  /* numberChange(number){
    this.textCount=this.mobileValue.length;
  } */
  searchByNumber(recNumber, type): any {
    this.toggleOnOff = 'nothing';
    let patientObj = {};
    this.loadDoctorList();
    if (recNumber.value.length > 0){
      this.textCount = recNumber.value.length;
    }else{
      this.textCount = '';
    }
    if (type === 'num' && recNumber.value.length === 10) {
      this.dataLoaded = true;
      patientObj = {
        patientMobile: recNumber.value
      };
      this.gettingSinglepatient(patientObj);

    }
    else if (type === 'id') {
      this.dataLoaded = true;
      patientObj = {
        patientID: recNumber.value
      };
      this.gettingSinglepatient(patientObj);
    }
    else if (type === 'name' && recNumber.value.length >= 3) {
      this.dataLoaded = true;
      patientObj = {
        patientName: recNumber.value
      };
      this.selectedVisitId = null;
      this.addVisitAvailable = false;
      this.gettingSinglepatient(patientObj);
    }
  }
  checkingExistingUMR(receivedData): any {
    const exUmr = {
      patientID: receivedData.value
    };
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, exUmr)
      .subscribe( patients => {
        if (patients.length > 0) {
          this.existingUmr = true;
        }
        else {
          this.existingUmr = false;
        }
      });
  }
  duplicateRecordsModal(regDup): any{
    const serInput = {
      patientMobile:  this.model.mobileNumber
    };
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, serInput)
      .subscribe((patients: PatientProfile[]) => {
        this.duplicateRecords = patients.filter( eachPatient => {
          return (Number(eachPatient.mobileNumber) === Number(this.model.mobileNumber)) &&
            (eachPatient.dateOfBirth === this.model.dateOfBirth)
            && eachPatient.gender === this.model.gender;
        });
        if (this.duplicateRecords.length > 0 && this.model.patientID === 0) {
          this.modalService.open(this.duplicate, {size: 'xl'});
        } else {
          this.regUpdatePatient();
        }
      });
  }
  existingPatientsCheck(data, type, existingPatientsModal): any {
    if (data.value.length > 3 )
    {
      let serInput = {};
      if (type === 'num') {
        serInput = {
          patientMobile: data.value
        };
      } else if (type === 'email' && data.value !== '') {
        serInput = {
          patientEmail: data.value
        };
      }
      if (serInput) {
        this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, serInput)
          .subscribe((patients: PatientProfile[]) => {
            this.existingPatients = patients;
            this.existingPatientsAvailability = patients.length > 0;
            if (patients.length > 0) {
              this.modalService.open(existingPatientsModal, { size: 'lg'});
            }
          });
      }
    }

  }

  extDoctorList = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.extReferalList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  gettingSinglepatient(receivedParam): any {
    this.appointmentsData = undefined;
    this.patientsData = [];
    this.selectedVisitIdArray = [];
    this.addVisitArray = [];
    this.textCount = '';
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, receivedParam)
      .subscribe( patients => {
        this.dataLoaded = false;
        if (patients.length > 0){
        patients.forEach( eachPatient => {
          this.patientsData.push(eachPatient);
          this.gettingVisitStatus(eachPatient);
          this.isShown = false;
          this.doctorBtn = false;
        });
      }else{
        this.toastr.error('No Records Found');
      }
    });
  }

  loadDoctorList(): any {
    this.doctorData = [];
    this.appointmentService.getAppointmentSearchDoctors(AppointmentSearchDoctor + this.userInfo.hospitalID)
      .subscribe( (doctors: SearchDoctors[]) => {
        this.doctorList = doctors;
        this.filterDoctorList = doctors;
        doctors.forEach( doc => {
          this.doctorData.push({docName: doc.doctorTitle + ' ' + doc.doctorName, docId: doc.doctorId, depId: doc.departmentId});
        });
      });
  }

  loadDepartmentList(): any {
    this.departmentList = [];
    this.appointmentService.getAppointmentDepartmentList(AppointmentDepartmentsList + this.userInfo.hospitalID)
      .subscribe( (departments: SearchDepartments[]) => {
        const departmentId: number[] = [];
        departments.forEach( dept => {
          if (departmentId.indexOf(dept.deptId) < 0) {
            this.departmentList.push(dept);
          }
          departmentId.push(dept.deptId);
        });
      });
  }

  loadExternalReferal(): any {
    this.appointmentService.getExternalReferralList(ExternalReferralList)
      .subscribe( referals => {
        this.extRefList = referals;
        this.extReferalList = [];
        this.extRefList.forEach( refList => {
          this.extReferalList.push(refList.doctorName);
        });
      });
  }

  loadVisitType(doctorId: any): any {
    this.visitTypeList = [];
    if (doctorId !== null) {
      const param = VisitTypeCategories + doctorId + '/' + this.userInfo.hospitalID + '/' + this.saveVisitFormData.patientID;
      this.appointmentService.getVisitTypeList(param).subscribe( types => {
        this.visitTypeList = types;
      });
    }
  }

  onChangePayeeType(): any {
    const payeeObj = {
      payeeId: this.userInfo.hospitalID
    };
    this.saveVisitFormData.payeeName = '';
    this.saveVisitFormData.payeeTypeId = null;
    this.appointmentService.getPayeeTypeDetails(PayeeType + '/' + this.userInfo.hospitalID)
      .subscribe( types => {
        this.payeeList = types;
      });
  }
  // Function to get patient profile
  chekingPatientStatus(patient, i, isView): any {
    this.disableSave = false;
    this.isView = isView;
    this.toggleOnOff = 'nothing';
    if (!this.visitIpExists) {
      if (Number(patient.status) === 0) {
        this.modalService.open(this.statusInfo);
        this.gettingPatientData(patient.patientID, i);
      }
      else if (Number(patient.status) === 1) {
        this.loadPatientDetails(patient, i);
      }
    } else {
      this.toggleOnOff = 'visitForm';
    }
  }
  loadPatientDetails(patient: any, ind): any {
    // this.showin = !this.showin;
    // this.show = false;
    this.saveVisitFormData = {
      title: '',
      patientName: '',
      patientID: '',
      patientAge: '',
      dateOfBirth: '',
      mobileNumber: '',
      gender: '',
      doctorId: null,
      doctorName: '',
      departmentId: null,
      departmentName: '',
      patientType: '',
      patientTypeId: 1,
      payeeType: '',
      payeeTypeId: 1,
      payeeName: 'Self Pay',
      payeeId: null,
      vcmId: 0,
      extReferralId: null,
      extReferralName: '',
      visitTypeId: null,
      visitTypeName: ''
    };
    this.indexSelected = undefined;
    if (this.visitindex === ind || this.visitindex === undefined || (this.visitindex !== ind && this.toggleOnOff === 'nothing')) {
      this.toggleOnOff = 'visitForm'; // (this.toggleOnOff === 'visitForm') ? 'nothing' : 'visitForm';
      this.visitindex = undefined;
    }
    this.visitindex = ind;
    this.doctorNdepAvailable = false;
    this.saveVisitFormData.doctorId = null;
    this.saveVisitFormData.doctorName = '';
    this.saveVisitFormData.departmentId = '';
    if (patient.doctorID) {
      this.doctorNdepAvailable = true;
      this.saveVisitFormData.doctorId = patient.doctorID;
      this.saveVisitFormData.doctorName = patient.doctorTitle + ' ' + patient.doctorName;
      this.appointmentService.gettingSingleDoctorInfoById(AppointmentSingleDoctorInfo + patient.doctorID)
        .subscribe( (getDepID: DoctorInfoModel) => {
          this.saveVisitFormData.departmentId = getDepID.deptId;
        });
      // this.saveVisitFormData.departmentId = this.doctorData.find(dep => {
      //   return dep.docId === patient.doctorID;
      // }).depId;

    }
    const requestData = {
      patientID: patient.patientID
    };
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, requestData)
      .subscribe( patients => {
        if (patients !== null && patients.length !== 0) {
          this.saveVisitFormData.title = patients[0].title;
          this.saveVisitFormData.patientName = patients[0].patientName;
          this.saveVisitFormData.patientID = patients[0].patientID;
          this.saveVisitFormData.dateOfBirth = patients[0].dateOfBirth;
          this.saveVisitFormData.mobileNumber = patients[0].mobileNumber;
          this.saveVisitFormData.gender = patients[0].gender;
          this.saveVisitFormData.patientVisitId = patients[0].patientVisitId;
          this.saveVisitFormData.patientVisitDate = patients[0].patientVisitDate;
          this.saveVisitFormData.patientVisitTime = patients[0].patientVisitTime;
          if (patients[0].patientType !== null)
          {
            this.patientTypeInfo = false;
            this.saveVisitFormData.patientTypeId = patients[0].patientType;
            this.saveVisitFormData.patientType = patients[0].patientType;
          }
          else{
            this.saveVisitFormData.patientTypeId = 1;
            this.patientTypeInfo = true;
          }
          this.itemDoctor = (this.saveVisitFormData.patientTypeId === 2) ? true : false;

          this.getAge(this.saveVisitFormData.dateOfBirth);
        }
      });
  }

  setValuestoEmpty(): any {

    this.editButton = false;
    this.editOption = false;
    this.visitIpExists = false;
    this.isView = false;
    this.patientDobDate = 0;
    this.patientDobMonth = 0;
    this.patientDobYear = 0;
    this.disableSave = false;
    this.maxDate = new Date().toISOString().split('T')[0];
    if (this.indexSelected >= 0 && this.indexSelected !== null) {
      this.toggleOnOff = 'nothing';
    }
    this.toggleOnOff = 'registration';
    // (this.toggleOnOff === 'registration') ? 'nothing' : 'registration';
    this.indexSelected = null;
    Object.keys(this.model).forEach( keys => {
      this.model[keys] = '';
      if (keys === 'status') {
        this.model[keys] = 1;
      }
      if (keys === 'patientID') {
        this.model[keys] = null;
      }
      if (keys === 'nextOfKin1Mobilenr') {
        this.model[keys] = null;
      }
    });
    // this.gettingAllCitiesData();

    this.loadDoctorList();

  }

  // Function for new and ild patient registerations and to check duplicate reords
  onSubmit(): any {
    if (this.model.patientID === null || this.model.patientID === 0) {
      this.model.createDateTime = this.appointmentService.requiredDateFormat(this.toDayDate) + ' ' +
        this.toDayDate.toTimeString().substr(0, 8);
      this.model.regDate = this.appointmentService.requiredDateFormat(this.toDayDate);
      this.model.patientID = 0;
    }
    this.model.status = 1;
    this.duplicateRecordsModal(false);
  }
  // Functionality for updating or Registering patient
  regUpdatePatient(): any {
    this.appointmentService.registeringPatientIfUnavailable(AppointmentPatientRegistration, this.model)
      .subscribe( reply => {
        if (reply.errorMessage === null) {
          if (this.model.patientID === 0) {
            this.model.patientID = reply.recordID	;
          }
          this.toggleOnOff = 'nothing';
          if (this.indexSelected === null) {
            this.loadPatientDetails(this.model, null);
          }
          this.toastr.success('Details submitted successfully.');
          this.resetValues();
          // this.toggleOnOff = (this.indexSelected !== null) ? 'nothing' : 'visitForm' ;
        }
        else {
          this.toastr.error('Please Check all the fields or Check Internet Connection');
        }
      });
  }
  resetValues(): any {
    this.maxDate = null;
    this.minDate = null;
    this.minYear = null;
    this.minMonth = 0;
    this.minDay = 0;
    this.maxYear = null;
    this.maxMonth = 11;
    this.maxDay = 30;
  }
  formFillPatientFun(content, patient, index): any {
    this.modalService.open(content);
    this.formFillPatient = patient;
  }

  newPatientWithExisitng(): any {
    this.model = this.formFillPatient;
    this.editOption = true;
    this.editButton = true;
    this.modalService.dismissAll();
    this.formFillPatient = undefined;
  }
  gettingPatientData(patientid, recIndex): any{
    // this.show = true;
    // this.existingPatientsAvailability = false;
    this.editButton = true;
    this.editOption = true;
    this.visitindex = undefined;
    // if (this.indexSelected === recIndex) {
    //   this.toggle();
    //   this.indexSelected = null;
    // }
    // else {
    //   this.indexSelected = recIndex;
    // }
    Object.keys(this.model).forEach( keys => {
      this.model[keys] = '';
      if (keys === 'status') {
        this.model[keys] = 1;
      }
    });
    if (this.indexSelected === recIndex || this.indexSelected === undefined || (this.indexSelected !== recIndex && this.toggleOnOff === 'nothing')) {
      this.toggleOnOff = 'registration'; // (this.toggleOnOff === 'registration') ? 'nothing' : 'registration';
      this.indexSelected = null;
    }
    this.indexSelected = recIndex;
    const requestData = {
      patientID: patientid
    };
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, requestData)
      .subscribe( patients => {
        if (patients !== null && patients.length > 0) {
          this.model = patients[0];
          //this.settingMinnMaxDates();
          this.gettingAge(this.model.dateOfBirth);
        }
      });
   // this.gettingAllCitiesData();
  }
  gettingAllCitiesData(data): any {
    this.patientCityModel = [];
    const cityobj = {
      keyWord: data
    };
    this.appointmentService.gettingAllCitiesForPatientWithInput(AppointmentPatientAllCities, cityobj)
      .subscribe( citiesData => {
        if (citiesData.length > 0) {
          citiesData.forEach( cityItem => {
            this.patientCityModel.push({cityName: cityItem.cscmCityAreaName, cityId: cityItem.cityID});
          });
          this.citiesArray = this.patientCityModel;
        }
      });
  }

  assigningYears(data): any {
    this.patientDobYear = Number(data.target.value);
    if (this.patientDobYear === this.maxYear) {
      this.maxMonth = 0;
      this.maxDay = 0;
      this.minMonth = 0;
      this.minDay = 0;
    }
    else {
      this.minMonth = 0;
      this.minDay = 0;
      this.maxMonth = 11;
      this.maxDay = 30;
    }
    this.gettingDOBFromAge();
  }
  checkingByYear(event): boolean {
    //this.settingMinnMaxDates();
    if (Number(event.target.value) >= this.maxYear) {
      this.yearError = Number(event.target.value) > this.maxYear;
      this.patientDobYear = this.maxYear;
      this.maxMonth = 11;
      this.maxDay = 30;
      this.minDay = 0;
      this.minMonth = 0;
      this.gettingDOBFromAge();
      return false;
    }
    else {
      this.maxMonth = 11;
      this.maxDay = 30;
      this.minDay = 0;
      this.minMonth = 0;
      this.gettingDOBFromAge();
      this.yearError = false;
    }
    if (Number(event.target.value) <= this.minYear) {
      // this.patientDobYear = this.minYear;
      this.yearError = Number(event.target.value) < this.minYear;
      this.minMonth = 0;
      this.minDay = 0;
      this.maxMonth = 11;
      this.maxDay = 30;
      this.gettingDOBFromAge();
      return false;
    }
    this.yearError = false;
    return true;
  }
  checkingByMonth(event): boolean {
    if (Number(event.target.value) >= this.maxMonth) {
      this.yearError = Number(event.target.value) > this.maxMonth;
      if (this.patientDobYear === this.maxYear) {
        this.maxDay = 29;
      }
      this.patientDobMonth = this.maxMonth;
      this.gettingDOBFromAge();
      return false;
    }
    if (Number(event.target.value) <= this.minMonth) {
      this.yearError = Number(event.target.value) < this.minMonth;
      this.patientDobMonth = this.minMonth;
      this.gettingDOBFromAge();
      return false;
    }
    this.yearError = false;
    return true;
  }
  checkingByDay(event): boolean {
    if (Number(event.target.value) >= this.maxDay) {
      this.yearError = Number(event.target.value) > this.maxDay;
      this.patientDobDate = this.maxDay;
      this.gettingDOBFromAge();
      return false;
    }
    if (Number(event.target.value) <= this.minDay) {
      this.yearError = Number(event.target.value) < this.minDay;
      this.patientDobDate = this.minDay;
      this.gettingDOBFromAge();
      return false;
    }
    this.yearError = false;
    return true;
  }
  assigningMonths(data): any {
    this.patientDobMonth = Number(data.target.value);
    this.gettingDOBFromAge();
  }
  assigningDate(data): any {
    this.patientDobDate = Number(data.target.value);
    this.gettingDOBFromAge();
  }
  gettingDOBFromAge(): any {
    const ageToSeconds = ((this.patientDobYear * 365.25) + (this.patientDobMonth * 30.41) + this.patientDobDate) * 86400000;
    const secondsDiff = new Date().getTime() - ageToSeconds;
    this.model.dateOfBirth = this.appointmentService.requiredDateFormat(new Date(secondsDiff));
    // this.appointmentService.apppatientAge = this.patientDobYear + 'Y' + this.patientDobMonth + 'M' + this.patientDobDate + 'D';
  }

  checkInputDate(event): boolean {
    //this.settingMinnMaxDates();
    const recValue = event.target.value;
    const recyear = parseInt(recValue, 10).toString();
    if ((recValue.length >= 10) && (recValue > this.maxDate) && (recyear.length >= 4) ){
      this.model.dateOfBirth = this.maxDate;
      this.gettingAge(this.maxDate);
      this.yearError = true;
      return false;
    }
    if ((recValue.length >= 10) && (recValue < this.minDate)  && (recyear.length >= 4)){
      this.model.dateOfBirth = this.minDate;
      this.gettingAge(this.minDate);
      this.yearError = true;
      return false;
    }
    this.yearError = false;
    return true;
  }
  gettingCityInfo(cityId: any): any {
      const cityobj = {
        cityID: cityId
      };
      this.appointmentService.patientCityInfoById(GettingCityInfoById, cityobj)
        .subscribe(cityInfo => {
          this.model.country = cityInfo[0].cityCountryName;
          this.model.stateCountry = cityInfo[0].cityState;
          this.model.pincode = cityInfo[0].cityPinZip;
        });
  }

  gettingAge(dobValue): any {
    const fullDate = new Date(dobValue);
    const todayDate = new Date();
    this.patientDobYear = todayDate.getFullYear() - fullDate.getFullYear();
    this.patientDobMonth = todayDate.getMonth() - fullDate.getMonth();
    this.patientDobDate = todayDate.getDate() - fullDate.getDate();

    if (this.patientDobMonth <= 0) {
      this.patientDobYear--;
      this.patientDobMonth = (12 + this.patientDobMonth);
    }
    if (this.patientDobDate < 0) {
      this.patientDobMonth--;
      this.patientDobDate = 31 + this.patientDobDate;
      if (this.patientDobDate === 31) {
        this.patientDobMonth++;
        this.patientDobDate = 0;
      }
    }
    if (this.patientDobMonth === 12) {
      this.patientDobYear = this.patientDobYear + 1;
      this.patientDobMonth = 0;
    }
    this.patientAge = this.patientDobYear + 'Y ' + this.patientDobMonth + 'M ' + this.patientDobDate + 'D';
    return this.patientAge;
  }
  checkingMobileNumber(event): boolean {
    if (event.keyCode === 8 || event.keyCode === 46) {
      this.textCount = '';
      return true;
    }
    if (event.target.value.toString().length >= 10 || event.keyCode === 38 || event.keyCode === 40) {
      return false;
    }
    return true;
  }
  changeRadioGender(event): any {
    if (this.model.title === 'B/O.') {
      this.model.gender = event.target.value;
    }
    if (this.model.title === 'Baby') {
      this.model.gender = event.target.value;
    }
  }
  changingGenderFields(): any {
    this.yearError = false;
    // this.dateError = false;
    // this.monthError = false;
    if (this.model.title === 'Master.' || this.model.title === 'Mr.') {
      this.model.gender = 'MALE';
    }
    else if (this.model.title === 'B/O.' || this.model.title === 'Baby') {
      this.model.gender = '';
    }
    else {
      this.model.gender = 'FEMALE';
    }
    //this.model.dateOfBirth = '';
    //this.patientDobYear = 0;
    //this.patientDobMonth = 0;
    //this.patientDobDate = 0;
    //this.settingMinnMaxDates();
  }
  settingMinnMaxDates(): any {
    const presentYear = this.toDayDate.getFullYear();
    const myDate = new Date();
    if (this.model.title === 'B/O.' || this.model.title === 'Baby') {
      this.minDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 2) + 86400000));
      this.maxDate = this.appointmentService.requiredDateFormat(this.toDayDate);
      this.maxYear = 1;
      this.minYear = 0;
    }
    else if (this.model.title === 'Master.' || this.model.title === 'Miss.') {
      this.maxDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 2)));
      this.minDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 13) + 86400000));
      this.maxYear = 12;
      this.minYear = 2;
    }
    else {
      this.maxDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear - 13)));
      this.minDate = null;
      this.maxYear = 150;
      this.minYear = 13;
    }
  }

  getAge(birthdate): any {
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    this.saveVisitFormData.patientAge = this.gettingAge(this.saveVisitFormData.dateOfBirth);
  }

  doctorChange(doctorId: any): any {
    const docObj = this.doctorList.filter(obj => obj.doctorId === doctorId);
    if (docObj.length !== 0) {
      this.saveVisitFormData.departmentId = docObj[0].departmentId;
    }
    this.loadVisitType(doctorId);
  }

  departmentChange(departmentId: any): any {
    if (this.saveVisitFormData.doctorId === null) {
      // debugger;
      this.filterDoctorList = this.doctorList.filter(obj => obj.departmentId === departmentId);
    } else {
      this.filterDoctorList = this.doctorList;
    }
  }
  setPatientType(type: any): any {
    if (type === 1 && this.saveVisitFormData.doctorId === null) {
      this.itemDoctor = false;
    }
    if (type === 2) {
      this.itemDoctor = true;
    }
  }

  submitVisitFormDetails(): any {
    this.disableSave = true;
    const currentDate = new Date();
    const nextDate = new Date();
    let visitDoctorName;
    
    if (this.saveVisitFormData.patientTypeId !== 2) {
      visitDoctorName = this.doctorList.find( eachDoc => {
        return Number(eachDoc.doctorId) === Number(this.saveVisitFormData.doctorId);
      }).doctorName;
    } else {
      // visitDoctorName = this.hospitalData.find( hospital => {
      //  return hospital.hospitalId === this.userInfo.hospitalID;
      // }).hospitalName;
      visitDoctorName =  this.userInfo.hospitalName;
    }

    nextDate.setDate(nextDate.getDate() + 1);

    this.saveVisitFormDetails = {
      createdDateTime: this.formatDate(currentDate) + ' ' + currentDate.toTimeString().substr(0, 8),
      createdID: this.userInfo.username,
      departmentID: this.saveVisitFormData.patientTypeId !== 2 ? this.saveVisitFormData.departmentId : this.userInfo.hospitalID,
      doctorID: this.saveVisitFormData.patientTypeId !== 2 ? this.saveVisitFormData.doctorId : this.userInfo.hospitalID,
      doctorName: visitDoctorName,
      externalReferalID: this.saveVisitFormData.extReferralId,
      followUpDate: currentDate.toTimeString().substr(0, 8),
      hospitalID: this.userInfo.hospitalID,
      patientAge: this.saveVisitFormData.patientAge,
      patientID: this.saveVisitFormData.patientID ,
      patientType: this.saveVisitFormData.patientTypeId,
      // PayeeId is hardcoded beacuse the service to fetch this value is disabled and payeename is static
      payeeID: this.saveVisitFormData.payeeNameId,
      payeeType: this.saveVisitFormData.payeeTypeId,
      vcmId: this.saveVisitFormData.patientTypeId !== 2 ? this.saveVisitFormData.visitTypeId : this.userInfo.hospitalID,
      visitCategory: this.saveVisitFormData.visitTypeId,
      visitDate: this.formatDate(currentDate),
      visitStatus: 0,
      visitTime: currentDate.toTimeString().substr(0, 8),
      visitType: this.saveVisitFormData.visitTypeId
    };
    if (!this.isView) {
      this.appointmentService.savePatientVisitDetails(SavePatientVisitDetails, this.saveVisitFormDetails)
        .subscribe( response => {
          this.payeeTypeChange(1)
          this.disableSave = false;
          this.visitID = undefined;
          if (response.errorMessage === null) {
            this.disableSave = true;
            const ID = response.recordID;
            const vId = ID.split('=');
            if (vId.length === 2) {
              this.visitID = vId[1];
            }
            this.toastr.success('Data submitted successfully.');
            this.bodyDisplay = 'viewoutPatient';
          }
          else {
            this.toastr.error('Please Check all the fields or Check Internet Connection');
          }
        });
    } else {
      const newVisitDocObj = {
        createdDateTime: this.formatDate(currentDate) + ' ' + currentDate.toTimeString().substr(0, 8),
        createdID: this.userInfo.username,
        departmentID: this.saveVisitFormData.patientTypeId !== 2 ? this.saveVisitFormData.departmentId : this.userInfo.hospitalID,
        doctorID: this.saveVisitFormData.patientTypeId !== 2 ? this.saveVisitFormData.doctorId : this.userInfo.hospitalID,
        doctorName: visitDoctorName,
        externalReferalID: this.saveVisitFormData.extReferralId,
        followUpDate: currentDate.toTimeString().substr(0, 8),
        hospitalID: this.userInfo.hospitalID,
        patientAge: this.saveVisitFormData.patientAge,
        patientID: this.saveVisitFormData.patientID ,
        patientType: this.saveVisitFormData.patientTypeId,
        payeeID: 101,
        payeeType: this.saveVisitFormData.payeeTypeId,
        visitCategory: this.saveVisitFormData.visitTypeId,
        visitDate: this.formatDate(currentDate),
        visitStatus: 0,
        visitId: this.selectedVisitId,
        visitTime: currentDate.toTimeString().substr(0, 8),
        visitType: this.saveVisitFormData.visitTypeId
      };
      this.appointmentService.addDoctorToVisit(AddDoctors, newVisitDocObj).subscribe( response => {
        this.payeeTypeChange(1)
        this.disableSave = false;
        this.visitID = undefined;
        if (response.errorMessage === null) {
          this.disableSave = true;
          this.doctorBtn = false;
          const ID = response.recordID;
          const vId = ID.split('=');
          if (vId.length === 2) {
            this.visitID = vId[1];
          }
          this.toastr.success('Data submitted successfully.');
          this.bodyDisplay = 'viewoutPatient';
        }
        else {
          this.toastr.error('Please Check all the fields or Check Internet Connection');
        }
      });
    }

  }

  public formatDate(date): any {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let  day = '' + d.getDate();
    const  year = d.getFullYear() !== null ? d.getFullYear() : 0;

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  public  getPatientPreviousVisits(patientID: any, existingPatientVisitsModel): any {
    const obj = {
      patientId : patientID,
      hospitalId : this.userInfo.hospitalID
    };
    this.patientVisits = [];
    this.appointmentService.getPatientPreviousVisits(PatientPreviousVisits, obj)
      .subscribe( response => {
        if (response.length > 0){
          this.patientVisits = response;
          this.modalService.open(this.patientVisitsInfo, {size: 'lg'});
        }
      });
  }

}
