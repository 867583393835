<fieldset class="legend-block mt-3">
  <legend class="legend-title blue-title mb-0">Patient Info</legend>
  <!-- Patient Info -->
  <div class="row patient-info">
    <div class="col-4 mb-4">
      <div class="row">
        <div class="col-5 font-weight-bold">
          Patient Name
        </div>
        <div class="col">
          <span>: {{patientDetails.PATIENT_NAME}}</span>
        </div>
      </div>
    </div>
    <div class="col-4 mb-4">
      <div class="row">
        <div class="col-6 font-weight-bold">
          UMR No
        </div>
        <div class="col">
          <span>: {{patientDetails.P_ID}}</span>
        </div>
      </div>
    </div>
    <div class="col-4 mb-4">
      <div class="row">
        <div class="col-5 font-weight-bold">
          IP No
        </div>
        <div class="col">
          <span>: {{patientDetails.IP_NR}}</span>
        </div>
      </div>
    </div>
    <div class="col-4 mb-4">
      <div class="row">
        <div class="col-5 font-weight-bold">
          Payee Name
        </div>
        <div class="col">
          <span>: {{patientDetails.PIM_NAME}}</span>
        </div>
      </div>
    </div>
    <div class="col-4 mb-4">
      <div class="row">
        <div class="col font-weight-bold">
          Gender/Age
        </div>
        <div class="col">
          : {{patientDetails.GENDER}}/{{patientDetails.AGE}}
        </div>
      </div>
    </div>
    <div class="col-4 mb-4">
      <div class="row">
        <div class="col-5 font-weight-bold">
          Admitting Doctor Name
        </div>
        <div class="col">
          : {{patientDetails.CONSULT_DOCTOR}}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-5 font-weight-bold">
          Admission Date
        </div>
        <div class="col">
          : {{patientDetails.IP_ADMISSION_DATE | date: 'dd MMM yyyy'}}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col font-weight-bold">
          Admission Time
        </div>
        <div class="col">
         : {{patientDetails.IP_ADMISSION_TIME}}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-5 font-weight-bold">
          Ward/Bed
        </div>
        <div class="col">
          : {{patientDetails.WM_WARD_NAME}} / {{patientDetails.WM_BED_NO}}
        </div>
      </div>
    </div>
  </div>
  <!-- ./END Patient Info -->
</fieldset>

<div class="row">
  <div class="col-5">
    <fieldset class="legend-block mt-3">
      <legend class="legend-title blue-title mb-0">ICD-10 Search</legend>
      <div class="table-body-scroll">
        <table class="table table-hover service-table">
          <thead>
            <tr>
              <th scope="col fweight600">ICD-10 CODE</th>
              <th scope="col fweight600">DISEASE NAME</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th scope="col fweight600">
                <input type="text"
                       [(ngModel)]="searchICDCodeValue" 
                       (ngModelChange)="icdSearch()"
                       class="form-control input-sm" />
              </th>
              <th scope="col fweight600">
                <input type="text"
                       [(ngModel)]="searchDiseaseValue" 
                       (ngModelChange)="icdSearch()"
                       class="form-control input-sm" />
              </th>
            </tr>
          </thead>
          <tbody class="mt-2">
            <tr *ngFor="let icdData of icdList" (click)="addICDCheck(icdData)">
              <td class="services line-break-anywhere">{{icdData.DIAGNOSIS_CODE}}</td>
              <td class="services line-break-anywhere">{{icdData.DESCRIPTION}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>
  </div>
  <div class="col-7">
    <fieldset class="legend-block mt-3">
      <legend class="legend-title blue-title mb-0">Selected ICD-10 Info</legend>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th class="border-top-0 w-35">Name of Disease</th>
              <th class="border-top-0">Diagnosis Code</th>
              <th class="border-top-0">Remarks</th>
              <th class="border-top-0">Select</th>
            </tr>
          </thead>
          <tbody class="mt-2">
            <tr *ngFor="let icdSelectedData of icdSelectedList; let i = index">
              <td>{{ icdSelectedData.DESCRIPTION }}</td>
              <td>{{ icdSelectedData.DIAGNOSIS_CODE }}</td>
              <td>
                <div *ngIf="icdSelectedData.REMARKS ===''">
                  <textarea type="text" [(ngModel)]="remarksContent[i]"></textarea>
                </div>
                <div *ngIf="icdSelectedData.REMARKS !==''">
                  {{ icdSelectedData.REMARKS }}
                </div>
              </td>
              <td><input type="checkbox" 
                name="select" 
                [(ngModel)]="icdSelectedData.isSelected"
                (change)="onSelectICD(icdSelectedData, i)" /></td>
            </tr>            
          </tbody>                  
        </table>
      </div>
    </fieldset>
  </div>
  <div class="col-12">
    <div class="col-12 text-right mt-4">
      <button class="btn brand-btn" 
              [disabled]="icdSelectedList.length === 0"
              (click)="onSubmit()">Submit</button>
    </div>
  </div>
  <div class="col-12">
    <fieldset class="legend-block mt-3">
      <legend class="legend-title blue-title mb-0">List</legend>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th class="border-top-0 w-12">Disease Code</th>
              <th class="border-top-0 w-25">Name of Disease</th>
              <th class="border-top-0 w-15">Remarks</th>
              <th class="border-top-0">Saved By</th>
              <th class="border-top-0">Saved On</th>
              <th class="border-top-0">Last Saved By</th>
              <th class="border-top-0 w-15">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of userICDList, let i = index">
              <td>{{data.ICD10_CODE}}</td>
              <td>{{data.DISEASE_NAME}}</td>
              <td>{{data.REMARKS}}</td>
              <td>{{data.CREATE_ID}}</td>
              <td>{{data.LAST_SAVED_ON | date: 'dd MMM, yyyy'}}</td>
              <td>{{data.LAST_SAVED_BY}}</td>
              <td>
                <a href="javascript:void(0)" 
                   *ngIf="data.STATUS === 'ACTIVE'"
                   class="mr-3"
                   (click)="removeICD(data.ID, i)">Cancel</a>
                <a href="javascript:void(0)"
                   *ngIf="data.STATUS !== 'ACTIVE'">
                   This entry has been cancelled by '{{data.LAST_SAVED_BY}}' on '{{data.LAST_SAVED_ON | date: 'dd MMM, yyyy'}}'
                </a>
              </td>
            </tr>
            <tr *ngIf="userICDList.length === 0">
              <td colspan="5" class="text-center">No Records</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>
  </div>
  
</div>