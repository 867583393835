import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { InpatientService } from '../../viewinpatient-module/inpatient.service';

import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { CommonService } from '../../shared/services/common.service';

@Component({
  selector: 'app-dischargeform-print',
  templateUrl: './dischargeform-print.component.html',
  styleUrls: ['./dischargeform-print.component.css']
})
export class DischargeformPrintComponent implements OnInit, OnChanges {

  public dischargeFormPrintData;

  @Input() ipno: number;
  @Input() umrno: number;

  public userInfo: any = {};
  permission: any;

  constructor(private inpatientService: InpatientService,
              private tokenService: TokenStorageService,
              private commonService: CommonService) {

    this.userInfo = this.tokenService.getUser();

  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

    this.dischargeFormPrint();
    this.getPermissionsForNursingAndSecurity();
  }

  getPermissionsForNursingAndSecurity() {
    const request = {
      hospitalID: this.userInfo.hospitalID,
      hospitalShortName: this.userInfo.hospitalShortName
    }
    this.inpatientService.getPermissions(request).subscribe((response)=>{
      this.permission = response.Requirement;
    });
  }

  dischargeFormPrint(): void {

    const inputRequest = {
      sear_hospitalid: this.userInfo.hospitalID,
      sear_ip_no: this.ipno,
      sear_umr_no : this.umrno,
      admissionPrintStatus: 'N',
      chckoutPrintStatus: 'Y'
    };
    this.inpatientService.admissionFormPrint(inputRequest).subscribe((response) => {

      if (response !== null) {
        this.dischargeFormPrintData = response;
      }

    });

  }

}
