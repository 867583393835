<div class="d-flex">
  <app-sidenav></app-sidenav>
  <div class="container-fluid total-page">

    <div class="row page-header">      
      <!-- <div class="col-1">
        <div class="info-count-box">
          <div class="text-center info-box-number info-box-primary-text mb-0">
            <i class="fa fa-calendar-check"></i>
          </div>
          <div class="text-center info-box-text">Calendar</div>
        </div>
      </div> -->
      
      <div class="col-md-10 text-center">
        <h4 class="text-uppercase bold font-weight-bold orange-color">Add / Post Services</h4>
      </div>
      <div class="col-md-2 text-center" style="line-height: 80px;">
        <button type="button" (click)="navigate()" class="btn border-btn mr-3 px-4">Back to list</button>
      </div>

      <!-- <div class="col-1">
        <div class="info-count-box">
          <div class="text-center info-box-number info-box-primary-text mb-0">
            <span class="fa-stack fa-stack-adjust" data-notification-count="9">
              <i class="fa fa-bell"></i>
            </span>
          </div>
          <div class="text-center info-box-text">Todo</div>
        </div>
      </div> -->

    </div>

    <div class="row mt-4" *ngIf="holdServiceStatus == 'Y'">
      <div class="col-12 m-auto">
        <span class="holdservicealert">Services on Hold for this Patient. Please contact billing department</span>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-11 m-auto mt-4">
        <fieldset class="legend-block mt-3">
          <legend class="legend-title blue-title mb-0">Patient Info</legend>
          <!-- Patient Info -->
          <div class="row patient-info">
            <div class="col-4 mb-4">
              <div class="row">
                <div class="col-5 font-weight-bold">
                  Patient Name
                </div>
                <div class="col">
                  <span>: {{patientInfo?.TITLE}} {{patientInfo.PATIENT_NAME}}</span>
                </div>
              </div>
            </div>
            <div class="col-4 mb-4">
              <div class="row">
                <div class="col-6 font-weight-bold">
                  UMR No
                </div>
                <div class="col">
                  <span>: {{patientUMRNo}}</span>
                </div>
              </div>
            </div>
            <div class="col-4 mb-4">
              <div class="row">
                <div class="col-5 font-weight-bold">
                  IP No
                </div>
                <div class="col">
                  <span>: {{patientInfo.IP_NR}}</span>
                </div>
              </div>
            </div>
            <div class="col-4 mb-4">
              <div class="row">
                <div class="col-5 font-weight-bold">
                  Payee Name
                </div>
                <div class="col">
                  <span>: {{patientInfo.PIM_NAME}}</span>
                </div>
              </div>
            </div>
            <div class="col-4 mb-4">
              <div class="row">
                <div class="col font-weight-bold">
                  Gender/Age
                </div>
                <div class="col">
                  : {{patientInfo.GENDER}}/{{patientInfo.AGE}}
                </div>
              </div>
            </div>
            <div class="col-4 mb-4">
              <div class="row">
                <div class="col-5 font-weight-bold">
                  Admitted/Treating Doctor <span style="color:tomato">*</span>
                </div>
                <div class="col">
                  <div class="input-group">
                    <div>
                      <ng-autocomplete
                        [data]="doctorsList"
                        [searchKeyword]="keyword"
                        [initialValue]="initialValue"
                        placeholder="Enter the Doctor's Name"
                        (selected)='selectEvent($event)'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        class="autocomplete-dropdown sm"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.DM_NAME"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                      
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./END Patient Info -->
        </fieldset>        
        
        
        <div class="row mt-4" *ngIf="bedConfirmationStatus == 'N'">
          <div class="col mt-1">
            <span class="holdservicealert">Please confirm the bed allotment to continue with Add / Post a service</span>
          </div>
        </div>

        <div class="row" *ngIf="holdServiceStatus !== 'Y' || bedConfirmationStatus !== 'N'">
          <div class="col-5">
            <fieldset class="legend-block mt-3">
              <legend class="legend-title blue-title mb-0">Service Search</legend>
              <div class="table-body-scroll">
                <table class="table table-hover service-table">
                  <thead>
                    <tr>
                      <th scope="col fweight600">Name Of Service</th>
                      <th scope="col fweight600">Service Type Name</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th scope="col fweight600">
                        <input type="text" 
                               class="form-control input-sm" 
                               [(ngModel)]="searchServiceNameVal" 
                               (ngModelChange)="servicenameSearch()"
                               [disabled]="bedConfirmationStatus === 'N'" />
                      </th>
                      <th scope="col fweight600">
                        <input type="text" 
                               class="form-control input-sm" 
                               [(ngModel)]="searchServiceType" 
                               (ngModelChange)="serviceTypeSearch()"
                               [disabled]="bedConfirmationStatus === 'N'" />
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mt-2">
                    <tr *ngFor="let service of selectedAllServices.slice(0, 10)" 
                        (click)="addServiceCheck(service, addServiceConfirmPopup)" [ngClass]="{ 'selectedService': service.isSelected }">
                      <td class="services line-break-anywhere">{{service.SERVICE_NAME}}</td>
                      <td class="services line-break-anywhere">{{service.SERVICE_TYPE_NAME}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-7">
            <fieldset class="legend-block mt-3">
              <legend class="legend-title blue-title mb-0">Selected Service Info</legend>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="border-top-0">Name of Service</th>
                      <th class="border-top-0">Category/Dept</th>
                      <th class="border-top-0">Quantity</th>
                      <th class="border-top-0">Select</th>
                    </tr>
                  </thead>
                  <tbody class="mt-2">
                    <tr>
                      <td *ngIf="doctorServicesList.length ===0">No Service Selected</td>
                    </tr>
                    <tr *ngFor="let service of doctorServicesList">
                      <td>{{service.SERVICE_NAME}}</td>
                      <td>{{service.SERVICE_TYPE_NAME}}</td>
                      <td class='alignCenter'>{{service.SERVICE_UNIT_QTY}}</td>
                      <td class='alignCenter'>
                        <input type="checkbox" 
                               name="select" 
                               [(ngModel)]="service.isSelected"
                               (change)="onSelectService(service)" /></td>
                    </tr>
                  </tbody>                  
                </table>

              </div>
            </fieldset>
          </div>
          <div class="col-12">
            <fieldset class="legend-block mt-3">
              <legend class="legend-title blue-title mb-0">Services List</legend>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="border-top-0">Name of Service</th>
                      <th class="border-top-0">Category</th>
                      <th class="border-top-0">Quantity</th>
                      <th class="border-top-0">Date</th>
                      <th class="border-top-0">Remarks</th>
                      <th class="border-top-0">Priority</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td *ngIf="selectedServices.length === 0" colspan="6">No Records</td>
                    </tr>
                    <tr *ngFor="let service of selectedServices; let i = index">
                      <td>{{service.SERVICE_NAME}}</td>
                      <td>{{service.SERVICE_TYPE_NAME}}</td>
                      <td class='alignCenter'>
                        <span *ngIf="service.VARIABLE_UNIT_QTY == '0'">{{service.SERVICE_UNIT_QTY}}</span>
                        <span *ngIf="service.VARIABLE_UNIT_QTY == '1'">
                          <input type="text" 
                            class="form-control form-control-sm" 
                            [value]="service.SERVICE_UNIT_QTY"
                            (keyup)="changeUnitQTY($event, i)"
                            appOnlyNumberdecimal numericType="number" />
                        </span>
                      </td>
                      
                      <td class='alignCenter'>
                        <input type="date" 
                               class="form-control form-control-sm"
                               [(ngModel)]="service.SERVICE_BILL_DATE" 
                               #dateOfBirth="ngModel"
                               [ngClass]="{ 'is-invalid-error': dateOfBirth.invalid }" 
                               value="service.SERVICE_BILL_DATE"
                               [min]="minDate" 
                               [max]="maxDate"
                               onkeydown="return false"
                               placeholder="mm/dd/yyyy" />
                      </td>
                      <td class='alignCenter'>
                        <textarea class="form-control form-control-sm" 
                                  [(ngModel)]="service.REMARKS"
                                  rows="1"></textarea>
                      </td>
                      <td class='alignCenter'>
                        <select [(ngModel)]="service.PRIORITY"
                                class="form-control form-control-sm">
                          <option value="">Select</option>
                          <option value="NORMAL">NORMAL</option>
                          <option value="STAT">STAT</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-12 text-right mt-4">
            <button class="btn brand-btn" 
                    [disabled]="selectedServices.length === 0 || holdServiceStatus == 'Y' || bedConfirmationStatus == 'N' " 
                    (click)="onSubmit()">Submit</button>
          </div>
        </div>
        <div class="py-4">&nbsp;</div>
      </div>
    </div>
    

    <!-- bed confirmation popup -->
    <ng-template #addServiceConfirmPopup let-modal>
      <div class="modal-header">
        <h4 class="modal-title">The selected service already added.</h4>
      </div>
      <div class="modal-body">
        <div>
          <label><b>Date: </b> </label> 
          <span>{{addServiceCheckInfo.SERVICE_BILLED_DATE}}</span>
        </div>
        <div>
          <label><b>Time: </b> </label> 
          <span>{{addServiceCheckInfo.SERVICE_BILLED_TIME}}</span>
        </div>
        <div>
          <label><b>Created by: </b> </label> 
          <span>{{addServiceCheckInfo.CREATE_ID}}</span>
        </div>
        <p>Are you sure you want to add this servie again?</p>
        <button class="btn btn-success mr-2"
                type="button" 
                (click)="addService(addedSelectService)">Yes</button>
        <button class="btn btn-danger" 
                type="button" 
                aria-label="Close" 
                (click)="modal.dismiss('Cross click')">No</button>
      </div>
    </ng-template>
  <!-- ./END bed confirmation popup -->

  </div>
</div>