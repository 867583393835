<div class="container-fluid total-page">

    <div class="row page-header">
      <div class="col-md-11 text-center p-0">
        <div>
          <h4 class="text-uppercase bold font-weight-bold orange-color">
            IP DISCHARGE LIST
            <!-- <span class="descriptiveText" *ngIf="totalPatientsList != ''">
              (Total Patients: {{ totalPatientsList }})
            </span> -->
          </h4>
        </div>
        <!-- <span class="descriptiveText pr-1" *ngIf="selfPayList != 0">
          (Self-Pay Patients: {{ selfPayList }})
        </span>
        <span class="descriptiveText pr-1" *ngIf="insuranceList != 0">
          (Insurance Patients: {{ insuranceList }})
        </span> -->
      </div>
      <div class="col-md-1 text-center p-0" style="line-height: 80px;">
        <button type="button" (click)="backNav()" class="btn border-btn mr-3 px-4">Back</button>
      </div>
    </div>

    <!-- Advanced search block -->
    <div class="row">

     <!-- Advance search -->
     <div class="col-12 mb-1">
        <div class="p-2 mx-3">
          <form [formGroup]="advSearchForm" (ngSubmit)="onSearch()" class="form-inline" autocomplete="off">
            <div class="row advance-search">
              <div class="col-md-3 mb-1">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname mb-3">UMR ID</label>
                  <div class="col-8 ">
                    <input formControlName="UMRID" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with full UMR number</span>
                  </div>
                </div>
              </div>

              <div class="col-md-4 mb-1">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname mb-3 text-left">Patient Name</label>
                  <div class="col-6 px-0">
                    <input formControlName="PATIENTNAME" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with minimum 3 characters</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mb-1">
                <div class="form-group row no-gutters">
                  <label class="col-4 modname mb-3">IP No</label>
                  <div class="col-8 px-0">
                    <input formControlName="IPNO" class="form-control input-textt1 w-100" type="text" />
                    <span class="helptxt">Search with full IP number</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 mb-2">
                <div class="form-group row no-gutters">
                  <label class="col-4 mb-2 modname">From Date </label>
                  <div class="col-8 px-0 mb-2">
                    <input formControlName="FROMDATE" class="form-control input-textt1 w-100" type="date"/>
                  </div>
                </div>
              </div>

              <div class="col-md-4 mb-2">
                <div class="form-group row no-gutters">
                  <label class="col-md-4 mb-2 modname">To Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                  <div class="col-md-6 px-0 mb-2">
                    <input formControlName="TODATE" class="form-control input-textt1 w-100" type="date" />
                  </div>
                </div>
              </div>
              <div class="col-sm-1">
              </div>
              <div class="col-sm-1 mb-2 text-left">
                <button type="submit" class="btn btn-primary btn-sm advanced-search-btn">Search</button>
              </div>
              <div class="col-sm-1 mb-2 text-left">
                <button type="reset" class="btn btn-primary btn-sm advanced-search-btn" (click)="clearSearchParameters()">Reset</button>
              </div>
              <!-- <button type="button" class="btn btn-light float-right light-gray-btn mt-4 px-4">Reset</button> -->
              
            </div>
          </form>
        </div>
      </div>
      <!-- ./END Advance search -->

    </div>
    <!-- ./Advanced search block -->

    <div class="row justify-content-center mb-2">

      <div class="col-md-12">

        <div class="matcontent">
            <mat-table #table [dataSource]="patientsData" matSort>

            <ng-container matColumnDef="IPNUM">
              <mat-header-cell *matHeaderCellDef  mat-sort-header class="center">
                <div>IP NO</div>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element" class="center">
                <div>{{ element.IP_NR }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="NAMEGENDERAGE">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>Name & Gender/Age</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.name }}">
                <div>
                  <a href="javascript:void(0)" (click)="patientQuickInfo(patientQuickInfoPopup, element)">
                    {{ element.TITLE }} {{ element.PATIENT_NAME }}
                  </a><br/>{{ element.GENDER }}/<br>{{element.AGE}}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CONSULTDOCTORWARDBED">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="text-left">Admitting Doctor Name<br>& Ward/Bed</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div *ngIf="element.BED_STATUS != 'C'" class="bednormal"><span style="color: #1661eef5">{{ element.CONSULT_DOCTOR}}</span><br>
                  {{element?.WM_WARD_NAME}}<br>
                  <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span>
                </div>
                <div *ngIf="element.BED_STATUS == 'C'" class="bedconfirmflag">
                  {{ element.CONSULT_DOCTOR }}<br>{{element?.WM_WARD_NAME}}<br>
                  <span *ngIf="element?.WM_BED_NO">({{element?.WM_BED_NO}})</span>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ADMISSIONDATEANDTIME">
              <mat-header-cell *matHeaderCellDef >
                <div class="text-left">Admission Date <br> Admission Time</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_ADMISSION_DATE }}">
                <div>{{ element.IP_ADMISSION_DATE }} <br> {{element.IP_ADMISSION_TIME}}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="REFERRALDOCTOR">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Referral Doctor</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"><!-- matTooltip="{{ element.IP_DISCHARGE_DATE }}" -->
                <div>{{ element.externalRefDoctor }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DISCHARGETYPE">
                <mat-header-cell *matHeaderCellDef>
                  <div class="text-left">Discharge Type</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_DISCHARGE_TYPE }}">
                  <div>{{ element.IP_DISCHARGE_TYPE }}</div>
                </mat-cell>
              </ng-container>
  
              <ng-container matColumnDef="DISCHARGEDDATE">
                <mat-header-cell *matHeaderCellDef>
                  <div class="text-left">Discharge Date</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" matTooltip="{{ element.IP_DISCHARGE_DATE }}">
                  <div>{{ element.IP_DISCHARGE_DATE }}</div>
                </mat-cell>
              </ng-container>

            <!-- <ng-container matColumnDef="LENGTHOFSTAY">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Length of Stay</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" matTooltip="{{ element.description }}">
                <div>{{ element.LENGTH_OF_STAY }}</div>
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="ACTION">
              <mat-header-cell *matHeaderCellDef>
                <div class="text-left">Action</div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="form-inline" style="min-width: 100px;">
                    <!-- <i class="fas fa-microscope p-2 back-hover" title="View Lab Reports" (click)="viewLabReportsPopup(labReportsPopup, element)"></i>
                    <i class="fas fa-clipboard-list p-2 back-hover" title="Discharge Summary" (click)="printDischargeSummary(dischargeSummaryPrintPopup, element)"></i>
                    <i class="fas fa-book-medical p-2 back-hover" title="ICD-10" (click)="actionIcdPop(icdPopup, element)"></i> -->
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                    
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      (click)="viewLabReportsPopup(labReportsPopup, element)" mat-menu-item>
                      <span>View Lab Results</span>
                    </button>
                    <button (click)="printDischargeSummary(dischargeSummaryPrintPopup, element)" mat-menu-item>
                      <span>Discharge Summary </span>
                    </button>
                    <button (click)="actionIcdPop(icdPopup, element)" mat-menu-item>
                      <span>ICD-10</span>
                    </button>
                  </mat-menu>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
            </mat-table>
      
              <div class="mat-norecords" *ngIf="patientsData.data.length == 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
              <!-- <mat-paginator #opPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons 
              style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
              </mat-paginator> -->
        </div>
      </div>
    </div>
  </div>

<!-- Patient QuickInfo Start -->
<ng-template #patientQuickInfoPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Patient Quick Info</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              IP Number
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_NR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Next of Kin / Guardian Name
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinName || patientQuickInformation.NEXT_OF_KIN_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              UMR No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.P_ID}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Relation
            </div>
            <div class="col">
              <span>{{patientQuickInformation.nextOfKinRelatoin}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Mobile No
            </div>
            <div class="col">
              <span>{{patientQuickInformation.mobileNo || patientQuickInformation.NEXT_OF_KIN_MOBILENR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Date
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_DATE}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admission Time
            </div>
            <div class="col">
              <span>{{patientQuickInformation.IP_ADMISSION_TIME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Admitting Doctor
            </div>
            <div class="col">
              <span>{{patientQuickInformation.CONSULT_DOCTOR}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              Treating Doctor / Cross Consultants
            </div>
            <div class="col">
              <span>{{patientQuickInformation?.treatingDoctors?.DM_NAME}}</span>
            </div>
          </div>
        </div>

        <div class="col-4 mt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">
              External Referral
            </div>
            <div class="col">
              <span>{{patientQuickInformation.externalRefDoctor}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
</ng-template>
  <!-- Patient QuickInfo End -->
  
<!-- print discharge summary popup -->
<ng-template #dischargeSummaryPrintPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Discharge Summary</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
    <app-discharge-summary-popup [selectedBed]="dischargeSummaryPatientInfo" [existingRecord]="existingRecord"
    [dischargeSummaryInfo]="existingDischargeSummaryInfo.DISCHARGE_SUMMARY_INFO"></app-discharge-summary-popup>
  </div>
</ng-template>
<!-- ./END print discharge summary popup -->

<!-- ICD Popup -->
<ng-template #icdPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">ICD-10 Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body text-break">
    <app-icd [patientDetails]="icdPatientDetails" (ICDpopupClose)="popupClose()"></app-icd>
  </div>
</ng-template>
<!-- ./ END ICD Popup -->

<ng-template #labReportsPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Lab Results</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body">
  <app-view-lab-reports [patientIPNo]="patientIPNo" [patientUMRNo]="patientUMRNo" (reportsPopupClose)="popupClose()"></app-view-lab-reports>
  </div>
</ng-template>