import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingComponent } from './billing/billing.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import { ViewoutpatientComponent } from './viewoutpatient/viewoutpatient.component';
import { ViewoutpatientbillComponent } from './viewoutpatientbills/viewoutpatientbill.component';
import { EnquiryComponent } from './enquiry/enquiry.component';

import { BillingService } from '../billing-module/billing.service';
import { ViewinpatientModule } from '../viewinpatient-module/viewinpatient.module';

import { SharedModule } from '../shared/shared.module';
import { VaccinationComponent } from './vaccination/vaccination.component';
import { ReportsModule } from '../reports/reports.module';
import { InsuranceInfoComponent } from './insurance-info/insurance-info.component';
import { InsurancePatientsComponent } from './insurance-patients/insurance-patients.component';
import { MaterialModule } from '../shared/material/material.module';
//import { CreditBillSubmissionComponent } from '../credit-bill-submission/credit-bill-submission.component';


const billingRoutes: Routes = [
  { path: 'OutPatient', component: ViewoutpatientComponent },
  { path: 'Enquiry', component: EnquiryComponent },
  { path: 'Vaccination', component: VaccinationComponent},  
  { path: 'insurance-patients', component: InsurancePatientsComponent}  

];

@NgModule({
  declarations: [
    BillingComponent,
    ViewoutpatientComponent,
    ViewoutpatientbillComponent,
    EnquiryComponent,
    VaccinationComponent,
    InsuranceInfoComponent,
    InsurancePatientsComponent
    //CreditBillSubmissionComponent
  ],
  exports: [
    RouterModule,
    ViewoutpatientComponent,
    ViewoutpatientbillComponent,
    EnquiryComponent,
    VaccinationComponent
  ],
  imports: [
    ViewinpatientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule.forChild(billingRoutes),
    SharedModule,
    ReportsModule,
    MaterialModule,
  ]
})
export class BillingModuleModule { }
