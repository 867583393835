<div>
    <div class="col-md-12 mb-5 font-16">
        <p style="text-align: right; padding-right: 25px;"><b>DATE: {{patientInfo.TodayDate}}</b></p>
    </div>
    <br><br><br><br><br>
    <div class="text-center mb-2">
        <h2 style="font-weight: bold;"><u>NO CLAIM CERTIFICATE</u></h2>
    </div>
    <br><br><br>
    <div class="text-center mb-2">
        <h4 style="font-weight: bold;"><u>TO WHOM SO EVER IT MAY CONCERN</u></h4>
    </div>    
    <br><br><br>
    <div class="text-justify col-md-12 font-16">
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to certify that patient <b>{{patientInfo.Pname}}</b> 
            aged <b>{{patientInfo.Age}}</b>, S-W-D-B/O <b>{{patientInfo.SoDoRelation}}</b>. He/She was taken treatment 
            in our hospital on date <b>{{patientInfo.Doa | date: "dd-MMM-yyyy"}}</b>, with vide IP No. <b>{{ipPatientNO}}</b> 
            and he/she was discharged on date <b>{{patientInfo.Dod | date: "dd-MMM-yyyy"}}</b>. 
        </p>
        <br><br>
        <p>
           The total bill amount of <b>Rs. {{patientInfo.GrandTotal}} /-</b> has been paid and settled by the patient to the 
           hospital and is not claimed by the hospital under EHS scheme. This is for your information.
        </p>
        <br><br><br><br><br>
        <p>
            For <b>{{patientInfo.HospitalName}}</b>
        </p>
        <br><br><br><br><br>
        <p>
            <b>AUTHORISED SIGNATORY</b>
        </p>
    </div>
    <div class="col-md-12 mt-3 text-center">
        <button class="col-sm-1 btn btn-primary btn-sm mx-3" (click)="getPrint()">Print</button>
        <button class="col-sm-1 btn btn-primary btn-sm mx-3" (click)="closePopup()">Close</button>
    </div>
</div>

<div id="noclaim-print" style="display: none; margin-top: 1.3in;" class="print-info">

    <div class="col-md-12">
        <div class="col-md-12 mb-5">
            <p style="font-size: 22px; text-align: right; padding-right: 25px;"><b>DATE: {{patientInfo.TodayDate}}</b></p>
        </div>
        <br><br><br><br><br>
        <div class="text-center mb-2">
            <h2 style="font-weight: bold;"><u>NO CLAIM CERTIFICATE</u></h2>
        </div>
        <br><br><br>
        <div class="text-center mb-2">
            <h4 style="font-weight: bold;"><u>TO WHOM SO EVER IT MAY CONCERN</u></h4>
        </div>    
        <br><br><br>
        <div class="text-justify col-md-12">
            <p style="font-size: 22px;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to certify that patient <b>{{patientInfo.Pname}}</b> 
                aged <b>{{patientInfo.Age}}</b>, S-W-D-B/O <b>{{patientInfo.SoDoRelation}}</b>. He/She was taken treatment 
                in our hospital on date <b>{{patientInfo.Doa | date: "dd-MMM-yyyy"}}</b>, with vide IP No. <b>{{ipPatientNO}}</b> 
                and he/she was discharged on date <b>{{patientInfo.Dod | date: "dd-MMM-yyyy"}}</b>. 
            </p>
            <br>
            <p style="font-size: 22px;">
               The total bill amount of <b>Rs. {{patientInfo.GrandTotal}} /-</b> has been paid and settled by the patient to the 
               hospital and is not claimed by the hospital under EHS scheme. This is for your information.
            </p>
            <br><br><br><br><br>
            <p style="font-size: 22px;">
                For <b>{{patientInfo.HospitalName}}</b>
            </p>
            <br><br><br><br><br>
            <p style="font-size: 22px;">
                <b>AUTHORISED SIGNATORY</b>
            </p>
        </div>
    </div>     

</div>