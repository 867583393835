import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from '../inpatient.service';

@Component({
  selector: 'app-no-claim',
  templateUrl: './no-claim.component.html',
  styleUrls: ['./no-claim.component.css']
})
export class NoClaimComponent implements OnInit {

  @Input() ipPatientNO: number;

  userInfo: any;
  patientInfo: any;
  
  constructor( 
    private tokenStorageService: TokenStorageService,
    private inpatientService: InpatientService,
    private printerService: NgxPrinterService,
    private toaster: ToastrService,
    private modalService: NgbModal
  ) {
    this.userInfo = this.tokenStorageService.getUser();
   }

  ngOnInit(): void {
    //
  }

  ngOnChanges(): void {
    this.getPatientInfo();
  }

  getPatientInfo(): void{
    const request = {
      hospitalID: this.userInfo.hospitalID,
      hospitalShortName: this.userInfo.hospitalShortName,
      Ipno: this.ipPatientNO
    }
    this.inpatientService.getCertificatesInfo(request).subscribe((data)=>{
      if(data){
        this.patientInfo = data;
      console.log("Patient Info: ",this.patientInfo);
      } else{
        this.toaster.error(data.message);
      }      
    });
  }

  getPrint(){
    const htmlele = document.getElementById('noclaim-print');
    htmlele.style.display = 'block';
    this.printerService.printDiv('noclaim-print');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);
  }

  closePopup(){
    this.modalService.dismissAll();
  }

}
