<div id="print-prescription-section" *ngIf="permission != 'Y' && dischargeFormPrintData !== undefined" class="print-form">
  <div class="border border-dark rounded">
    <div class="row no-gutters heading-block border-bottom border-dark">

      <div class="mx-auto text-center">
        <h4 class="main-title mb-0 pdf-hospital-heading">{{ dischargeFormPrintData?.HospitalName }}</h4>
        <span style="font-size: 12px; font-weight: normal; display: inline-block; ">{{ dischargeFormPrintData?.HospitalTagLine }}</span>

        <p>{{dischargeFormPrintData?.HospitalAddress}}</p>
        <h3 class="sub-title1  mb-1 font-weight-bold">
          <u>{{dischargeFormPrintData?.TitleLabel}}</u>
        </h3>

      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">UMR No. &nbsp;&nbsp;:</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.UMRNo}}
      </div>
      <div class="col-md-2 font-weight-bold">
        Discharge Date
      </div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.DischargeDate}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Admission No. &nbsp;&nbsp;:</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.IpNo}}
      </div>
      <div class="col-md-2 font-weight-bold">
        Ward Name
      </div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.WardName}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold"></div>
      <div class="col-md-4">

      </div>
      <div class="col-md-2 font-weight-bold">
        Bed No.
      </div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.BedNo}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Patient Name</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.PatientName}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Doctor Name</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.DoctorName}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Department</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.Department}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Total Bill Amount</div>
      <div class="col-md-10 ">{{dischargeFormPrintData?.TotalBillAmount}}</div>
    </div>
    <div class="row p-2 font-weight-bold">
      <div class="col-md-2">Due Authorized By</div>
      <div class="col-md-10">{{dischargeFormPrintData?.DueAuthorizedBy}}</div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Due Amount</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.DueAmount}}
      </div>
      <div class="col-md-2">

      </div>
      <div class="col-md-4 font-weight-bold">
        Signature
      </div>
    </div>
  </div>
  <div class="row p-2">
    <div class="col-md-2 font-weight-bold">Prepared by</div>
    <div class="col-md-4">
      {{dischargeFormPrintData?.PreparedBy}}
    </div>
    <div class="col-md-2">
      Prepared on
    </div>
    <div class="col-md-4 font-weight-bold">
      {{dischargeFormPrintData?.PreparedOn}}
    </div>
  </div>
  <div class="row p-2">
    <div class="col-md-2 font-weight-bold">Printed by</div>
    <div class="col-md-4">
      {{userInfo.username}}
    </div>
    <div class="col-md-2">
      Printed on
    </div>
    <div class="col-md-4 font-weight-bold">
      {{dischargeFormPrintData?.PrintedOn}}
    </div>
  </div>
</div>

<div id="print-prescription-section" *ngIf="permission == 'Y' && dischargeFormPrintData !== undefined" 
class="print-form" style="margin-top: 1.5in;">
  <div>
    <div class="border border-dark rounded">
      <div class="row no-gutters heading-block border-bottom border-dark">
  
        <div class="mx-auto text-center">
          <h4 class="main-title mb-0 pdf-hospital-heading">{{ dischargeFormPrintData?.HospitalName }}</h4>
          <span style="font-size: 12px; font-weight: normal; display: inline-block; ">{{ dischargeFormPrintData?.HospitalTagLine }}</span>
  
          <p>{{dischargeFormPrintData?.HospitalAddress}}</p>
          <h3 class="sub-title1  mb-1 font-weight-bold">
            <u>Check Out Slip copy to Security Staff</u>
            <!-- <u>{{dischargeFormPrintData?.TitleLabel}}</u> -->
          </h3>
  
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">UMR No &nbsp;&nbsp;</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.UMRNo}}
        </div>
        <div class="col-md-2 font-weight-bold">
          Admission No
        </div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.IpNo}}
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Date of Admission</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.AdmissionDate | date:"dd-MMM-yyyy"}}
        </div>
        <div class="col-md-2 font-weight-bold">
          Ward Name
        </div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.WardName}}
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Date of Discharge</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.DischargeDate}}
        </div>
        <div class="col-md-2 font-weight-bold">
          Bed No
        </div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.BedNo}}
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Patient Name</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.PatientName}}</div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Doctor Name</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.DoctorName}}</div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Department</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.Department}}</div>
      </div>
      <!-- <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Total Bill Amount</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.TotalBillAmount}}</div>
      </div> -->
      <div class="row p-2 font-weight-bold">
        <div class="col-md-2">Due Authorized By</div>
        <div class="col-md-10">{{dischargeFormPrintData?.DueAuthorizedBy}}</div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Due Amount</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.DueAmount}}
        </div>
        <div class="col-md-2">
  
        </div>
        <div class="col-md-4 font-weight-bold">
          Signature
        </div>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Prepared by</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.PreparedBy}}
      </div>
      <div class="col-md-2">
        Prepared on
      </div>
      <div class="col-md-4 font-weight-bold">
        {{dischargeFormPrintData?.PreparedOn}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Printed by</div>
      <div class="col-md-4">
        {{userInfo.username}}
      </div>
      <div class="col-md-2">
        Printed on
      </div>
      <div class="col-md-4 font-weight-bold">
        {{dischargeFormPrintData?.PrintedOn}}
      </div>
    </div>
  </div>
  <br><br><br><br><br><br><br>
  <div>
    <div class="border border-dark rounded">
      <div class="row no-gutters heading-block border-bottom border-dark">
  
        <div class="mx-auto text-center">
          <h4 class="main-title mb-0 pdf-hospital-heading">{{ dischargeFormPrintData?.HospitalName }}</h4>
          <span style="font-size: 12px; font-weight: normal; display: inline-block; ">{{ dischargeFormPrintData?.HospitalTagLine }}</span>
  
          <p>{{dischargeFormPrintData?.HospitalAddress}}</p>
          <h3 class="sub-title1  mb-1 font-weight-bold">
            <u>Check Out Slip copy to Nursing Staff</u>
            <!-- <u>{{dischargeFormPrintData?.TitleLabel}}</u> -->
          </h3>
  
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">UMR No &nbsp;&nbsp;</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.UMRNo}}
        </div>
        <div class="col-md-2 font-weight-bold">
          Admission No
        </div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.IpNo}}
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Date of Admission</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.AdmissionDate | date:"dd-MMM-yyyy"}}
        </div>
        <div class="col-md-2 font-weight-bold">
          Ward Name
        </div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.WardName}}
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Date of Discharge</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.DischargeDate}}
        </div>
        <div class="col-md-2 font-weight-bold">
          Bed No
        </div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.BedNo}}
        </div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Patient Name</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.PatientName}}</div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Doctor Name</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.DoctorName}}</div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Department</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.Department}}</div>
      </div>
      <!-- <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Total Bill Amount</div>
        <div class="col-md-10 ">{{dischargeFormPrintData?.TotalBillAmount}}</div>
      </div> -->
      <div class="row p-2 font-weight-bold">
        <div class="col-md-2">Due Authorized By</div>
        <div class="col-md-10">{{dischargeFormPrintData?.DueAuthorizedBy}}</div>
      </div>
      <div class="row p-2">
        <div class="col-md-2 font-weight-bold">Due Amount</div>
        <div class="col-md-4">
          {{dischargeFormPrintData?.DueAmount}}
        </div>
        <div class="col-md-2">
  
        </div>
        <div class="col-md-4 font-weight-bold">
          Signature
        </div>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Prepared by</div>
      <div class="col-md-4">
        {{dischargeFormPrintData?.PreparedBy}}
      </div>
      <div class="col-md-2">
        Prepared on
      </div>
      <div class="col-md-4 font-weight-bold">
        {{dischargeFormPrintData?.PreparedOn}}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-md-2 font-weight-bold">Printed by</div>
      <div class="col-md-4">
        {{userInfo.username}}
      </div>
      <div class="col-md-2">
        Printed on
      </div>
      <div class="col-md-4 font-weight-bold">
        {{dischargeFormPrintData?.PrintedOn}}
      </div>
    </div>
  </div>
</div>

