import { Injectable } from '@angular/core';
import { FacadeService } from '../shared/facade.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inpatient_URL } from './inpatientConstants';
import { Observable, forkJoin, throwError } from 'rxjs/index';

@Injectable({
  providedIn: 'root',
})
export class InpatientService {
  public headers: any = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  constructor(private facadeService: FacadeService, private http: HttpClient) {}

  /**
   * get the patient info by search fields
   */
  public ipPatientInfo(data: any): any{
    return this.http.post(Inpatient_URL.GET_IP_PATIENT_DETAILS, data);
  }

  public receiptsOperationInfo(data: any): any{
    return this.http.post(Inpatient_URL.RECEIPTSOPERATION, data);
  }

  public receiptRefundOrCreditAction(data: any): any{
    return this.http.post(Inpatient_URL.RECEIPTREFUNDORCREDITACTION, data);
  }
  
  public searchPatientById(data: any, parameters: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public patientsList(data: any): any {
    return this.http.post(Inpatient_URL.GET_IP_PATIENTS_LIST, data);
  }
  public consultDoctorsList(data: any): any {
    return this.http.post(Inpatient_URL.GET_CONSULT_DOCTORS_LIST, data);
  }
  public sponsersList(data: any): any {
    return this.http.post(Inpatient_URL.GET_SPONSERS_LIST, data);
  }
  public DischargepatientsList(data: any): any {
    return this.http.post(Inpatient_URL.IPDISCHARGEDPATIENTSLIST, data);
  }
  public patientInfo(data: any): any {
    return this.http.post(Inpatient_URL.GET_PATIENT_INFO, data);
  }
  public viewReceipts(data: any): any {
    return this.http.post(Inpatient_URL.VIEW_RECEIPTS_LIST, data);
  }
  public receiptList(data: any): any {
    return this.http.post(Inpatient_URL.ADD_RECEIPT_DATA, data);
  }
  /**
   * in patient service list
   */
  public patientServiceList(data: any): any {
    return this.http.post(Inpatient_URL.GET_PATIENT_SERVICE_LIST, data);
  }

  public patientServiceChargedEdit(data: any): any {
    return this.http.post(Inpatient_URL.PATIENT_SERVICE_CHARGED_EDIT, data);
  }

  public getDoctorsInfo(data: any): any {
    return this.http.post(Inpatient_URL.GET_DOCTORS_INFO, data);
  }

  public getServiceDetail(data: any): any {
    return this.http.post(Inpatient_URL.GET_SERVICE_DETAILS, data);
  }

  public addServiceCheck(data: any): any {
    return this.http.post(Inpatient_URL.ADD_SERVICE_CHECK, data);
  }

  public getAllServices(data: any): any {
    return this.http.post(Inpatient_URL.GET_ALL_SERVICES, data);
  }

  public addPostService(data: any): any {
    return this.http.post(Inpatient_URL.ADD_POST_SERVICE, data);
  }

  public patientServicesGroupList(inputRequest: any): any {
    return this.http.post(
      Inpatient_URL.GET_PATIENT_SERVICES_GROUP_LIST,
      inputRequest
    );
  }

  public applyDiscountToServices(inputRequest: any): any {
    return this.http.post(
      Inpatient_URL.APPLY_DISCOUNT_TO_SERVICES,
      inputRequest
    );
  }

  public patientBillingSummary(inputRequest): any {
    return this.http.post(Inpatient_URL.BILLING_SUMMARY_INFO, inputRequest);
  }

  public getRemarksList(inputRequest: any): any {
    return this.http.post(Inpatient_URL.GET_REMARKS_LIST, inputRequest);
  }

  public getHoldServiceInfo(inputRequest: any): any {
    return this.http.post(Inpatient_URL.GET_HOLD_SERVICE_INFO, inputRequest);
  }

  public submitRemar(inputRequest: any): any {
    return this.http.post(Inpatient_URL.SUBMIT_REMARK, inputRequest);
  }
  public submitPatientRemar(inputRequest: any): any {
    return this.http.post(Inpatient_URL.SUBMIT_PATIENT_REMARK, inputRequest);
  }
  
  public getPatientServicesTypeLst(inputRequest: any): any {
    return this.http.post(
      Inpatient_URL.GET_PATIENT_SERVICES_TYPE_LIST,
      inputRequest
    );
  }
  public getDiscountAuthorizationNames(): any {
    return this.http.get(Inpatient_URL.GET_DISCOUNT_AUTHORIZATION_NAMES);
  }

  public getDiscountStatusByIPNo(inputRequest: any): any {
    return this.http.post(Inpatient_URL.DISCOUNT_STATUS_BY_IPNO, inputRequest);
  }

  public assignDiscountToAuthorization(inputRequest: any): any {
    return this.http.post(Inpatient_URL.ASSIGN_DISCOUNT_TO_AUTHORIZATION, inputRequest);
  }

  public getpreAuthBtnStatus(inputRequest: any): any {
    return this.http.post(Inpatient_URL.ADDPREAUTHSTATUS, inputRequest);
  }
  public patientPreAuthFormInfo(inputRequest: any): any {
    return this.http.post(Inpatient_URL.PATIENTPREAUTHFORM, inputRequest);
  }
  public getPreAuthList(inputRequest: any): any {
    return this.http.post(Inpatient_URL.GETPREAUTHLIST, inputRequest);
  }
  public ipReceiptDetails(inputRequest: any): any {
    return this.http.post(Inpatient_URL.IPRECEIPT, inputRequest);
  }

  public getDischargeBtnStatus(inputRequest: any): any {
    return this.http.post(Inpatient_URL.DISCHARGEBTNSTATUS, inputRequest);
  }

  public patientDischargeForm(inputRequest: any): any {
    return this.http.post(Inpatient_URL.PATIENTDISCHARGE, inputRequest);
  }

  public holdServiceAction(inputRequest: any): any {
    return this.http.post(Inpatient_URL.MODIFY_HOLD_SERVIOCE, inputRequest);
  }

  public saveFinalizeBill(data: any): any {
    return this.http.post(Inpatient_URL.PATIENT_FINAL_BILL_CLOSURE, data);
  }

  public holdServiceBillingStatus(data: any): any {
    return this.http.post(
      Inpatient_URL.HOLD_SERVICE_FINAL_BILLING_STATUS,
      data
    );
  }

  public paymentDone(data: any): any {
    return this.http.post(Inpatient_URL.PAYMENT_DONE, data);
  }

  public getPendingStatus(data: any): any {
    return this.http.post(Inpatient_URL.PENDING_STATUS, data);
  }
  public admissionFormPrint(data: any): any {
    return this.http.post(Inpatient_URL.ADMISSIONCHECKOUTPRINT, data);
  }
  public finalBillHeader(data: any): any {
    return this.http.post(Inpatient_URL.PATIENTINFO_ATFINALBILL, data);
  }
  public finalBillServicesList(data: any): any {
    return this.http.post(Inpatient_URL.FINAL_BILL_SERVICES_INFO, data);
  }
  public finalBillReceiptsList(data: any): any {
    return this.http.post(Inpatient_URL.FINAL_BILL_RECEIPTS_LIST, data);
  }
  public finalBillFooterDetails(data: any): any {
    return this.http.post(
      Inpatient_URL.FINAL_BILL_FOOTER_ACKNOWLEDGEMENT,
      data
    );
  }
  public creditBillpatientInfo(data: any): any {
    return this.http.post(Inpatient_URL.CREDITBILLPATIENTINFO, data);
  }
   public getCreditBillRemarksList(data: any): any {
     return this.http.post(Inpatient_URL.CREDITBILLREMARKSLIST, data);
   }
   public submitCreditBillRemark(data: any): any {
    return this.http.post(Inpatient_URL.ADDCREDITBILLREMARKS, data);
  }
  public creditBillSubmission(data: any): any {
    return this.http.post(Inpatient_URL.CREDITBILLSUBMISSION, data);
  }

  public dateWiseServiceChargedList(data: any): any {
    return this.http.post(Inpatient_URL.DATEWISESERVICECHARGED, data);
  }

  public patientBillingInsuranceInfo(data: any): any {
    return this.http.post(Inpatient_URL.SUBMITINSURANCEINFO, data);
  }

  public getFinalBillCallsR(inputRequest: any): Observable<any> {

    const response1 = this.http.post(Inpatient_URL.PATIENTINFO_ATFINALBILL, inputRequest);
    const response2 = this.http.post(Inpatient_URL.FINAL_BILL_SERVICES_INFO, inputRequest);
    const response3 = this.http.post(Inpatient_URL.FINAL_BILL_RECEIPTS_LIST, inputRequest);
    const response4 = this.http.post(Inpatient_URL.FINAL_BILL_FOOTER_ACKNOWLEDGEMENT, inputRequest);
    const response5 = this.http.post(Inpatient_URL.BILLING_SUMMARY_INFO, inputRequest);

    return forkJoin([response1, response2, response3, response4, response5]);

  }

  public getTariffs(data: any): any {
    return this.http.post(Inpatient_URL.GETTARIFFS, data);
  }

  public getServicesByIPNO(data: any): any {
    return this.http.post(Inpatient_URL.GETSERVICESBYIPNO, data);
  }

  public patientServiceChargedrowUpdate(data: any): any {
    return this.http.post(Inpatient_URL.PATIENTSERVICECHARGEDROWUPDATE, data);
  }

  public updateFullBreakPrintLabelStatus(data: any): any {
    return this.http.post(Inpatient_URL.UPDATEFULLBREAKPRINTLABELSTATUS, data);
  }

  public updateReceiptPrintLabelStatus(data: any): any {
    return this.http.post(Inpatient_URL.UPDATERECEIPTPRINTLABELSTATUS, data);
  }
  public getcoverLetterData(data: any): any {
    return this.http.post(Inpatient_URL.GETCOVERLETTERDATA, data);
  }
  public getBillingInsuranceInfo(data: any): any {
    return this.http.post(Inpatient_URL.GETINSURANCEDETAILS, data);
  }
  public updateBillingInsuranceInfo(data: any): any {
    return this.http.post(Inpatient_URL.UPDATEINSURANCEINFO, data);
  }
  public insurancePatientInfo(data: any): any{
    return this.http.post(Inpatient_URL.PATIENTINFO_FINALBILL, data);
  }
  public patientInsuranceInfo(data: any): any{
    return this.http.post(Inpatient_URL.GET_PATIENT_INSURANCEINFO, data);
  }
  public addEssentialCertificate(data: any): any{
    return this.http.post(Inpatient_URL.ADD_ESSENTIAL_CERTIFICATE, data);
  }
  public essentialCertificateInfo(data: any): any{
    return this.http.post(Inpatient_URL.ESSENTIAL_CERTIFICATE_INFO, data);
  }
  public getPrimaryInsuranceList(){
    return this.http.get(Inpatient_URL.GET_PRIMARY_INSURANCE_LIST);
  }
  public getBtnStatus(data: any): any{
    return this.http.post(Inpatient_URL.GET_BUTTON_STATUS, data);
  }
  public getAutoCharges(data: any): any{
    return this.http.post(Inpatient_URL.GET_AUTOCHARGES, data);
  }
  public getPatientBedInfo(data: any): any{
    return this.http.post(Inpatient_URL.GET_PATIENT_BED_INFO, data);
  }
  
  public getServiceDoctorsList(data: any): any{
    return this.http.post(Inpatient_URL.GET_SERVICE_DOCTORS_LIST, data);
  }
  public getDoctorWiseServiceSearch(data: any): any{
    return this.http.post(Inpatient_URL.DOCTORWISE_SERVICE_SEARCH, data);
  }
  public getAdmittedWardWiseInfo(data: any): any{
    return this.http.post(Inpatient_URL.GET_ADMITTED_WARD_WISE_DETAILS, data);
  }
  public getPermission(data: any): any{
    return this.http.post(Inpatient_URL.PERMISSIONS_GENUINENESS_AND_NOCLAIM, data);
  }
  public getCertificatesInfo(data: any): any{
    return this.http.post(Inpatient_URL.GET_GENUINENESS_AND_NOCLAIM, data);
  }
  public getPermissions(data: any): any{
    return this.http.post(Inpatient_URL.PERMISSIONS_NURSING_AND_SECURITY_STAFF, data);
  }
  public getDepartments(){
    return this.http.post(Inpatient_URL.GET_DEPARTMENTS_LIST, null);
  }
  public getDepartmentWiseDoctors(data): any{
    return this.http.post(Inpatient_URL.GET_DEPARTMENT_WISE_DOCTORS, data);
  }
  public getPayeenamesList(data): any{
    return this.http.post(Inpatient_URL.GET_PAYEENAMES_INFO, data);
  }
  public getIpBillingData(data: any) {
    return this.http.post(Inpatient_URL.IP_BILLING_INFO, data);
  }
  public getOpBillingData(data: any) {
    return this.http.post(Inpatient_URL.OP_BILLING_INFO, data);
  }
  public getServicesInfo(data: any) {
    return this.http.post(Inpatient_URL.GET_SERVICES_LIST, data);
  }
}
