/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-this-alias */
import { Component, OnInit, AfterViewInit, ViewChild, resolveForwardRef, ElementRef } from '@angular/core';
import { Location } from '@angular/common';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InpatientService } from '../../viewinpatient-module/inpatient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastNoAnimation, ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { NgxPrinterService } from 'ngx-printer';
import { CommonService } from 'src/app/shared/services/common.service';

import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent } from '../../print-management/preview/preview.component';

import { NursestationsService } from '../../ward-management/nursestations.service';
import { WardService } from '../../ward-management/ward.service';
import {SearchDoctors} from 'src/app/shared/appointmentsModels/searchDoctors';
import { SearchDepartments } from 'src/app/shared/appointmentsModels/searchDepartments';
import { DOCTORS_LIST, DEPARTMENTS_LIST } from '../../ward-management/wardConstants';



interface IsericePopupInfo {
  title: string | null;
}

@Component({
  selector: 'app-bill-summary',
  templateUrl: './bill-summary.component.html',
  styleUrls: ['./bill-summary.component.css'],
})
export class BillSummaryComponent implements OnInit {

  @ViewChild('paymentReceipt') paymentReceipt;
  @ViewChild('preAuthPopup') preAuthPopup;
  @ViewChild('viewBill') viewBillPopup;
  @ViewChild('ipAdmissionInformationPopup') private ipAdmissionInformationPopup: ElementRef;
  @ViewChild('updatePayeeSponsorPopup') private updatePayeeSponsorPopup: ElementRef;
  @ViewChild('addReferralPopup') private addReferralPopup: ElementRef;
  @ViewChild('externalReferralPopup') private externalReferralPopup: ElementRef;
  @ViewChild('doctorauto') doctorauto;

  sericePopupInfo: IsericePopupInfo = {
    title: null,
  };
  public patientInfo: any;
  public ipPatientNO = 0;
  public ipPNO = ' ';
  public userInfo: any = {};

  public finalBillPatientInfo: any;
  public finalBillServicesList: any = [];
  public finalBillReceiptsList: any;
  public finalBillFooterAcknowledgement: any;
  public finalBillSummary: any;

  public paymentModeList: any = [];
  public dischargeBtnStatus = false;
  public preAuthBtnStatus = false;

  public bedTransferSubmitBtn = true;
  public completeWardsList: any = [];
  public bedsList: any = [];
  public selectedBed: any = {};
  public transferBedError = '';
  public floorType = 0;
  public roomBedsList: any = [];
  public wardType = false;
  public patientData = false;
  patientDetails: any = null;
  public addDoctorSubmitBtn = true;
  public departmentList: any = [];
  public doctorIdsList: any = [];
  filterDoctorList: any = [];
  doctorData: any = [];
  doctorList: any = [];
  itemDoctor: boolean;
  consultFormErrorMessage = false;
  doctorNameFilterKeyword = 'doctorName';
  inWordsGrossAmount = '';
  inWordsReceiptAmount = '';
  approved_Amount: number;
  grs_amount = 0;
  balance_amount = 0;

  public consultantFormData = {
    doctorId: '',
    departmentId: ''
  };

  public modeCreditVoucher = {
    hopitalID: 1,
    paymentModeID: 1,
    paymentModeName: 'Credit Voucher',
    isDisable: false,
    collectedAmount: '',
    selectedPaymentType: false,
    paymentType: 3
  };
  public modeRefundLater = {
    hopitalID: 1,
    paymentModeID: 1,
    paymentModeName: 'Refund Later',
    isDisable: false,
    collectedAmount: '',
    selectedPaymentType: false,
    paymentType: 2
  };

  public printPrescription = false;
  public collectedAmount: any = 0;

  public transactionId: any = '';
  public bankName: any = '';
  public mobileNumber: any = '';
  public issueDate: any = '';

  billReceiptsTotal: any = '';
  billServiceListTotal: any = 0;
  billReceiptsTotalWords: any = '';

  billServiceListTotalWords: any = '';

  billServiceDiscountStatus = false;
  public optionsbtn = false;

  receiptList = [];
  patientURMNo: number;
  remarkFormData: any = {
    remark: '',
    display_in_print: 'N'
  };
  patientRemarkFormData: any = {
    remark: '',
  };

  servicesList: any = [];
  authorizationNames: any;
  selectedAuthorizationName = '';

  discountStatus = '';
  discountApprove = 'N';
  discountReject = 'N';

  selectedValue = '';
  payeeName = '';

  DischargeFormRadioButtons = [
    { name: 'Normal', id: '1' },
    { name: 'LAMA/DAMA', id: '2' },
    { name: 'Death', id: '3' },
    { name: 'Discharge with HQ', id: '4' },
    { name: 'Admission Cancelled', id: '5'}
  ];

  serviceGroupList: any = [];
  billingSummaryDetails: any = [];
  remarksList: any = [];
  holdServiceInfo: any;

  holdServiceStatus = 'N';
  finalBillStatus = 'N';
  dischargeStatus = 'N';

  paymentDoneBtnStatus = 'N';

  ipReceiptDetailsList;
  ipReceiptAmountInWords;
  printReceiptDiv = false;

  public rolesInfo: any = [];

  returnMode = 'iplist';

  public transferFormData: any = {
    wardListId: '',
    patientBedId: ''
  };

  discountForm: any = {
    discountAmount: '',
    discountPercentage: '',
    netAmount: ''
  };

  discountAuthorization: any = [];

  paybleRoundoffAmount = 0;
  roundoffAmount = 0;
  insuranceList: any = {};
  patientInfoList: any = {};
  walkin = false;
  finalBillServiceList: any = [];

  disableDiscountAmount = false;
  disableDiscountPercentage = false;
  autocharges: any = {};

  constructor(
    private modalService: NgbModal,
    private inpatientService: InpatientService,
    private route: ActivatedRoute,
    private tokenService: TokenStorageService,
    private confirmDialogService: ConfirmDialogService,
    private toastrService: ToastrService,
    private printerService: NgxPrinterService,
    private commonService: CommonService,
    private router: Router,
    private location: Location,
    private nursestationsService: NursestationsService,
    private wardService: WardService,
    public dialog: MatDialog
  ) {

    if (this.tokenService.getToken()) {
      this.rolesInfo = this.tokenService.getUser().rolesInfo;
    }

    this.userInfo = this.tokenService.getUser();

    const id = this.route.snapshot.paramMap.get('id');
    // tslint:disable-next-line:radix
    this.ipPatientNO = parseInt(id);

    this.route.queryParams.subscribe(params => {

      const urmNo = params.umrNumber;
      // tslint:disable-next-line:radix
      this.patientURMNo = parseInt(urmNo);

      this.returnMode = params.mode;
      if (params.mode === 'iplist'){
        this.optionsbtn = true;
      }else{
        this.optionsbtn = false;
      }

    });

  }

  ngOnInit(): void {

    this.getPatientInfo();
    // this.servicesTypeList();
    this.getBillingSummaryInfo();
    this.getRemarksList();
    this.getHoldServiceInfo();

    this.holdServiceBillingStatus();

    this.getPatientServicesTypeLst();

    this.getDischargeBtnStatus();
    this.getPreAuthBtnStatus();
    this.getPaymentModeList();
    this.insurancePatientInfo();
    this.patientInsuranceInfo();
    this.getAutoCharges_Patient();

    // Calculate Round off
    // this.roundOffDifference();

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {

    this.route.queryParams.subscribe(params => {

      const serviceType = params.mode;
      if (serviceType === 'AddReceipts') {
        this.receiptPopup(this.paymentReceipt);
      }
      if (serviceType === 'PreAuth') {
        this.OnpreAuth(this.preAuthPopup);
      }

    });

  }

  getAutoCharges_Patient(){

    const input = {      
      "hid": this.userInfo.hospitalID,
      "Ipno": this.ipPatientNO
    }
    this.inpatientService.getAutoCharges(input).subscribe(
      (response) => {
        this.autocharges = response;
    //   this.toastrService.success(this.autocharges.message);
    // },(err) => {
    //   this.toastrService.error("Autocharges update failed");
    // });
      });
  }

  getPaymentModeList(): any {

    const modes = [
      { id: 1,  name: 'Cash', type: 1 },
      { id: 2, name: 'Credit/Debit Card', type: 1 },
      { id: 3, name: 'Google Pay/Phonee/UPI', type: 1 },
      { id: 4, name: 'Cheque/ECS/DD/NEFT/RTGS', type: 1 },
      { id: 5, name: 'Credit Voucher /Due Amount', type: 3 },
      { id: 6, name: 'Refund Later', type: 2 }
    ];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < modes.length; i++) {
      const data = {
        hopitalID: 1,
        paymentModeID: modes[i].id,
        paymentModeName: modes[i].name,
        isDisable: false,
        collectedAmount: '',
        selectedPaymentType: false,
        paymentType: modes[i].type,
      };
      this.paymentModeList.push(data);
    }

  }

  /**
   * get patient info
   */
  getPatientInfo(): void {
    const inputRequest = {
      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
    };
    this.inpatientService.patientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
        // console.log("patient info", response )
        this.payeeName = this.patientInfo.PIM_NAME;
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  /** get the billing summary info */
  getBillingSummaryInfo(): void {
    try {
      const inputRequest = {
        sear_ip_no: this.ipPatientNO,
        sear_hospitalid: this.userInfo.hospitalID,
      };
      this.inpatientService.patientBillingSummary(inputRequest).subscribe(
        (response) => {
          // console.log("billing summary", response)
          this.billingSummaryDetails = response;
          this.grs_amount = this.billingSummaryDetails.Gross_Amount;
          this.billingSummaryDetails.Balance_Amount_Org = parseFloat(this.billingSummaryDetails.Balance_Amount);

          this.paybleRoundoffAmount = parseFloat(this.billingSummaryDetails.Balance_Amount);

          if (this.billingSummaryDetails) {
            if (this.billingSummaryDetails.Gross_Amount > 0) {
              this.inWordsGrossAmount = this.commonService.toWords(
                this.billingSummaryDetails.Gross_Amount
              );
            }
            /* if (this.billingSummaryDetails.Gross_Amount > 0) {
              this.balance_amount = this.grs_amount - this.approved_Amount;
            } */
          }

          this.getDiscountAuthorizationNames();
        },
        (err) => {
          console.log('HTTP Error', err);
        }
      );
    } catch (error) {}
  }

  /** get remarks list */
  getRemarksList(): void {
    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
    };
    this.inpatientService.getRemarksList(inputRequest).subscribe(
      (response) => {
        console.log(response);
        this.remarksList = response;
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  /** get the service hold info */
  getHoldServiceInfo(): void {
    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
    };
    this.inpatientService.getHoldServiceInfo(inputRequest).subscribe(
      (response) => {
        this.holdServiceInfo = response;
        // console.log('hold service info', response);
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  holdServiceBillingStatus(): void {

    const search = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.holdServiceBillingStatus(search).subscribe((response) => {
      this.holdServiceStatus = response.HoldServiceStatus;
      this.finalBillStatus = response.FinalBillStatus;
      this.dischargeStatus = response.DischargeStatus;
    });

  }
  public insurancePatientInfo() {
    const inputRequest = {
    sear_ip_no: this.ipPatientNO,
    sear_hospitalid: this.userInfo.hospitalID,
    sear_umr_no: this.patientURMNo,
    daywise_break_btn: '0',
    summary_bill_btn: '0',
    insuranceInfo: 'true'
    };
    this.inpatientService.insurancePatientInfo(inputRequest).subscribe((response: any) => {
      console.log(response);
      this.patientInfoList = response;
    },
    );
  }
  patientInsuranceInfo() {
    const request =
    {
      ipNr: this.ipPatientNO
    };
    this.inpatientService.patientInsuranceInfo(request).subscribe((response: any) => {
      console.log(response);
      this.insuranceList = response;
      this.approved_Amount = this.insuranceList.approvalAmount;
      console.log(this.approved_Amount);
    },
    );
  }

  /** get status of pre-auth button */
  getPreAuthBtnStatus(): void {

    const inputRequest = {
      IpNo: this.ipPatientNO,
    };
    this.inpatientService.getpreAuthBtnStatus(inputRequest).subscribe(
      (response) => {
        this.preAuthBtnStatus = response.AddPreAuthStatus;
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  /** pre-auth form popup */
  OnpreAuth(preAuthPopup): void {

    this.modalService.open(preAuthPopup, { size: 'xl', backdrop: 'static' });

  }

  /** get status of discharge button */
  getDischargeBtnStatus(): void {

    const inputRequest = {
      HospitalID: this.userInfo.hospitalID,
      IpNo: this.ipPatientNO,
    };

    this.inpatientService.getDischargeBtnStatus(inputRequest).subscribe(
      (response) => {
        this.dischargeBtnStatus = response.BtnStatus;
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  /** Discharge form popup */
  DischargePopup(dischargePopup): void {

    this.modalService.open(dischargePopup, { size: 'xl', backdrop: 'static' });

  }

  /** get the selected type in discharge form */
  selectedradioButton(data): void {

    this.selectedValue = data.id;

  }

  /** Submitting discharge form */
  submitDischargeForm(): void {

    const inputRequest = {
      IpNo: this.ipPatientNO,
      HospitalID: this.userInfo.hospitalID,
      DischargeType: this.selectedValue,
      CreateName: this.userInfo.username
    };

    this.inpatientService.patientDischargeForm(inputRequest).subscribe(
      (response) => {
        this.toastrService.success(response.message);
        this.holdServiceBillingStatus();
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  /** get the patient services type list */
  getPatientServicesTypeLst(): void {

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
      start: '',
      limit: '',
    };

    this.inpatientService.getPatientServicesTypeLst(inputRequest).subscribe(
      (response) => {
        this.servicesList = response;
        // console.log('services type list', response);
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  getDiscountAuthorizationNames(): void {

    this.inpatientService.getDiscountAuthorizationNames().subscribe(
      (response) => {
        this.authorizationNames = response;
        // console.log('services type list', response);
        this.getDiscountStatusByIPNo();
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  getDiscountStatusByIPNo(): void {

    const inputRequest = {
      sear_ip_no: this.ipPatientNO
    };

    this.inpatientService.getDiscountStatusByIPNo(inputRequest).subscribe(
      (response) => {
        if (response) {

          this.discountAuthorization = response;

          this.roundOffDifference();

          this.discountStatus = response.DISCOUNT_STATUS;

          if (this.discountStatus === 'PENDING') {
            this.discountForm.discountPercentage = response.DISCOUNT_PERCENTAGE;

            const totalAmount = parseFloat(this.billingSummaryDetails.Total_Bill_Amount);
            // const discountAmount = ( totalAmount * this.discountForm.discountPercentage / 100 );

            this.discountForm.discountAmount = response.DISCOUNT_AMOUNT; // Math.ceil(discountAmount);
            this.discountForm.netAmount = Math.round(totalAmount - this.discountForm.discountAmount);
          }

          // console.log('services type list', response);
        } else {
          this.discountStatus = '';
        }
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  saveDiscountAssign(): void {

    let discountStatus = '';
    if (this.discountApprove === 'Y') {
      discountStatus = 'APPROVE';
    }
    if (this.discountReject === 'Y') {
      discountStatus = 'REJECT';
    }

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
      authorization: this.selectedAuthorizationName,
      status: discountStatus
    };
    this.inpatientService.assignDiscountToAuthorization(inputRequest).subscribe(
      (response) => {
        this.toastrService.success(response.message);

        // Add remarks
        const remarkInputRequest = {
          sear_ip_no: this.ipPatientNO,
          sear_hospitalid: this.userInfo.hospitalID,
          create_name: this.userInfo.username,
          remarks: response.message,
          display_in_print: 'Y'
        };
        this.saveRemarks(remarkInputRequest);

        // Reset service type grid
        this.getPatientServicesTypeLst();

        // Reset Summary
        this.getBillingSummaryInfo();

        // Reset Discount status
        this.getDiscountStatusByIPNo();

        // Calculate Round off
        // this.roundOffDifference();

      },
      (err) => {
        console.log('HTTP Error', err);
        this.toastrService.error('Something went wrong..!');
      }
    );

  }

  changeDiscountApprove(event): void {

    this.discountApprove = 'N';
    this.discountReject = 'N';

    if (event.target.checked) {
      this.discountApprove = 'Y';
    } else {
      this.discountApprove = 'N';
    }

  }

  changeDiscountReject(event): void {

    this.discountApprove = 'N';
    this.discountReject = 'N';

    if (event.target.checked) {
      this.discountReject = 'Y';
    } else {
      this.discountReject = 'N';
    }

  }

  changeDisplayInPrintout(event): void {
    if (event.target.checked) {
      this.remarkFormData.display_in_print = 'Y';
    } else {
      this.remarkFormData.display_in_print = 'N';
    }
  }

  /** submit the remark form */
  submitRemar(): void {

    if (this.remarkFormData.remark === '') {
      this.toastrService.error('Please enter remarks');
      return;
    } else {
      if (this.remarkFormData.remark.length < 10) {
        this.toastrService.error('Please enter at least 10 characters');
        return;
      }
    }

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
      create_name: this.userInfo.username,
      remarks: this.remarkFormData.remark,
      display_in_print: this.remarkFormData.display_in_print
    };
    this.saveRemarks(inputRequest);

  }

  saveRemarks(inputRequest): void {

    this.inpatientService.submitRemar(inputRequest).subscribe(
      (response) => {
        // this.remarksList = response;
        this.getRemarksList();
        this.remarkFormData.remark = '';
        this.toastrService.success('Remarks saved successfully');
      },
      (err) => {
        console.log('HTTP Error', err);
        this.remarkFormData.remark = '';
        this.toastrService.error('Something went wrong..!');
      }
    );

  }
 /** submit the patient remark form */
 submitPatientRemar(): void {

  if (this.patientRemarkFormData.remark === '') {
    this.toastrService.error('Please enter remarks');
    return;
  } else {
    if (this.patientRemarkFormData.remark.length < 10) {
      this.toastrService.error('Please enter at least 10 characters');
      return;
    }
  }

  const inputRequest = {
    sear_ip_no: this.ipPatientNO,
    create_name: this.userInfo.username,
    remarks: this.patientRemarkFormData.remark,
  };
  this.inpatientService.submitPatientRemar(inputRequest).subscribe(
    (response) => {
      this.patientRemarkFormData.remark = '';
      this.toastrService.success('Remarks saved successfully');
    },
    (err) => {
      console.log('HTTP Error', err);
      this.patientRemarkFormData.remark = '';
      this.toastrService.error('Something went wrong..!');
    }
  );

}
  billingSericePopup(billingSericePopupBlock, serviceInfo): void {

    this.sericePopupInfo.title = serviceInfo.Service_typename;

    const inputRequest = {
      start: '',
      limit: '',
      sear_ip_no: this.ipPatientNO,
      service_type_id: serviceInfo.Service_typeid,
    };

    this.inpatientService.patientServicesGroupList(inputRequest).subscribe(
      (response) => {
        this.serviceGroupList = response;
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

    this.modalService.open(billingSericePopupBlock, { size: 'xl', backdrop: 'static' });

  }

  receiptPopup(paymentReceipt): void {
    this.modalService.open(paymentReceipt, { size: 'xl', backdrop: 'static' });
  }

  viewReceipts(): void {

    const queryParam = { umrNumber: this.patientURMNo, mode: this.returnMode };
    this.router.navigate(['/' + 'ViewReceipt' + '/' + this.ipPatientNO], { queryParams: queryParam });

  }
  viewBillPopUp(viewBill): void {
     this.modalService.open(viewBill, { size: 'xl', backdrop: 'static' });


  }
  finalBillprint(typeOfBill): any {

    // let inputRequest: any;

    // if (typeOfBill === 'Summary') {
    //   inputRequest = {
    //     sear_ip_no: this.ipPatientNO,
    //     sear_hospitalid: this.userInfo.hospitalID,
    //     sear_umr_no: this.patientURMNo,
    //     daywise_break_btn: 0,
    //     summary_bill_btn: 1
    //   };
    // } else if (typeOfBill === 'Full Break') {
    //   inputRequest = {
    //     sear_ip_no: this.ipPatientNO,
    //     sear_hospitalid: this.userInfo.hospitalID,
    //     sear_umr_no: this.patientURMNo,
    //     daywise_break_btn: 0,
    //     summary_bill_btn: 0
    //   };
    // } else {
    //   inputRequest = {
    //     sear_ip_no: this.ipPatientNO,
    //     sear_hospitalid: this.userInfo.hospitalID,
    //     sear_umr_no: this.patientURMNo,
    //     daywise_break_btn: 1,
    //     summary_bill_btn: 0
    //   };
    // }

    // future scope
    // this.getFinalBillData(inputRequest);

    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '80%',
      height: '95%',
      data: {
        mode: 'summarybill',
        ipno: this.ipPatientNO,
        umrno: this.patientURMNo,
        typeofbill: typeOfBill
      }
    });

  }

  interimBillPrint(typeofbill): any{
    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
      sear_umr_no: this.patientURMNo,
      daywise_break_btn: 0,
      summary_bill_btn: 0
      };
    this.getFinalBillInfo(inputRequest);
  }

  // future scope
  getFinalBillData(inputRequest): void {

    this.inpatientService.getFinalBillCallsR(inputRequest).subscribe(result => {

      // Header
      if (result[0] !== null) {
        this.finalBillPatientInfo = result[0];
        if (this.finalBillPatientInfo.Referral === 'Walk-In'){
          this.walkin = true;
        }
      }

      // Services
      if (result[1] !== null) {

        this.billServiceDiscountStatus = false;

        const totalCountArray = [];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < result[1].length; i++) {

          if (!(this.finalBillPatientInfo.SummaryBillBtn)) {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < result[1][i].SubCategory.length; j++) {

              totalCountArray.push(result[1][i].SubCategory[j]);
              if ( parseFloat(result[1][i].SubCategory[j].Discount) > 0 ) {
                this.billServiceDiscountStatus = true;
              }

            }
          } else { /** If it is summary bill, we will not be having sub catagories */

            totalCountArray.push(result[1][i]);
            if ( result[1][i].DiscountPercent !== '0') {
              this.billServiceDiscountStatus = true;
            }

          }

        }
        console.log(totalCountArray);
        // console.log("getFinalBillServicesList", this.billServiceDiscountStatus);
        // tslint:disable-next-line:max-line-length
        this.billServiceListTotal = totalCountArray.map((t) => this.finalBillPatientInfo.SummaryBillBtn ?  parseInt(t.netAmount) : parseInt(t.Rate)).reduce((a: number, value: number) => a + value, 0);

        this.billServiceListTotalWords = this.commonService.toWords(
          this.billingSummaryDetails.Total_Bill_Amount
        );

        this.finalBillServicesList = result[1];
        console.log(this.finalBillServicesList);

      }

      // Receipts
      if (result[2] !== null) {
        this.printPrescription = true;
        this.finalBillReceiptsList = result[2];

        this.billReceiptsTotal = this.finalBillReceiptsList
          .map((t) => parseInt(t.RecptAmt))
          .reduce((a: number, value: number) => a + value, 0);

        this.billReceiptsTotal = Math.round(this.billReceiptsTotal);

        this.billReceiptsTotalWords = this.commonService.toWords(
          this.billReceiptsTotal
        );
        this.billReceiptsTotal = this.billReceiptsTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      // Footer
      if (result[3] !== null) {
        this.finalBillFooterAcknowledgement = result[3];
        // console.log(
        //   'finalBillFooterAcknowledgement',
        //   this.finalBillFooterAcknowledgement
        // );
      }

      // Bill Summary
      if (result[4] !== null) {
        this.finalBillSummary = result[4];
      }

    },
    error => {
      console.log('Unable to load');
    },
    () => {

      // All the API calls are completed here.
      // codes should be executed after the completion of all API calls
      // console.log("I am completed");
      this.printPrescription = true;

      setTimeout(() => {
        this.printerService.printDiv('print-prescription-section');
      }, 2000);

      setTimeout(() => {
        this.printPrescription = false;
      }, 3000);

    });

  }
  getFinalBillInfo(inputRequest): void{
    this.inpatientService.getFinalBillCallsR(inputRequest).subscribe(result => {

      // Header
      if (result[0] !== null) {
        this.finalBillPatientInfo = result[0];
        if (this.finalBillPatientInfo.Referral === 'Walk-In'){
          this.walkin = true;
        }
      }

      // Services
      if (result[1] !== null) {

        this.billServiceDiscountStatus = false;

        const totalCountArray = [];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < result[1].length; i++) {

          if (!(this.finalBillPatientInfo.SummaryBillBtn)) {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < result[1][i].SubCategory.length; j++) {

              totalCountArray.push(result[1][i].SubCategory[j]);
              if ( parseFloat(result[1][i].SubCategory[j].Discount) > 0 ) {
                this.billServiceDiscountStatus = true;
              }

            }
          } else { /** If it is summary bill, we will not be having sub catagories */

            totalCountArray.push(result[1][i]);
            if ( result[1][i].DiscountPercent !== '0') {
              this.billServiceDiscountStatus = true;
            }

          }

        }
        console.log(totalCountArray);
        // console.log("getFinalBillServicesList", this.billServiceDiscountStatus);
        // tslint:disable-next-line:max-line-length
        this.billServiceListTotal = totalCountArray.map((t) => this.finalBillPatientInfo.SummaryBillBtn ?  parseInt(t.netAmount) : parseInt(t.Rate)).reduce((a: number, value: number) => a + value, 0);

        this.billServiceListTotalWords = this.commonService.toWords(
          this.billingSummaryDetails.Total_Bill_Amount
        );

        this.finalBillServicesList = result[1];
        console.log(this.finalBillServicesList);

      }

      // Receipts
      if (result[2] !== null) {
        this.printPrescription = false;
        this.finalBillReceiptsList = result[2];

        this.billReceiptsTotal = this.finalBillReceiptsList
          .map((t) => parseInt(t.RecptAmt))
          .reduce((a: number, value: number) => a + value, 0);

        this.billReceiptsTotal = Math.round(this.billReceiptsTotal);

        this.billReceiptsTotalWords = this.commonService.toWords(
          this.billReceiptsTotal
        );
        this.billReceiptsTotal = this.billReceiptsTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      // Footer
      if (result[3] !== null) {
        this.finalBillFooterAcknowledgement = result[3];
        // console.log(
        //   'finalBillFooterAcknowledgement',
        //   this.finalBillFooterAcknowledgement
        // );
      }

      // Bill Summary
      if (result[4] !== null) {
        this.finalBillSummary = result[4];
      }

    }/*
    error => {
      console.log('Unable to load');
    },
    () => {

      // All the API calls are completed here.
      // codes should be executed after the completion of all API calls
      // console.log("I am completed");
      this.printPrescription = true;

      setTimeout(() => {
        this.printerService.printDiv('print-prescription-section');
      }, 2000);

      setTimeout(() => {
        this.printPrescription = false;
      }, 3000); */

    );
  }

  holdServiceUndo(): void {
    const currentObj = this;

    this.confirmDialogService.confirmThis(
      'Are you sure you want to change this action?',
      () => {
        // console.log('Yes');
        this.holdServiceInfo.hold_service_status = 'N';
        currentObj.holdServiceAction();
      },
      () => {
        // console.log('No');
      }
    );
  }

  holdService(event, serviceStatus): void {
    if (event.target.checked) {
      this.holdServiceInfo.hold_service_status = 'Y';
    } else {
      this.holdServiceInfo.hold_service_status = 'N';
    }

    this.holdServiceAction();
  }

  holdServiceAction(): void {
    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
      hold_service_status: this.holdServiceInfo.hold_service_status,
      create_name: this.userInfo.username,
    };

    this.inpatientService.holdServiceAction(inputRequest).subscribe(
      (response) => {
        console.log('holdServiceAction', response);
        this.getHoldServiceInfo();
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  finalizeBillPopup(finalizeBill): void {
    this.sericePopupInfo.title = 'Finalize Bill';

    const currentObj = this;

    const balanceAmount = this.billingSummaryDetails.Balance_Amount;

    if (Number(balanceAmount) === 0) {
      this.confirmDialogService.confirmThis(
        'Do you want Finalize Bill ?',
        () => {
          // console.log('Yes');
          currentObj.saveFinalizeBillZero();
        },
        () => {
          // console.log('No');
        }
      );
    } else {
      this.resetFinalizeBillPopup();
      this.modalService.open(finalizeBill, { size: 'xl', backdrop: 'static' });
    }
  }

  finalizeBillPopupClose(): void {
    this.modalService.dismissAll();
    this.holdServiceBillingStatus();

    // Reset Summary
    this.getBillingSummaryInfo();
  }

  resetFinalizeBillPopup(): void {

    this.transactionId = '';
    this.bankName = '';
    this.mobileNumber = '';
    this.issueDate = '';

    this.paymentModeList.forEach((element) => {
      element.isDisable = true;
      element.collectedAmount = '';
      element.selectedPaymentType = false;
    });

  }

  public isPaymentSelected(): any {
    const hasObj = this.paymentModeList.filter(
      (obj) => obj.paymentModeID !== 1 && obj.paymentModeID !== 5 && obj.paymentModeID !== 6 && obj.selectedPaymentType
    );
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }

  public isPaymentChequeSelected(): any {
    const hasObj = this.paymentModeList.filter(
      (obj) => obj.paymentType === 1 && obj.paymentModeID === 4 && obj.selectedPaymentType
    );
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }

  public clearAmount(paymentType: any): void {
    paymentType.collectedAmount = '';
  }

  numberOnlyVal(event: any, data: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changePaymentMode(event, paymentMode): void {

    this.paymentModeList.forEach((element) => {
      element.isDisable = true;
      element.collectedAmount = '';
      element.selectedPaymentType = false;
    });

    if (event.target.checked) {
      paymentMode.selectedPaymentType = true;
      paymentMode.isDisable = false;

      if (paymentMode.paymentType === 2) {
        paymentMode.collectedAmount = this.billingSummaryDetails.Balance_Amount * -1;
      }

    }

    // const hasObj = this.paymentModeList.filter(
    //   (obj) => obj.selectedPaymentType === true
    // );
    // if (hasObj.length > 0) {
    //   this.modeCreditVoucher.isDisable = true;
    //   this.modeCreditVoucher.selectedPaymentType = false;

    //   this.modeRefundLater.isDisable = true;
    //   this.modeRefundLater.selectedPaymentType = false;
    // } else {
    //   this.modeCreditVoucher.isDisable = false;
    //   this.modeCreditVoucher.selectedPaymentType = false;

    //   this.modeRefundLater.isDisable = false;
    //   this.modeRefundLater.selectedPaymentType = false;
    // }

  }

  changeCreditVoucher(event): void {
    if (event.target.checked) {
      this.paymentModeList.forEach((element) => {
        element.isDisable = true;
        element.collectedAmount = '';
        element.selectedPaymentType = false;
        element.paymentType = 1;
      });
    } else {
      this.paymentModeList.forEach((element) => {
        element.isDisable = false;
        element.collectedAmount = '';
        element.selectedPaymentType = false;
        element.paymentType = 1;
      });
    }
  }

  changeRefundLater(event): void {
    if (event.target.checked) {
      this.paymentModeList.forEach((element) => {
        element.isDisable = true;
        element.collectedAmount = '';
        element.selectedPaymentType = false;
        element.paymentType = 1;
      });
    } else {
      this.paymentModeList.forEach((element) => {
        element.isDisable = false;
        element.collectedAmount = '';
        element.selectedPaymentType = false;
        element.paymentType = 1;
      });
    }
  }

  public checkCollectAmount(): any {
    let collectAmount = 0;
    let balanceAmount = 0;

    if (Number(this.billingSummaryDetails.Balance_Amount) < 0) {
      balanceAmount = Number(this.billingSummaryDetails.Balance_Amount) * -1;
    }
    if (Number(this.billingSummaryDetails.Balance_Amount) > 0) {
      balanceAmount = Number(this.billingSummaryDetails.Balance_Amount);
    }

    const hasObj = this.paymentModeList.find((obj) => obj.selectedPaymentType === true);
    if (hasObj) {

      if (hasObj.paymentType === 1) {
        collectAmount = Number(hasObj.collectedAmount);
      }

      if (hasObj.paymentType === 3) {
        collectAmount = Number(this.billingSummaryDetails.Balance_Amount);
      }

      if (hasObj.paymentType === 2) {
        collectAmount = Number(this.billingSummaryDetails.Balance_Amount) * -1;
      }

    }

    // this.paymentModeList.forEach((element) => {
    //   collectAmount += Number(element.collectedAmount);
    // });

    // if (this.modeCreditVoucher.selectedPaymentType) {
    //   collectAmount = Number(this.billingSummaryDetails.Balance_Amount);
    // }

    // if (this.modeRefundLater.selectedPaymentType) {
    //   collectAmount = Number(this.billingSummaryDetails.Balance_Amount) * -1;
    // }
    // console.log('collect', collectAmount);
    // console.log('balanceAmount', balanceAmount);
    // console.log('collectAmount', collectAmount);
    // console.log('hasObj', hasObj);

    if (collectAmount === balanceAmount) {
      return false;
    } else {
      return true;
    }

  }

  saveFinalizeBill(): void {
    const currentObj = this;

    if (this.billingSummaryDetails.Balance_Amount > 0) {
      currentObj.saveFinalizeBillAmount();
    }

    if (this.billingSummaryDetails.Balance_Amount < 0) {
      currentObj.saveFinalizeBillRefund();
    }
  }

  saveFinalizeBillZero(): void {

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_h_id: this.userInfo.hospitalID,
      final_bill_btn_status: '0',
      payment_type: '',
      cash_amount: '',
      card_amount: '',
      wallets_amount: '',
      banking_amount: '',
      bank_name: '',
      banking_date: '',
      transaction_reference_id: '',
      transaction_id: '',
      transaction_mobile_nr: '',
      payment_mode_type: '',
      refund_later_checkbox: '',
      create_name: this.userInfo.username,
      roundoff_amount: this.roundoffAmount
    };

    this.inpatientService.saveFinalizeBill(inputRequest).subscribe(
      (response) => {
        console.log('final bill', response);
        this.toastrService.success(response.message);
        this.finalizeBillPopupClose();
      },
      (err) => {
        console.log('HTTP Error', err);
        this.toastrService.error('Something went wrong..!');
        this.finalizeBillPopupClose();
      }
    );
  }

  saveFinalizeBillAmount(): void {

    let paymentType = '';

    let cashAmount = '';
    let cardAmount = '';
    let walletsAmount = '';
    let bankingAmount = '';

    let transactionReferenceId = '';

    let error = 0;

    let paymentModeName = '';

    const hasObj = this.paymentModeList.find((obj) => obj.selectedPaymentType === true);
    if (hasObj) {

      paymentType = hasObj.paymentType;

      paymentModeName = hasObj.paymentModeName;

      if (hasObj.paymentModeID === 1) {
        cashAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 2) {
        cardAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 3) {
        walletsAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 4) {
        bankingAmount = hasObj.collectedAmount;
        transactionReferenceId = this.transactionId;
      }

      if (hasObj.paymentModeID === 2 || hasObj.paymentModeID === 3 || hasObj.paymentModeID === 4) {

        if (this.transactionId === '') { error++; }
        if (this.bankName === '') { error++; }
        if (this.mobileNumber === '') { error++; }
        if (this.issueDate === '' && hasObj.paymentModeID === 4) { error++; }

      }

    }

    if (error > 0) {
      this.toastrService.error('Please fill all required fields');
      return;
    }

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_h_id: this.userInfo.hospitalID,
      final_bill_btn_status: '1',
      payment_type: paymentType,
      cash_amount: cashAmount,
      card_amount: cardAmount,
      wallets_amount: walletsAmount,
      banking_amount: bankingAmount,
      bank_name: this.bankName,
      banking_date: this.issueDate,
      transaction_reference_id: transactionReferenceId,
      transaction_id: this.transactionId,
      transaction_mobile_nr: this.mobileNumber,
      payment_mode_type: paymentModeName,
      refund_later_checkbox: '',
      create_name: this.userInfo.username,
      roundoff_amount: this.roundoffAmount
    };
    // console.log(inputRequest);

    this.inpatientService.saveFinalizeBill(inputRequest).subscribe(
      (response) => {
        console.log('final bill', response);
        this.toastrService.success(response.message);
        this.finalizeBillPopupClose();
      },
      (err) => {
        console.log('HTTP Error', err);
        this.toastrService.error('Something went wrong..!');
        this.finalizeBillPopupClose();
      }
    );
  }

  saveFinalizeBillRefund(): void {

    let refundLaterCheckbox = '';

    let paymentType = '';

    let cashAmount = '';
    let cardAmount = '';
    let walletsAmount = '';
    let bankingAmount = '';

    let transactionReferenceId = '';

    let paymentModeName = '';

    let error = 0;

    const hasObj = this.paymentModeList.find((obj) => obj.selectedPaymentType === true);
    if (hasObj) {

      paymentType = hasObj.paymentType;

      paymentModeName = hasObj.paymentModeName;

      if (hasObj.paymentModeID === 1) {
        cashAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 2) {
        cardAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 3) {
        walletsAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 4) {
        bankingAmount = hasObj.collectedAmount;
        transactionReferenceId = this.transactionId;
      }

      if (hasObj.paymentModeID === 6) {
        bankingAmount = hasObj.collectedAmount;
        refundLaterCheckbox = '1';
      }

      if (hasObj.paymentModeID === 2 || hasObj.paymentModeID === 3 || hasObj.paymentModeID === 4) {

        if (this.transactionId === '') { error++; }
        if (this.bankName === '') { error++; }
        if (this.mobileNumber === '') { error++; }
        if (this.issueDate === '' && hasObj.paymentModeID === 4) { error++; }

      }

    }

    if (error > 0) {
      this.toastrService.error('Please fill all required fields');
      return;
    }

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_h_id: this.userInfo.hospitalID,
      final_bill_btn_status: '-1',
      payment_type: 2, // For refund transaction
      cash_amount: cashAmount,
      card_amount: cardAmount,
      wallets_amount: walletsAmount,
      banking_amount: bankingAmount,
      bank_name: this.bankName,
      banking_date: '',
      transaction_reference_id: transactionReferenceId,
      transaction_id: this.transactionId,
      transaction_mobile_nr: this.mobileNumber,
      payment_mode_type: paymentModeName,
      refund_later_checkbox: refundLaterCheckbox,
      create_name: this.userInfo.username,
      roundoff_amount: this.roundoffAmount
    };

    this.inpatientService.saveFinalizeBill(inputRequest).subscribe(
      (response) => {
        console.log('final bill', response);
        this.toastrService.success(response.message);
        this.finalizeBillPopupClose();
      },
      (err) => {
        console.log('HTTP Error', err);
        this.toastrService.error('Something went wrong..!');
        this.finalizeBillPopupClose();
      }
    );
  }

  closeModalPopup(event): void {

    this.modalService.dismissAll();

    // Get update Bill summary
    this.getBillingSummaryInfo();

  }

  printReceipt(receipt): void {

    const input = {
      IpNr: this.ipPatientNO,
      HospitalId: this.userInfo.hospitalID,
      ReceiptNo : receipt.RECEIPT_NO
    };
    this.getIpReceiptDetails(input);

  }

  /** get the pdf footer details */
  getIpReceiptDetails(inputRequest): void {

    this.inpatientService.ipReceiptDetails(inputRequest).subscribe((response) => {

      if (response !== null) {
        this.ipReceiptDetailsList = response;

        this.ipReceiptAmountInWords = this.commonService.toWords(
          Math.round(this.ipReceiptDetailsList.ReceiptAmt)
        );
      }

      this.printReceiptDiv = true;

      setTimeout(() => {
        this.printerService.printDiv('print-receipt-section');
        // this.closeModalPopup();
      }, 2000);

      setTimeout(() => {
        this.printReceiptDiv = false;
      }, 3000);

    });

  }

  paymentDone(): void {

    const inputRequest = {
      sear_hospitalid: this.userInfo.hospitalID,
      sear_ip_no: this.ipPatientNO
    };
    this.inpatientService.paymentDone(inputRequest).subscribe((response) => {
      this.toastrService.success(response.msg);
    });

  }

  getPendingStatus(): void {

    const inputRequest = {
      sear_hospitalid: this.userInfo.hospitalID,
      sear_ip_no: this.ipPatientNO
    };
    this.inpatientService.getPendingStatus(inputRequest).subscribe((response) => {

      this.paymentDoneBtnStatus = 'Y';

    });

  }

  changeDiscountAmount(event, discountForm: any): void {
    // console.log(event.target.value);
    const totalAmount = parseFloat(this.billingSummaryDetails.Total_Bill_Amount);
    const min = 0;
    const max = totalAmount;
    const currentValue = event.target.value;

    if (currentValue !== '') {

      if (currentValue > max ) {
        this.toastrService.error('Discount amount should not exceed Net amount');
        event.target.value = max;
      } else {
        event.target.value = currentValue;
      }

      discountForm.discountAmount  = event.target.value;

      discountForm.netAmount = Math.round(totalAmount - discountForm.discountAmount);

      let discountAmountPercenttage = 0;
      if (totalAmount > 0) {
        discountAmountPercenttage = (discountForm.discountAmount / totalAmount) * 100;
        // discountAmountPercenttage = discountAmountPercenttage;
      }
      discountForm.discountPercentage = Number(discountAmountPercenttage).toFixed(2);

    }

  }

  blurDiscountAmount(event, service: any): void {
    const currentValue = event.target.value;

    if (currentValue === '') {
      event.target.value = 0;
    }
  }

  changeDiscount(event, discountForm: any): void {
    // console.log(event.target.value);
    const totalAmount = parseFloat(this.billingSummaryDetails.Total_Bill_Amount);
    const min = 0;
    const max = 100;
    let currentValue = event.target.value;

    if (currentValue === '') {
      currentValue = 0;
    }

    if (currentValue !== '') {

      if (currentValue > max ) {
        this.toastrService.error('Discount should not exceed 100 percent');
        event.target.value = max;
      } else {
        event.target.value = currentValue;
      }

      discountForm.discountPercentage  = event.target.value;

      const discountAmount = ( totalAmount * discountForm.discountPercentage / 100 );

      discountForm.discountAmount = discountAmount.toFixed(2); // Math.ceil(discountAmount);

      discountForm.netAmount = Math.round(totalAmount - discountForm.discountAmount);

    }

  }

  blurDiscount(event, service: any): void {
    const currentValue = event.target.value;

    if (currentValue === '') {
      event.target.value = 0;
    }
  }

  focusDiscountAmount(): void {
    this.disableDiscountPercentage = true;
  }

  focusDiscountPercentage(): void {
    this.disableDiscountAmount = true;
  }

  resetDiscount(): void {
    this.discountForm = {
      discountAmount: '',
      discountPercentage: '',
      netAmount: ''
    };
    this.disableDiscountPercentage = false;
    this.disableDiscountAmount = false;
  }

  applyDiscount(discountForm): void {

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      discount_amount: discountForm.discountAmount,
      discount_percentage: discountForm.discountPercentage,
      discount_status: 'PENDING',
      requested_by: this.userInfo.username
    };

    this.inpatientService.applyDiscountToServices(inputRequest).subscribe(
      (response) => {
        console.log('applydisount', response);
        this.toastrService.success('Discount applied and need to be approved');
        // this.getPatientServicesTypeLst();
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  roundOffDifference(): void {

    if (this.discountAuthorization.DISCOUNT_STATUS === 'APPROVE') {

      this.roundoffAmount = this.discountAuthorization.DISCOUNT_APPROVE_AMOUNT - this.billingSummaryDetails.ServiceBasedDiscount;
      console.log(this.roundoffAmount);
      // this.billingSummaryDetails.Balance_Amount = parseFloat(this.billingSummaryDetails.Balance_Amount_Org) + this.roundoffAmount;

    }

  }

  roundOffAdjust(type): void {

    const stepIndex = 1;
    if (type === 'plus') {
      this.paybleRoundoffAmount = this.paybleRoundoffAmount + stepIndex;
    }
    if (type === 'minus') {
      this.paybleRoundoffAmount = this.paybleRoundoffAmount - stepIndex;
    }

    this.roundoffAmount = this.paybleRoundoffAmount - this.billingSummaryDetails.Balance_Amount_Org;

    this.billingSummaryDetails.Balance_Amount = parseFloat(this.billingSummaryDetails.Balance_Amount_Org) + this.roundoffAmount;

  }

  backNav(): void {
    const queryParam = { mode: 'dischargelist' };
    if (this.returnMode === 'iplist') {
      this.router.navigate(['/Inpatient']);
    } else if (queryParam.mode === 'dischargelist' && this.returnMode === undefined){
      this.router.navigate(['/Inpatient']);
    } else {
      this.router.navigate(['/Inpatient'], {  queryParams: queryParam });
    }
  }

  backServicesList(): void {

    const queryParam = { umrNumber: this.patientURMNo, mode: this.returnMode };
    this.router.navigate(['/ServiceCharged/' + this.ipPatientNO], {queryParams: queryParam});

  }

  actionRouting(value: any): void{
    const val = value;

    const queryParam = {umrNumber: this.patientURMNo};
    if (val === 'admissionInformation') {
      this.openIpAdmissionInfoPopup(this.ipAdmissionInformationPopup);
    } else if (val === 'updatePayee') {
      this.openUpdatePayeeSponsorPopup(this.updatePayeeSponsorPopup);
    } else if (val === 'addReferral'){
      this.openAddReferralPopup(this.addReferralPopup);
    } else if (val === 'updateExternal'){
      this.openUpdateExternalPopup(this.externalReferralPopup);
    } else if (val === 'AddInpatientServices'){
      this.router.navigate(['/AddInpatientServices/' + this.ipPatientNO], {queryParams: queryParam});
    }
  }


  openIpAdmissionInfoPopup(ipAdmissionInformationPopup): void{
    this.modalService.open(ipAdmissionInformationPopup, { size: 'lg'});
  }

  openUpdatePayeeSponsorPopup(updatePayeeSponsorPopup): void{
    this.modalService.open(updatePayeeSponsorPopup, { size: 'lg'});
  }

  openAddReferralPopup(addReferralPopup) {
    this.modalService.open(addReferralPopup);
  }
  
  openUpdateExternalPopup(externalReferralPopup){
    this.modalService.open(externalReferralPopup, { size: 'lg'});
  }

  roomInfoPopup(RoomInfoPopup) {
    this.modalService.open(RoomInfoPopup, { size: 'xl', backdrop: 'static'});
  }
  /* admissionInfoPopClose(): void
  {
    this.modalService.dismissAll();
  }

  updatePayeePopupClose(): void
  {
    this.modalService.dismissAll();
  } */
  popUpClose(): void{
    this.modalService.dismissAll();
  }


  /**
   *
   * @param 'bedTransferPopup'
   */
   bedTransferPopUp(bedTransferPopup): void {
    this.bedTransferSubmitBtn = true;
    this.transferFormData.wardListId = '';
    this.transferFormData.patientBedId = '';
    this.getWardsList();
    this.modalService.open(bedTransferPopup);
  }
  /**
   *
   */
  getWardsList(): void {
    this.wardService.getWardsList().subscribe((response) => {
      this.completeWardsList = response;
    });
  }
  
  changeWard(wardId): void {
    this.bedsList = [];
    this.wardService.getBedsList(wardId).subscribe((response) => {
      if(response !=0 ){
      this.bedsList = response;
      }else{
        this.toastrService.error("All beds are occupied in this ward");
      }
    });
  }

  /**
   * submitTransferFormDetails
   */
   submitTransferFormDetails(): void {
    if (this.bedTransferSubmitBtn) {
      this.bedTransferSubmitBtn = false;
    }
    else {
      const roomId = this.bedsList.find((data) => {
        if (data.WM_BED_M_ID === this.transferFormData.patientBedId) {
          return data;
        }
      });
      const inputRequest = {
        HospitalId: this.userInfo.hospitalID,
        IpNr: this.ipPatientNO,
        BedConfirmationBtn: 'N',
        BedTransferBtn: 'Y',
        CreateName: this.userInfo.username,
        WmWardMId: this.transferFormData.wardListId,
        WmWardRoomMId: roomId.WM_WARD_ROOM_M_ID,
        WmBedMId: this.transferFormData.patientBedId
      };
      this.nursestationsService.transferBed(inputRequest)
        .subscribe(
          (response) => {
            this.transferBedError = '';
            this.modalService.dismissAll();
            this.transferFormData = {
              wardListId: '',
              patientBedId: ''
            };
            this.showWardRoomsList(this.floorType, this.selectedBed.WardId);
          },
          (error) => {
            this.transferBedError = error.error.message;
            console.log('error123458', error.error.message);
          }
        );
    }
  }
   /** get Ward Rooms list from B-end */
   showWardRoomsList(floorId, wardId): void {
    const roomReq = {
      HospitalId: this.userInfo.hospitalID,
      WardId: wardId
    };
    this.nursestationsService.wardRoomBedsList(roomReq).subscribe((response) => {

      this.roomBedsList = response;
      this.floorType = floorId;
      this.wardType = true;
      this.patientData = false;
    });
  }
  closeBedTransferPopup(): void {
    this.bedTransferSubmitBtn = true;
    this.transferFormData.wardListId = '';
    this.transferFormData.patientBedId = '';
    this.modalService.dismissAll();
  }


/**
 * open cross consultant popup
 * @param 'crsConsultantPopUp'
 */
crossConsultantPopUp(crsConsultantPopUp): void {
  this.addDoctorSubmitBtn = true;
  this.consultantFormData.departmentId = '';
  this.loadDoctorLists();
  this.loadDepartmetList();
  this.modalService.open(crsConsultantPopUp, { size: 'lg', backdrop: 'static' });
}

loadDoctorLists(): void {
  const docInput = {
    IpNr: this.ipPatientNO,
    HospitalID: this.userInfo.hospitalID
  };
  this.doctorData = [];
  this.nursestationsService.doctorsList(docInput)
  .subscribe((doctors: SearchDoctors[]) => {
    this.doctorList = doctors;
    console.log(doctors);
    this.doctorList.forEach(element => {
      this.doctorIdsList[element.doctorId] = element.doctorName;
    });
    /* this.filterDoctorList = this.doctorList.filter(
      obj => obj.doctorId !== this.selectedBed.ConsultDoctorId); */
    this.filterDoctorList = this.doctorList;

  });
}

loadDepartmetList(): void {
  const dpInput = {
    HospitalID: this.userInfo.hospitalID
    };
  this.departmentList = [];
  this.nursestationsService.departmentsList(dpInput).subscribe((departments: SearchDepartments[]) => {
    const departmentId: number[] = [];
    departments.forEach( dept => {
      if (departmentId.indexOf(dept.deptId) < 0) {
        this.departmentList.push(dept);
      }
      departmentId.push(dept.deptId);
    });
  });
}
doctorChange(doctorId: any): any {

  if (doctorId === '') {
    this.filterDoctorList = this.doctorList;
  } else {
    const docObj = this.doctorList.filter(obj => obj.doctorId === doctorId);
    if (docObj.length !== 0) {
      this.consultantFormData.departmentId = docObj[0].departmentId;
    }
  }
}

departmentChange(departmentId: any): any {

  if (departmentId === '') {
    this.filterDoctorList = this.doctorList;
  } else {
    this.filterDoctorList = this.doctorList.filter(obj => obj.departmentId === departmentId);
    this.consultantFormData.doctorId = '';
    this.doctorauto.clear();
  }
}
selectAutocompleteEvent(item: any, field: string): void {
  console.log('item', item);
  if (field === 'doctorname') {
    this.consultantFormData.doctorId = item.doctorId;
    this.doctorChange(item.doctorId);
  }
}

onRemoveSelected(item: any, field: string): void {
  if (field === 'doctorname') {
    this.consultantFormData.doctorId = null ;
    this.consultantFormData.departmentId = ' ';
    this.itemDoctor = false;
  }
}
submitConsultantFormDetails(): void {
  if (this.consultantFormData.doctorId){
    const inputRequest = {

      IPNr: this.ipPatientNO,
      DeptId: this.consultantFormData.departmentId,
      DmId: this.consultantFormData.doctorId,
      DoctorName: this.doctorIdsList[this.consultantFormData.doctorId],
      CreateName: this.userInfo.username,
      ReferralId: 0,
      ReferralName: 0
        };
    this.nursestationsService.crossConsultFormDetails(inputRequest)
      .subscribe((response) => {

          // console.log(this.consultantFormData.doctorId);
          this.modalService.dismissAll();
          this.consultantFormData = {
            doctorId: '',
            departmentId: ''
          };
        });

      }else{
        this.consultFormErrorMessage = true;
      }

  }

  onChangeSearch(val: string): void {
    // return this.doctorsList.filter(option => option.DM_NAME.toLowerCase().includes(val));
  }

  onFocused(e): void {
    // do something when input is focused
  }
  closeCrosConsultantPopup(): void {
    this.addDoctorSubmitBtn = true;
    this.consultantFormData.doctorId = '';
    this.consultantFormData.departmentId = '';
    this.modalService.dismissAll();
  }
  interimmBillPopup(interimBillPopup, typeofbill) {

    this.modalService.open(interimBillPopup, {size: 'xl'});
    this.interimBillPrint(typeofbill);
    // this.getFinalBillInfo(inputRequest);
  }
  finallBillPopup(finalBillPopup, typeofbill) {
    this.modalService.open(finalBillPopup, {size: 'xl'});
    this.interimBillPrint(typeofbill);
  }
  interimBillPopupClose() {
    this.modalService.dismissAll();
  }
  public intermBillPrint(): any {

    const htmlele = document.getElementById('interim-print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('interim-print-section');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);

  }
  finalBillPopupClose() {
    this.modalService.dismissAll();
  }
  public insuranceFinalBillPrint(): any {

    const htmlele = document.getElementById('finalBill-print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('finalBill-print-section');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);

  }
  insranceeBillPopup(insuranceBillPopup) {
    this.modalService.open(insuranceBillPopup, {size: 'lg'});
  }
}



function data(data: any) {
  throw new Error('Function not implemented.');
}

