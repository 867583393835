import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports/reports.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
//import { BillingComponent } from '../billing-module/billing/billing.component';


const reportsRouting: Routes = [
  { path: 'reports', component: ReportsComponent}
  //{ path: 'billing', component: BillingComponent}
];

@NgModule({
  declarations: [ReportsComponent],
  exports: [
    RouterModule,
    ReportsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    RouterModule.forChild(reportsRouting)
  ]
})
export class ReportsModule { }
