
<nav class="navbar navbar-expand-lg navbar-light navheader px-4 py-2 ">
  <a class="navbar-brand" href="#">
    <img style="height: 50px; width: auto; line-height: 76px"  src="assets/IgzoltHealthCompressed.png">
  </a>
  
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <form class="form-inline my-2 my-lg-0 navbar-form" autocomplete="off">
    <div class="input-group input-group-search mx-auto">
      <!-- <input type="search" class="form-control searc-input" placeholder=" Type to Search..." aria-label="Search" >
      <div class="input-group mbl-d">
        <button class="btn searchbtn " type="submit" id="search-button-addon"><i class="fa fa-search" style="    margin-left: -3px;" aria-hidden="true"></i></button>
      </div> -->
    </div>
  </form>

  <div class="font-weight-bold col-2">
    <span>{{username | uppercase}}</span> <br/>
    <span class="d-inline-block text-truncate h-name-length" [title]="hospitalName">{{hospitalName}}</span>
  </div>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
      <li class="nav-item active profilealign" *ngIf="hasUser()">
        <div ngbDropdown class="d-inline-block">
          <a href="javascript:void(0)"
             ngbDropdownToggle
             class="d-block"><img  class="profile" src="assets/men.png" />
          </a>
          
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="account-dropdown-menu">
            <ul class="list-group">
              <li class="list-group-item">
                <a href="javascript:void(0)" routerLink="/changepassword">
                  <i class="fa fa-cog"></i> Change Password
                </a>
              </li>
              <li class="list-group-item">
                <a href="javascript:void(0)" (click)="logout()"><i class="fa fa-sign-out-alt"></i> Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
    <button *ngIf="!hasUser()" class="app_btn btn"><span class=" header-btns" ata-toggle="tooltip" data-placement="left" title="Log In" >Login</span></button>
  </div>
</nav>


